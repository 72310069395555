import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import useStateRef from 'react-usestateref';
import Chip from '@material-ui/core/Chip';
import directus from "../../services/directus";
import EventEmitter from "src/utils/EventEmitter";
import { useSnackbar } from 'notistack';
import { OverflowTip } from "src/components/OverflowTip";
import ErrorMessage from 'src/components/ErrorMessage';
import { CloseSnackbarAction } from 'src/components/CloseSnackbarAction';
import { useStyles } from './approvaldetailstyle.js';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import { DeleteButton, CustomButton2 } from './approvaldetailstyle.js';
import ClearIcon from '@mui/icons-material/Clear';
import {
  Grid,
  TextField,
  IconButton,
  Dialog,
  DialogContent,
  CircularProgress,
  Typography,
} from "@material-ui/core";

const ButtonsView = ({ selectedOrder }) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [initialized, setInitialized] = useState(false);
  const [rejectionNote, setRejectionNote, rejectionNoteRef] = useStateRef(null);
  const [loadReject, setLoadReject, loadRejectRef] = useStateRef(false);
  const [loadApprove, setLoadApprove, loadApproveRef] = useStateRef(false);
  const [openRejectOrder, setOpenRejectOrder] = useState(false);
  const [openApprovedOrder, setOpenApprovedOrder] = useState(false);

  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
    }
  });

  const handleClick = id => {
    window.open(id);
  };

  const rejectAwaitingApproval = async (orderID) => {    
    setLoadReject(true);

    try {
      let response = await directus.api.post(
        `/custom/orders/awaiting_approval/reject/${orderID}`,
        {
          rejection_note: rejectionNoteRef.current,
        },
      )

      if (response.data) {
        enqueueSnackbar('Order rejected successfully', {
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
          variant: 'success',
          autoHideDuration: 3200,
          action: CloseSnackbarAction
        });
      }
    } catch (e) {
      enqueueSnackbar(
        ErrorMessage(e),{
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
          variant: 'error',
          autoHideDuration: 3200,
          action: CloseSnackbarAction
        }
      );
    }

    setLoadReject(false);
    handleCloseRejectOrderPopup();
    EventEmitter.emit("action_approval");
  };

  const approvedAwaitingApproval = async (orderID) => {    
    setLoadApprove(true);

    try {
      let response = await directus.api.post(`/custom/orders/awaiting_approval/approve/${orderID}`);

      if (response.data) {
        enqueueSnackbar('Order approved successfully', {
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
          variant: 'success',
          autoHideDuration: 3200,
          action: CloseSnackbarAction
        });
      }
    } catch (e) {
      enqueueSnackbar(
        ErrorMessage(e),{
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
          variant: 'error',
          autoHideDuration: 3200,
          action: CloseSnackbarAction
        }
      );
    }

    setLoadApprove(false);
    handleCloseApprovedOrderPopup();
    EventEmitter.emit("action_approval");
  };

  const handleOpenRejectOrderPopup = () => {
    setOpenRejectOrder(true);
  }

  const handleCloseRejectOrderPopup = () => {
    setOpenRejectOrder(false);
    setRejectionNote('');
  }

  const handleOpenApprovedOrderPopup = () => {
    setOpenApprovedOrder(true);
  }

  const handleCloseApprovedOrderPopup = () => {
    setOpenApprovedOrder(false);
  }

  const handleChangeRejectNote = (e) => {
    setRejectionNote(e.target.value);
  }

  return (
    <div>
      <div style={{ width: '100%', display: 'flex', marginTop: '30px'}}>
        <div style={{ width: '50%', flexWrap: 'wrap', display: 'flex', gap: '10px', overflowX: 'auto' }}>
          {selectedOrder && selectedOrder ?
            selectedOrder.files && selectedOrder.files.length > 0
              ? selectedOrder.files.map((file, index) => {
                if (file.directus_file_id) {
                  return (
                    <Grid key={index} span="true" style={{ display: 'flex' }}>
                      <Chip
                        icon={<InsertDriveFileIcon />}
                        label={file.directus_file_id.filename_download}
                        onClick={id => handleClick(file.directus_file_id.data.full_url)}
                        variant="outlined"
                        style={{ marginRight: '5px' }}
                      />
                    </Grid>
                  );
                } else {
                  return null;
                }
              })
            : ''  
          : ''}
        </div>
        <div style={{ width: '50%', maxWidth: '50%', display: 'flex', justifyContent: 'flex-end', gap: '10px', flexWrap: 'wrap' }}>
          <DeleteButton
            variant="contained"
            onClick={() => handleOpenRejectOrderPopup()}
            >
            Reject Order
          </DeleteButton>
          <CustomButton2
            variant="contained"
            onClick={() => handleOpenApprovedOrderPopup()}
          >
            Approve Order
          </CustomButton2>
        </div>
      </div>      

      <Dialog
        isopen={`${openRejectOrder}`}
        open={openRejectOrder}
        fullWidth={true}
        onClose={() => handleCloseRejectOrderPopup()}
        className={classes.DialogRejectStyle}
      >
        <IconButton aria-label="close" onClick={() => handleCloseRejectOrderPopup()} className={classes.dialogCloseBtn}>
          <ClearIcon />
        </IconButton>
        <DialogContent>
          <Grid container spacing={0}>
            <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginBottom: '10px' }}>
              <TextField
                variant="outlined"
                multiline={true}
                rows={3}
                placeholder='Add order rejection note...'
                onChange={(e) => handleChangeRejectNote(e)}
                className={classes.leftAlignMultilineTextfield}
                value={ rejectionNoteRef.current ? rejectionNoteRef.current : ''}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginBottom: '5px', display: 'flex', justifyContent: 'flex-end' }}>
              <DeleteButton
                variant="contained"
                disabled={!rejectionNoteRef.current}
                onClick={() => rejectAwaitingApproval(selectedOrder.id)}
              >
                Reject Order
                {loadRejectRef.current ?
                  <CircularProgress
                    style={{
                      width: '15px',
                      height: '15px',
                      marginLeft: '10px',
                      marginTop: '2px',
                      color: '#FFFFFF'
                    }}
                    className={classes.circular}
                  />
                :''}
              </DeleteButton>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>

      <Dialog
        isopen={`${openApprovedOrder}`}
        open={openApprovedOrder}
        fullWidth={true}
        onClose={() => handleCloseApprovedOrderPopup()}
        className={classes.DialogApproveStyle}
      >
        <IconButton aria-label="close" onClick={() => handleCloseApprovedOrderPopup()} className={classes.dialogCloseBtn2}>
          <ClearIcon />
        </IconButton>
        <DialogContent>
          <Grid container spacing={0}>
            <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginBottom: '10px' }}>
              <Typography noWrap component="div" className={classes.typographyStyle2} style={{ color: 'black' }}>
                <OverflowTip>Are you sure you want to approve this order?</OverflowTip>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginBottom: '5px', display: 'flex', justifyContent: 'flex-end' }}>
              <CustomButton2
                variant="contained"
                onClick={() => approvedAwaitingApproval(selectedOrder.id)}
              >
                Approve Order
                {loadApproveRef.current ?
                  <CircularProgress
                    style={{
                      width: '15px',
                      height: '15px',
                      marginLeft: '10px',
                      marginTop: '2px',
                      color: '#FFFFFF'
                    }}
                    className={classes.circular}
                  />
                :''}
              </CustomButton2>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default ButtonsView