import { makeStyles } from '@material-ui/core';
import {
  withStyles,
  Fab,
  Tooltip,
  Button
} from '@material-ui/core';

export const AddButton = withStyles((theme) => ({
  root: {
    backgroundColor: '#3399FF',
    width: '30px',
    height: '30px',
    minHeight: '30px',
    boxShadow: '0 0 black',
    // left: '10%',
  },
}))(Fab);

export const AddAddressButton = withStyles({
  root: {
    border: 'none',
    backgroundColor: 'rgba(81, 101, 115, 0.15)',
    padding: '7px 14px !important',
    textTransform: 'capitalize',
    color: '#37455',
    fontFamily: 'SF Pro',
    borderRadius: '12px',
    '&:hover': {
      border: 'none',
      borderRadius: '12px',
      backgroundColor: 'rgba(81, 101, 115, 0.5)',
    },
  },
})(Button);

export const RemoveButton = withStyles({
  root: {
    border: 'none',
    backgroundColor: '#E87777',
    textTransform: 'capitalize',
    fontFamily: 'SF Pro',
    color: 'white',
    borderRadius: '4px',
    width: '100%',
    '&:hover': {
      border: 'none',
      borderRadius: '4px',
      backgroundColor: '#E87777',
    },
  },
})(Button);

export const CustomTooltip = withStyles({
  tooltip: {
    fontSize: "16px",
    maxWidth: 500,
    backgroundColor: "grey"
  }
})(Tooltip);

export const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    paddingTop: 48,
    marginRight: '28px',
    marginLeft: '28px',
  },
  Appbar: {
    backgroundColor: '#E2E2E7',
    color: '#333',
    minHeight: '35px',
    height: "35px",
    maxHeight: "35px",
    paddingBottom: '10px',
    borderRadius: '6px',
    boxShadow: '0px 0px !important',
    '& .Mui-selected': {
      backgroundColor: 'white !important',
      borderRadius: '6px !important',
      margin: '2.5px !important',
      padding: '2.5px 12px',
      height: '30px',
      maxHeight: '30px',
      minHeight: '30px'
    },
    '& .MuiTabs-scroller': {
      position: 'initial !important',
    },
    '& .MuiTabScrollButton-root': {
      height: '36px !important',
      width: '30px !Important'
    },
    '& .MuiTab-root': {
      minHeight: "30px"
    }
  },
  tabs: {
    '&. MuiTabs-flexContainer': {
      overflow: 'overlay !important'
    }
  },
  tab: {
    '& .MuiBox-root': {
      padding: '10px 0px 0px 0px',
    },
  },
  employees: {
    fontFamily: 'SF Pro',
    fontWeight: 'bold',
    minHeight: "30px"
  },
  customers: {
    fontFamily: 'SF Pro',
    fontWeight: 'bold',
    minHeight: "30px"
  },
}));

export const useStylesEmployees = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '10px !important',

  },
  circular: {
    color: '#5bfa17',
    marginLeft: '45%'
  },
  circular2: {
    color: '#5bfa17',
    marginRight: "10px"
  },
  buttonLeft: {
    backgroundColor: 'white',
    padding: 0,
    width: '60px',
    borderRadius: '24px 0 0 24px',
    borderRight: 0,
    boxShadow: '0 0 black',
    border: 0,
    height: 30,
  },
  buttonRight: {
    backgroundColor: 'white',
    padding: 0,
    width: '60px',
    borderRadius: '0 24px 24px 0',
    boxShadow: '0 0 black',
    border: 0,
    height: 30,
  },
  disableButtonLeft: {
    backgroundColor: 'white',
    width: '60px',
    borderRadius: '24px 0 0 24px',
    borderRight: 0,
    border: '0 !important',
  },
  MuiAccordionroot: {
    '&.MuiAccordion-root:before': {
      left: '7px',
      right: '7px'
    }
  },
  searchbox: {
    width: '300px',
    height: '36px',
    borderRadius: '10px',
    // backgroundColor: 'rgba(142,142,147,0.12)',
    marginTop: "11px",
    border: "1px solid rgba(0, 0, 0, 0.23)"
  },
  IconSearch: {
    height: '19px',
    width: '30px',
    opacity: '0.5',
  },
  selectSupplierStatus: {
    '& .MuiOutlinedInput-input': {
      padding: "10px 14px !important"
    }
  },
  headerSort: {
    display: "flex",
    overflowX: "hidden",
    cursor: "pointer"
  },
  ascSort: {
    color: "#516573",
    fontSize: "20px",
    position: "absolute",
    marginTop: "1px",
    marginLeft: "3px",
    transform: "rotateX(180deg)"
  },
  descSort: {
    color: "#516573",
    fontSize: "20px",
    position: "absolute",
    marginTop: "1px",
    marginLeft: "3px",
  },
  header: {
    textAlign: 'center',
    width: '100%',
    fontSize: "15px",
    fontWeight: "500",
    fontFamily: 'SF Pro',
    color: '#546e7a',
    borderBottom: '1px solid #a3a3a3',
    margin: "10px 0 15px",
    lineHeight: "0.1em",
    '& span':{
      background: "#fff",
      padding: "0 10px"
    }
  },
  export: {
    '& .MuiTouchRipple-ripple.MuiTouchRipple-rippleVisible': {
      width: "115px !important",
      left: "0 !important"
    }
  },
  gridContainer: {
    border: '1px solid lightgray',
    padding: '10px',
    margin: '0px 8px',
    borderRadius: '6px'
  }
}));