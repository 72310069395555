import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Menu,
  MenuItem,
  Avatar,
  AppBar,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  makeStyles,
  InputAdornment,
  ListItemIcon,
  CircularProgress,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  TextField,
  FormControlLabel,
  InputLabel,
  FormControl,
  OutlinedInput,
  Button,
  Switch,
  withStyles
} from '@material-ui/core';
import Logo from 'src/components/Logo';
import EventEmitter from 'src/utils/EventEmitter';
import { items } from 'src/utils/Menu';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import AddIcon from '@material-ui/icons/Add';
import ManageAccountsRoundedIcon from '@mui/icons-material/ManageAccountsRounded';
import PersonAddRoundedIcon from '@material-ui/icons/PersonAddRounded';
import CloudDownloadRoundedIcon from '@material-ui/icons/CloudDownloadRounded';
import FilterListRoundedIcon from '@material-ui/icons/FilterListRounded';
import DisplaySettingsRoundedIcon from '@mui/icons-material/DisplaySettingsRounded';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import directus from 'src/services/directus';
import { useSnackbar } from 'notistack';
import ErrorMessage from 'src/components/ErrorMessage';
import { AdminPermission, POCreatorPermission } from 'src/utils/Permission';
import useStateRef from 'react-usestateref';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { getEmployee } from 'src/utils/sessions';
import { CloseSnackbarAction } from 'src/components/CloseSnackbarAction';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

export const SubmitButton = withStyles(() => ({
  root: {
    borderRadius: '24px',
    backgroundColor: '#2AC940',
    color: '#ffffff',
    height: '30px',
    width: '120px',
    '&:hover': {
      backgroundColor: '#22a033'
    }
  }
}))(Button);

export const CancelButton = withStyles({
  root: {
    borderRadius: '24px',
    marginRight: '4px',
    backgroundColor: '#e0e0e0de',
    height: '30px',
    width: '120px',
    '&:hover': {
      backgroundColor: '#bdbdbdde'
    }
  }
})(Button);

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#ffffff',
    height: '81px',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)'
  },
  selected: {
    backgroundColor: 'red'
  },
  avatar: {
    width: theme.spacing(5),
    height: theme.spacing(5)
  },
  avatar2: {
    cursor: 'pointer',
    width: theme.spacing(5),
    height: theme.spacing(5),
    marginLeft: '20px'
  },
  logo: {
    height: '40px',
    marginLeft: '30px'
  },
  title: {
    height: '24px',
    fontSize: '22px',
    fontWeight: 'bold',
    fontFamily: 'SF Pro',
    color: '#333',
    paddingLeft: '55px'
  },
  topbarTab: {
    width: '110px',
    height: '40px',
    borderRadius: '22px',
    background: 'rgba(242, 242, 246, 0.5)',
    border: '1px solid rgba(242, 242, 246, 0.5)',
    cursor: 'pointer',
    fontSize: '16px',
    color: '#374355',
    lineHeight: '36px',
    textAlign: 'center',
    marginRight: '10px',
    '&:hover': {
      color: '#82CB43',
      border: '1px solid #E5E5E5'
    }
  },
  selectedTab: {
    fontWeight: 700,
    background: '#BADF9C',
    border: '1px solid rgba(214, 238, 193, 0.5)',
    '&:hover': {
      background: 'rgba(214, 238, 193, 0.5)',
      color: '#82CB43',
      border: '1px solid #82CB43'
    }
  },
  add: {
    width: '40px',
    height: '40px',
    backgroundColor: '#82CB43',
    marginLeft: '20px'
  },
  iconAdd: {
    backgroundColor: '#82CB43',
    color: '#FFFFFF',
    borderRadius: '19px',
    fontSize: '40px'
  },
  topFilter: {
    width: '40px',
    height: '40px',
    borderRadius: '10px',
    backgroundColor: '#F2F2F6',
    marginLeft: '20px'
  },
  searchbox: {
    width: '250px',
    height: '40px',
    borderRadius: '8px',
    border: '1px solid rgba(0, 0, 0, 0.1)'
    // backgroundColor: 'rgba(142,142,147,0.12)',
  },
  toolbar: {
    paddingLeft: '0px',
    minHeight: '0px',
    marginTop: '21px'
  },
  IconSetting: {
    borderRadius: '8px'
  },
  dropdown: {
    position: 'fixed',
    width: 200,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    border: '1px solid',
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper
  },
  IconSearch: {
    height: '15px',
    width: '15px',
    color: '#516573',
    marginLeft: '11px'
  },
  IconCancel: {
    height: '19px',
    width: '20px'
  },
  filterandsort: {
    '& .MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover': {
      backgroundColor: '#39F',
      color: 'white'
    },
    '& .MuiListItem-button:hover': {
      backgroundColor: '#39F',
      color: 'white'
    }
  },
  search_by_customer: {
    height: '36px',
    '& .MuiInputBase-root.MuiOutlinedInput-root.MuiAutocomplete-inputRoot': {
      height: '36px',
      marginBottom: '0px',
      borderRadius: '10px',
      backgroundColor: 'rgba(142,142,147,0.12)'
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none'
    },
    '& .MuiInputBase-input.MuiOutlinedInput-input': {
      height: '36px',
      marginTop: '-10px !important'
    }
  },
  circular: {
    color: '#5bfa17',
    marginRight: '10px'
  },
  ordersTypeWrapperStyle: {
    cursor: 'pointer',
    marginLeft: '30px',
    display: 'flex',
    alignItems: 'center',
    width: '175px',
    justifyContent: 'space-between',
    marginTop: '-8px',
    '&:hover $ordersTypeStyle, &:hover $arrowDownIcon': {
      color: "#82CB43 !important"
    },
  },
  ordersTypeStyle: {
    color: '#374355',
    fontWeight: '700',
    fontSize: '18px',
  },
  arrowDownIcon: {
    color: '#374355',
    fontWeight: '400',
    fontSize: '28px',
  },
  menuitem: {
    fontWeight: '400',
    fontSize: '17px',
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  listStyle: {
    '& .MuiList-padding': {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  listitemicon: {
    marginLeft: 'auto',
    justifyContent: 'flex-end',
  },
  approvalstab: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '6px',
    width: '125px !important',
  },
  approvalstotal: {
    width: '24px',
    height: '24px',
    padding: '3px',
    fontSize: '17px',
    fontWeight: '400',
    backgroundColor: '#FC6868',
    borderRadius: '50%',
    color: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}));

const StyledMenu = withStyles({
  paper: {
    width: '250px',
    boxShadow: '0px 0px 32px 0px #00000033',
    borderRadius: '12px',
    top: '68px !important'
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

var searchTimeout;
var react_app_url = process.env.REACT_APP_URL;
if (window.location.hostname.search('interax') !== -1) {
  react_app_url = process.env.REACT_APP_URL_INTERAX;
}

const TopBar = ({ className, onMobileNavOpen, user, company, openFilter, openFilterBar, isFilter, showInvoiceTab, ...rest }) => {
  /* eslint-disable no-unused-vars*/
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [orderTypeLocalStorageName, setOrderTypeLocalStorageName] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElOrdersType, setAnchorElOrdersType] = useState(null);
  const [search, setSearch] = useState('');
  const open = Boolean(anchorEl);
  const openOrderType = Boolean(anchorElOrdersType);
  const [initialized, setInitialized] = useState(false);
  const [firstUrl, setFirstUrl] = useState('');
  const [features, setFeatures] = useState({});
  const [anchorEl4, setAnchorEl4] = useState(null);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [selectedExport, setSelectedExport] = useState(null);
  const [ordersExportData, setOrdersExportData] = useState([]);
  const [invoicesExportData, setInvoicesExportData] = useState([]);
  const [orderTypeSelected, setOrderTypeSelected, orderTypeSelectedRef] = useStateRef('purchase-orders');

  const [openProfile, setOpenProfile] = useState(false);
  const [password, setPassword] = useState({
    password: '',
    showPassword: false
  });
  const [firstName, setFirstName, firstNameRef] = useStateRef('');
  const [lastName, setLastName, lastNameRef] = useStateRef('');
  const [mobileNumber, setMobileNumber, mobileNumberRef] = useStateRef('');
  const [email, setEmail, emailRef] = useStateRef('');
  const [userID, setUserID, userIDRef] = useStateRef('');
  const [employee, setEmployee, employeeRef] = useStateRef('');
  const [emailNotificationStatus, setEmailNotificationStatus, emailNotificationStatusRef] = useStateRef('');
  const [searchDeletedCustomers, setSearchDeletedCustomers, searchDeletedCustomersRef] = useStateRef(false);
  const [searchDeletedSuppliers, setSearchDeletedSuppliers, searchDeletedSuppliersRef] = useStateRef(false);
  const [loading, setLoading] = useState(false);
  const [awaitingApprovalsTotal, setAwaitingApprovalsTotal] = useState(0);
  const [userLoading, setUserLoading] = useState(null);

  const navigate = useNavigate();

  const [exportLoading, setExportLoading] = useState(false);
  const [searchingLoading, setSearchingLoading] = useState(false);

  let configuration = JSON.parse(window.localStorage.getItem('configuration'));
  let Employee = JSON.parse(window.localStorage.getItem('directus_employee'));

  //display settings
  const [displaySettingsLoading, setDisplaySettingsLoading] = useState(null);
  const [openDisplaySettings, setOpenDisplaySettings] = useState(false);
  const [filterBarAlwaysDisplayed, setFilterBarAlwaysDisplayed, filterBarAlwaysDisplayedRef] = useStateRef(false);
  const [enableApprovalWarning, setEnableApprovalWarning, enableApprovalWarningRef] = useStateRef(false);
  /* eslint-enable no-unused-vars*/

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleOrdersTypeClose = () => {
    setAnchorElOrdersType(null);
  };
  const handleClick3 = event => {
    openFilter();
  };
  const handleClick4 = event => {
    setAnchorEl4(event.currentTarget);
  };
  const handleClose4 = event => {
    setAnchorEl4(null);
  };
  const handleMenu = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleOrdersTypeOpen = event => {
    setAnchorElOrdersType(event.currentTarget);
  };
  const searchOrder = event => {
    setSearchingLoading(true);
    if (event.key === 'Enter') {
      searchEmit();
    } else {
      clearTimeout(searchTimeout);
      searchTimeout = setTimeout(() => {
        searchEmit();
      }, 500);
    }
  };

  const searchEmit = () => {
    EventEmitter.emit(firstUrl, {
      text: search
    });
  };

  const addMyOrder = () => {
    EventEmitter.emit('addOrder', {
      text: 'addOrder'
    });
  };

  const changeOrderType = (type) => {
    window.localStorage.setItem(orderTypeLocalStorageName, type);
    setOrderTypeSelected(type);
    EventEmitter.emit('change_order_type', {
      type: type
    });
    navigate(type);
    loadItems();
    loadOrdersExportType();
    handleOrdersTypeClose();
  }

  const exportEmit = data => {
    setSelectedExport(data.id);
    setExportLoading(true);
    EventEmitter.emit('export', {
      text: data
    });
    handleClose4();
  };
  const handleChange = e => {
    setSearch(e.target.value);
  };

  const getTabID = () => {
    let tabID = '';
    const existingTabId = sessionStorage.getItem('tabId');
    if (existingTabId) {
      tabID = existingTabId;
    } else {
      const newTabId = Math.random().toString(36).substring(2);
      sessionStorage.setItem('tabId', newTabId);
      tabID = newTabId;
    }

    return tabID;
  }

  /* eslint-disable react-hooks/exhaustive-deps*/
  useEffect(() => {
    if (features.search === true) {
      setSearch('');
    }

    if (features.searchByCustomer === true) {
      setSelectedCustomer(null);
    }
  }, [window.location.pathname]);

  useEffect(() => {
    if (!initialized) {
      let tabID = getTabID();
      let pathname = window.location.pathname;
      let pathnameLast = window.location.pathname.split('/').pop();
      const local_storage_order_type_name = `order_type_${tabID}`;
      
      setOrderTypeLocalStorageName(local_storage_order_type_name);
      let order_type = window.localStorage.getItem(local_storage_order_type_name);

      if (pathname.includes('sales-orders')) {
        setOrderTypeSelected('sales-orders')
        window.localStorage.setItem(local_storage_order_type_name, 'sales-orders');
      } else if (pathnameLast === '' || pathname.includes('purchase-orders') || pathnameLast == 'invoices') {
        setOrderTypeSelected('purchase-orders');
        window.localStorage.setItem(local_storage_order_type_name, 'purchase-orders');
      } else {
        if (order_type) {
          setOrderTypeSelected(order_type);
        } else {
          setOrderTypeSelected('purchase-orders');
          window.localStorage.setItem(local_storage_order_type_name, 'purchase-orders');
        }
      }

      setInitialized(true);
      //eslint-disable-next-line array-callback-return
      loadItems();
      loadAwaitingApprovalTotal();

      // loadCustomer();
      if (POCreatorPermission()) {
        loadOrdersExportType();
        // if (InvoiceAdminPermission()) {
        loadInvoicesExportType();
        // }
      }
    }

    const stopExportLoading = () => {
      setExportLoading(false);
    };

    const listener3 = EventEmitter.addListener('export_loading', stopExportLoading);

    const stopSearchingLoading = () => {
      setSearchingLoading(false);
    };

    const listener4 = EventEmitter.addListener('searching_loading', stopSearchingLoading);

    const reloadItem = args => {
      loadItems(args.value);
    };

    const listener5 = EventEmitter.addListener('reload_item', reloadItem);

    const onReloadItems = eventData => {
      if (eventData.path) {
        loadItems(eventData.path);
      }
    };

    const listener6 = EventEmitter.addListener('reloadItems', onReloadItems);

    const onSearchReload = eventData => {
      if (eventData.value) {
        setSearch(eventData.value);
      }
    };

    const listener7 = EventEmitter.addListener('reload_search', onSearchReload);
    
    const onRejectApproval = async () => {
      await loadAwaitingApprovalTotal();
    };

    const listener8 = EventEmitter.addListener('action_approval', onRejectApproval);

    return () => {
      listener3.remove();
      listener4.remove();
      listener5.remove();
      listener6.remove();
      listener7.remove();
      listener8.remove();
    };
  }, []);

  const loadItems = new_pathname => {
    var pathname = window.location.pathname;
    
    if (new_pathname) {
      pathname = new_pathname;
    }

    var checkItem = false;
    if (items()) {
      //eslint-disable-next-line array-callback-return
      items().map(item => {
        if (item.href === pathname) {
          setFirstUrl(item.href);
          setFeatures(item.features);
          checkItem = true;
        }
      });
    }

    if (!checkItem) {
      setFeatures({});
    }
  };

  const loadOrdersExportType = async () => {
    let orders_export = window.localStorage.getItem(orderTypeLocalStorageName) === 'sales-orders' ? 'sales_orders_export' : 'orders_export';

    try {
      var ExportResult = await directus.getItems(orders_export, {
        fields: '*.*',
        sort: 'number'
      });
      setOrdersExportData(ExportResult.data);
    } catch (e) {
      enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200, action: CloseSnackbarAction });
    }
  };

  const loadAwaitingApprovalTotal = async () => {
    try {
      var awaitingApprovalTotalValue = await directus.api.get('/custom/orders/awaiting_approval/total');      
      setAwaitingApprovalsTotal(awaitingApprovalTotalValue.total);
    } catch (e) {
      enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200, action: CloseSnackbarAction });
    }
  };

  const loadInvoicesExportType = async () => {
    try {
      var ExportResult = await directus.getItems('invoices_export', {
        fields: '*.*',
        sort: 'number'
      });
      setInvoicesExportData(ExportResult.data);
    } catch (e) {
      enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200, action: CloseSnackbarAction });
    }
  };

  const TopbarSearch = () => {
    if (features.search === true) {
      return (
        <span>
          <InputBase
            id="search_order"
            name="searchOrder"
            style={{ marginRight: !features.add && !features.sort && !features.filter ? '20px' : '0px' }}
            variant="outlined"
            placeholder="Search"
            size="small"
            // margin="dense"
            onChange={handleChange}
            onKeyUp={e => searchOrder(e)}
            className={classes.searchbox}
            value={search}
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon className={classes.IconSearch} />
              </InputAdornment>
            }
            endAdornment={
              <InputAdornment position="end">
                {searchingLoading ? <CircularProgress style={{ width: '20px', height: '20px' }} className={classes.circular} /> : <div></div>}
              </InputAdornment>
            }
          />
        </span>
      );
    }
  };

  const TopbarAdd = () => {
    if (features.add === true) {
      return (
        <span>
          <IconButton id="add_order_menu" className={classes.add} onClick={() => addMyOrder()}>
            <AddIcon fontSize="large" className={classes.iconAdd} />
          </IconButton>
        </span>
      );
    }
  };

  const TopbarFilter = () => {
    if (features.filter === true) {
      return (
        <span>
          <IconButton id="filter_menu" className={classes.topFilter} onClick={handleClick3} style={{ background: isFilter ? '#82CB43' : '' }}>
            {/* <i className="fas fa-filter" style={{ color: isFilter ? "#FFFFFF" : "" }}></i> */}
            <FilterListRoundedIcon style={{ color: isFilter ? '#FFFFFF' : '#516573', fontSize: '24px' }} />
          </IconButton>
        </span>
      );
    }
  };

  const TopbarExport = () => {
    var exportData = ordersExportData;
    if (window.location.pathname === '/invoices') {
      exportData = invoicesExportData;
    }
    if (features.export === true) {
      return (
        <span>
          <IconButton id="filter_menu" className={classes.topFilter} onClick={handleClick4}>
            <CloudDownloadRoundedIcon style={{ color: '#516573', fontSize: '24px' }} />
          </IconButton>
          <Menu anchorEl={anchorEl4} keepMounted open={Boolean(anchorEl4)} onClose={handleClose4} className={classes.filterandsort}>
            {exportData.length > 0
              ? exportData.map((value, index) => (
                <MenuItem key={index} variant="subtitle1" onClick={() => exportEmit(value)} selected={value.id === selectedExport}>
                  {value.name}
                </MenuItem>
              ))
              : ''}
          </Menu>
        </span>
      );
    }
  };

  const goToPage = value => {
    if (value !== '/supplier_requests') {
      var path_name = window.location.pathname;
      if (path_name !== value) {

        //replace sales
        if (value === '/orders') {
          value = '/' + orderTypeSelected;
        }

        EventEmitter.emit('tab_click', { value: value });
        //eslint-disable-next-line array-callback-return
        items().map(item => {
          if (item.href === value) {
            setFirstUrl(item.href);
            setFeatures(item.features);
          }
        });

        if (value === '/settings') {
          navigate(value);
        } else {
          EventEmitter.emit('checkShowHideFilter', { value: value });
        }
      }
    } else {
      EventEmitter.emit('reload_item', { value: value });
      EventEmitter.emit('hideFilter', { value: true });
      navigate(value);
    }
  };

  const handleChangeInput = e => {
    const { name, value } = e.target;

    if (name === 'Email Notifications Enabled') {
      setEmailNotificationStatus(e.target.checked);
    } else if (name === 'firstname') {
      setFirstName(value);
    } else if (name === 'lastname') {
      setLastName(value);
    } else if (name === 'mobile_number') {
      setMobileNumber(value);
    } else if (name === 'password') {
      setPassword({ ...password, password: value });
    } else if (name === 'search_deleted_customers') {
      setSearchDeletedCustomers(e.target.checked);
    } else if (name === 'search_deleted_suppliers') {
      setSearchDeletedSuppliers(e.target.checked);
    }
  };

  const handleOpenProfile = async () => {
    setOpenProfile(true);
    let myId = JSON.parse(window.localStorage.getItem('directus_user'));
    getUser(myId.id);
    handleClose();
  };

  const handleCloseProfile = () => {
    setOpenProfile(false);
  };

  const getUser = async id => {
    setUserLoading(true);
    var User = await directus.getUser(id);
    setUserID(User.data.id);
    setFirstName(User.data.first_name);
    setLastName(User.data.last_name);
    setEmail(User.data.email);
    setEmployee(Employee.id);
    setMobileNumber(Employee.mobile_number);
    setEmailNotificationStatus(Employee.enable_email_notifications);
    setSearchDeletedCustomers(Employee.search_deleted_customers);
    setSearchDeletedSuppliers(Employee.search_deleted_suppliers);
    setUserLoading(false);
  };

  const handleClickShowPassword = () => {
    setPassword({
      ...password,
      showPassword: !password.showPassword
    });
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  const sendEmail = () => {
    setLoading(true);
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
    };

    fetch(`${react_app_url}${JSON.parse(window.localStorage.getItem('company'))}/users/${emailRef.current}`, requestOptions).then(res =>
      console.log(res)
    );

    setLoading(false);
    handleClose();
  };

  const SaveProfile = async () => {
    setLoading(true);

    try {
      await directus.updateUser(userID, {
        first_name: firstNameRef.current,
        last_name: lastNameRef.current
      });
    } catch (e) {
      enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200, action: CloseSnackbarAction });
    }

    if (emailNotificationStatusRef.current !== null || mobileNumberRef.current !== null) {
      try {
        await directus.updateItem('employees', employeeRef.current, {
          enable_email_notifications: emailNotificationStatusRef.current,
          // search_deleted_customers: searchDeletedCustomersRef.current,
          // search_deleted_suppliers: searchDeletedSuppliersRef.current,
          mobile_number: mobileNumberRef.current
        });
      } catch (e) {
        enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200, action: CloseSnackbarAction });
      }
    }

    if (password !== null && password !== '' && password !== 'null') {
      if (password.password) {
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + directus.api.auth.config.token },
          body: JSON.stringify({ password: password.password })
        };

        fetch(`${react_app_url}${JSON.parse(window.localStorage.getItem('company'))}/custom/auth/setpassword/` + userIDRef.current, requestOptions)
          .then(response => response.text())
          .then(result => {
            // console.log(result);
          });
      }
    }

    var result = await directus.getItem('employees', employeeRef.current, { fields: '*.*' });
    if (!result.error) {
      window.localStorage.setItem('directus_employee', JSON.stringify(result.data));
    }

    setLoading(false);
    handleCloseProfile();
  };

  //display settings
  const handleOpenDisplaySettings = async () => {
    setOpenDisplaySettings(true);
    setDisplaySettingsLoading(true);
    let emp_data = JSON.parse(window.localStorage.getItem('directus_employee'));
    setFilterBarAlwaysDisplayed(emp_data ? (emp_data.filter_bar_always_displayed ? true : false) : false);
    setEnableApprovalWarning(emp_data ? (emp_data.enable_approval_warning ? true : false) : false);
    let myId = JSON.parse(window.localStorage.getItem('directus_user'));
    getUser(myId.id);
    handleClose();
    setDisplaySettingsLoading(false);
  };

  const handleCloseDisplaySettings = () => {
    setOpenDisplaySettings(false);
  };

  const handleChangeFilterBar = e => {
    setFilterBarAlwaysDisplayed(e.target.checked);
  };

  const handleChangeApprovalWarning = e => {
    setEnableApprovalWarning(e.target.checked);
  };

  const SaveDisplaySettings = async () => {
    setLoading(true);

    try {
      var employee_id = await getEmployee();
      await directus.updateItem('employees', employee_id.id, {
        filter_bar_always_displayed: filterBarAlwaysDisplayedRef.current,
        enable_approval_warning: enableApprovalWarningRef.current,
        search_deleted_customers: searchDeletedCustomersRef.current,
        search_deleted_suppliers: searchDeletedSuppliersRef.current,
      });

      var result = await directus.getItem('employees', employee_id.id, { fields: '*.*' });
      if (!result.error) {
        window.localStorage.setItem('directus_employee', JSON.stringify(result.data));
        EventEmitter.emit('update_filter_customer_supplier', { value: true });
      }
      setLoading(false);
      handleCloseDisplaySettings();

      EventEmitter.emit('show_approval_warning', { value: enableApprovalWarningRef.current });

      if (filterBarAlwaysDisplayedRef.current) {
        openFilterBar();
      } else {
        EventEmitter.emit('checkShowHideFilter', { value: true });
      }
    } catch (e) {
      enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200, action: CloseSnackbarAction });
    }
  };

  var pathname = window.location.pathname;

  return (
    <AppBar className={clsx(classes.root, className)} elevation={0} {...rest}>
      <Toolbar className={classes.toolbar}>
        <RouterLink to="/">
          <Logo className={classes.logo} />
        </RouterLink>
        <div>
          <div className={classes.ordersTypeWrapperStyle} onClick={handleOrdersTypeOpen}>
            <div className={classes.ordersTypeStyle}>
              {orderTypeSelected === 'purchase-orders' ? 'Purchase Orders' : 'Sales Orders'}
            </div>
            <div style={{ display: 'flex'}}>
              <KeyboardArrowDownIcon className={classes.arrowDownIcon} fontSize="small" />
            </div>
          </div>
          <StyledMenu
            id="customized-menu"
            anchorEl={anchorElOrdersType}
            keepMounted
            open={Boolean(openOrderType)}
            onClose={handleOrdersTypeClose}
            className={classes.listStyle}
          >
            <List>
              <MenuItem disabled={orderTypeSelected === 'purchase-orders'} className={classes.menuitem} onClick={() => changeOrderType('purchase-orders')} >
                Purchase Orders
                <ListItemIcon className={classes.listitemicon}>
                  <img src={"/static/images/po.svg"} />
                </ListItemIcon>
              </MenuItem>
              <MenuItem disabled={orderTypeSelected === 'sales-orders'} className={classes.menuitem} onClick={() => changeOrderType('sales-orders')}>
                Sales Orders
                <ListItemIcon className={classes.listitemicon}>
                  <img src={"/static/images/so.svg"} />
                </ListItemIcon>
              </MenuItem>
            </List>
          </StyledMenu>
        </div>
        <RouterLink to={orderTypeSelected} onClick={() => goToPage('/orders')}>
          <div
            className={`${classes.topbarTab} ${pathname === '/' || pathname === '/purchase-orders' || pathname === '/sales-orders' ? classes.selectedTab : ''}`}
            style={{ marginLeft: '36.67px' }}
          >
            Orders
          </div>
        </RouterLink>
        {POCreatorPermission() && showInvoiceTab && orderTypeSelected === 'purchase-orders' ? (
          <RouterLink to="/invoices" onClick={() => goToPage('/invoices')}>
            <div className={`${classes.topbarTab} ${pathname === '/invoices' ? classes.selectedTab : ''}`}>Invoices</div>
          </RouterLink>
        ) : null}
        {POCreatorPermission() && orderTypeSelected === 'purchase-orders' ? (
          <RouterLink to="/approvals" onClick={() => goToPage('/approvals')}>
            <div className={`${classes.topbarTab} ${pathname.includes('approval') ? classes.selectedTab : ''} ${classes.approvalstab}`}>
              Approvals
              {awaitingApprovalsTotal !== 0 && awaitingApprovalsTotal !== '0' ? 
                <div className={classes.approvalstotal}>
                  {awaitingApprovalsTotal}
                </div>
              : ''}
            </div>
          </RouterLink>
        ) : null}
        {AdminPermission() ? (
          <RouterLink to="/settings" onClick={() => goToPage('/settings')}>
            <div className={`${classes.topbarTab} ${pathname === '/settings' ? classes.selectedTab : ''}`}>Settings</div>
          </RouterLink>
        ) : null}
        <Box flexGrow={5} />
        <>
          {!exportLoading ? (
            <>
              <Hidden lgUp>
                {Employee ? (Employee.filter_bar_always_displayed ? '' : TopbarFilter()) : TopbarFilter()}
                {TopbarExport()}
                {TopbarAdd()}
                {/* {TopbarSearch()} */}
              </Hidden>
              <Hidden mdDown>
                {TopbarSearch()}
                {Employee ? (Employee.filter_bar_always_displayed ? '' : TopbarFilter()) : TopbarFilter()}
                {TopbarExport()}
                {TopbarAdd()}
              </Hidden>
            </>
          ) : (
            <CircularProgress style={{ color: '#5bfa17', marginRight: '10px', height: '30px', width: '30px' }} />
          )}

          <Avatar id="avatar_menu" className={classes.avatar2} src={user.avatar} onClick={handleMenu} />

          <Menu
            id="menu_topbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            isopen={`${open}`}
            open={open}
            onClose={handleClose}
          >
            <List sx={{ width: '100%', maxWidth: 500 }}>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar className={classes.avatar} src={user.avatar} />
                </ListItemAvatar>
                <ListItemText
                  primaryTypographyProps={{ id: 'user-job_title' }}
                  primary={user.jobTitle}
                  secondaryTypographyProps={{ component: 'div' }}
                  secondary={
                    <>
                      <Typography
                        sx={{ display: 'inline' }}
                        component="span"
                        variant="body2"
                      // color="text.primary"
                      >
                        <span id="user-company">{company}</span> - <span id="user-role">{user.role}</span>
                        <br />
                        <h5>
                          <i> v 2.0.1 </i>
                        </h5>
                      </Typography>
                    </>
                  }
                />
              </ListItem>
              <Divider variant="inset" component="li" />

              <MenuItem onClick={handleOpenProfile}>
                <ListItemIcon>
                  <ManageAccountsRoundedIcon fontSize="small" style={{ marginLeft: '1px' }} />
                </ListItemIcon>
                Profile
              </MenuItem>
              <MenuItem onClick={handleOpenDisplaySettings}>
                <ListItemIcon>
                  <DisplaySettingsRoundedIcon fontSize="small" />
                </ListItemIcon>
                User Settings
              </MenuItem>
              {POCreatorPermission() ? (
                <MenuItem onClick={() => goToPage('/supplier_requests')}>
                  <ListItemIcon>
                    <PersonAddRoundedIcon fontSize="small" />
                  </ListItemIcon>
                  Supplier Requests
                </MenuItem>
              ) : (
                ''
              )}

              <MenuItem
                id="logout_btn"
                onClick={() => {
                  window.localStorage.clear();
                  window.location.reload();
                }}
              >
                <ListItemIcon>
                  <ExitToAppIcon fontSize="small" />
                </ListItemIcon>
                Logout
              </MenuItem>
            </List>
          </Menu>

          <Dialog open={openProfile} isopen={`${openProfile}`} onClose={handleCloseProfile} aria-labelledby="employee_dialog" fullWidth>
            {userLoading ? (
              <DialogContent style={{ textAlign: 'center' }}>
                <CircularProgress className={classes.circularProfile} />
              </DialogContent>
            ) : (
              <>
                <DialogTitle id="form-dialog-title">
                  <Typography id="employee_dialog_title" component="div" variant="h6">
                    <h2>
                      {firstNameRef.current} {lastNameRef.current}'s Profile
                    </h2>
                  </Typography>
                </DialogTitle>
                <DialogContent>
                  <Grid container>
                    {configuration ? (
                      configuration.employee_settings === true ? (
                        <>
                          <Grid item xs={12}>
                            <TextField
                              id="firstname_text"
                              label="First Name"
                              variant="outlined"
                              name="firstname"
                              value={firstNameRef.current}
                              fullWidth={true}
                              autoComplete="off"
                              onChange={handleChangeInput}
                            />
                            <br />
                            <br />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              id="lastname_text"
                              label="Last Name"
                              variant="outlined"
                              name="lastname"
                              value={lastNameRef.current}
                              fullWidth={true}
                              autoComplete="off"
                              onChange={handleChangeInput}
                            />
                            <br />
                            <br />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              id="outlined-basic"
                              label="Mobile Number"
                              variant="outlined"
                              name="mobile_number"
                              value={mobileNumberRef.current}
                              fullWidth={true}
                              autocomplete="off"
                              onChange={handleChangeInput}
                            />
                            <br />
                            <br />
                          </Grid>
                        </>
                      ) : (
                        ''
                      )
                    ) : (
                      ''
                    )}
                    <Grid item xs={12}>
                      <FormControl fullWidth={true} variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                        <OutlinedInput
                          id="outlined-adornment-password"
                          type={password.showPassword ? 'text' : 'password'}
                          label="Password"
                          name="password"
                          onChange={handleChangeInput}
                          autocomplete="off"
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {password.showPassword ? <VisibilityOff /> : <Visibility />}
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                      </FormControl>
                      <Button onClick={() => sendEmail()} color="primary">
                        Send Reset Password Email
                      </Button>
                      <br />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Switch
                            name="Email Notifications Enabled"
                            checked={emailNotificationStatusRef.current ? true : false}
                            onClick={handleChangeInput}
                          />
                        }
                        label="Email Notifications Enabled"
                      />
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions style={{ height: '60px' }}>
                  {!loading ? (
                    <>
                      <CancelButton id="cancel_btn" size="small" variant="contained" onClick={handleCloseProfile}>
                        Cancel
                      </CancelButton>

                      <SubmitButton id="save_btn" size="small" variant="contained" onClick={() => SaveProfile()}>
                        Save
                      </SubmitButton>
                    </>
                  ) : (
                    <CircularProgress className={classes.circular} />
                  )}
                </DialogActions>
              </>
            )}
          </Dialog>

          <Dialog
            open={openDisplaySettings}
            isopen={`${openDisplaySettings}`}
            onClose={handleCloseDisplaySettings}
            aria-labelledby="display_settings_dialog"
            fullWidth
          >
            {displaySettingsLoading ? (
              <DialogContent style={{ textAlign: 'center' }}>
                <CircularProgress className={classes.circularProfile} />
              </DialogContent>
            ) : (
              <>
                <DialogTitle id="form-dialog-title">
                  <Typography id="employee_dialog_title" component="div" variant="h6">
                    <h2>User Settings</h2>
                  </Typography>
                </DialogTitle>
                <DialogContent>
                  <Grid container>
                    <Grid item xs={6}>
                      {filterBarAlwaysDisplayedRef.current === true ? (
                        <FormControlLabel
                          control={<Switch name="Filter Bar Always Displayed" defaultChecked onChange={handleChangeFilterBar} />}
                          label="Filter Bar Always Displayed"
                        />
                      ) : (
                        <FormControlLabel
                          control={<Switch name="Filter Bar Always Displayed" onChange={handleChangeFilterBar} />}
                          label="Filter Bar Always Displayed"
                        />
                      )}
                    </Grid>
                    <Grid item xs={6}>
                      {enableApprovalWarningRef.current === true ? (
                        <FormControlLabel
                          control={<Switch name="Show Approval Warning" defaultChecked onChange={handleChangeApprovalWarning} />}
                          label="Show Approval Warning"
                        />
                      ) : (
                        <FormControlLabel
                          control={<Switch name="Show Approval Warning" onChange={handleChangeApprovalWarning} />}
                          label="Show Approval Warning"
                        />
                      )}
                    </Grid>
                    <Grid item xs={6}>
                      <FormControlLabel
                        control={
                          <Switch
                            name="search_deleted_customers"
                            checked={searchDeletedCustomersRef.current}
                            onClick={handleChangeInput}
                          />
                        }
                        label="Search Deleted Customers"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FormControlLabel
                        control={
                          <Switch
                            name="search_deleted_suppliers"
                            checked={searchDeletedSuppliersRef.current}
                            onClick={handleChangeInput}
                          />
                        }
                        label="Search Deleted Suppliers"
                      />
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions style={{ height: '60px' }}>
                  {!loading ? (
                    <>
                      <CancelButton id="cancel_btn" size="small" variant="contained" onClick={handleCloseDisplaySettings}>
                        Cancel
                      </CancelButton>

                      <SubmitButton id="save_btn" size="small" variant="contained" onClick={() => SaveDisplaySettings()}>
                        Save
                      </SubmitButton>
                    </>
                  ) : (
                    <CircularProgress className={classes.circular} />
                  )}
                </DialogActions>
              </>
            )}
          </Dialog>
        </>
      </Toolbar>
    </AppBar>
  );
};
TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};
export default TopBar;
