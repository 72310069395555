import React from 'react';
import { useStyles } from './style.js';
import { OverflowTip } from "src/components/OverflowTip";
import {
  Grid,
  Typography,
} from "@material-ui/core";

const OrderItemsNotDraftView = ({
  dataItem,
  index,
  showPOInternalCategory,
  matched_invoice,
  getInvoicedQty,
  isLastItem,
  formatNumber
}) => {
  const classes = useStyles();

  return (
    <div>
      <Grid key={index} container spacing={0} justifyContent="space-around" style={{ marginBottom: isLastItem ? '0px' : '5px' }}>
        <Grid
          item
          xs={showPOInternalCategory ? 7 : 12}
          sm={showPOInternalCategory ? 7 : 12}
          md={showPOInternalCategory ? 7 : 12}
          lg={showPOInternalCategory ? 7 : 12}
        >
          <div style={{ paddingRight: showPOInternalCategory ? '16px' : '0px' }}>
            <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: 'white', padding: showPOInternalCategory ? '0px 0px 0px 5px' : '0px 16px 0px 5px', alignItems: 'center', borderRadius: '10px' }}>
              <Grid container spacing={0} justifyContent="space-around" style={{ height: '44px', display: 'flex', alignItems: 'center' }} >
                <Grid item xs={1} sm={1} md={1} lg={1}>
                  <Typography noWrap component="div" className={classes.typographyStyle2} style={{ paddingLeft: '6px'}}>
                    <OverflowTip>{dataItem.quantity}</OverflowTip>
                  </Typography>
                </Grid>
                <Grid item xs={2} sm={2} md={2} lg={2}>
                  <Typography noWrap component="div" className={classes.typographyStyle2} style={{ paddingLeft: '5px' }}>
                    <OverflowTip>{dataItem.supplier_code}</OverflowTip>
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6}>
                  <Typography noWrap component="div" className={classes.typographyStyle2} style={{ paddingRight: '10px', paddingLeft: '5px' }}>
                    <OverflowTip>{dataItem.description}</OverflowTip>
                  </Typography>
                </Grid>
                <Grid item xs={3} sm={3} md={3} lg={3}>
                  <Typography noWrap component="div" className={classes.typographyStyle2}>
                    <OverflowTip>
                      {isNaN(dataItem.unit_price) ? 0 : '$' + formatNumber((Math.round(dataItem.unit_price * 100) / 100).toFixed(2))}
                    </OverflowTip>
                  </Typography>
                </Grid>
                {/* <Grid item xs={2} sm={2} md={2} lg={2}>
                  <Typography noWrap component="div" className={`${classes.typographyStyle2} ${classes.flexEnd}`} style={{ paddingRight: '10px' }}>
                    <OverflowTip>
                    {matched_invoice ? getInvoicedQty(dataItem) : ''}
                    </OverflowTip>
                  </Typography>
                </Grid> */}
              </Grid>
            </div>
          </div>
        </Grid>
        {showPOInternalCategory ?
          <Grid item xs={5} sm={5} md={5} lg={5}>
            <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: 'rgba(130, 203, 67, 0.15)', padding: '0px 16px', alignItems: 'center', borderRadius: '10px' }}>
              <Grid container spacing={0} justifyContent="space-around" style={{ height: '44px', display: 'flex', alignItems: 'center' }} >
                <Grid item xs={6} sm={6} md={6} lg={6}>
                  <Typography noWrap component="div" className={classes.typographyStyle2} style={{ paddingRight: '10px'}}>
                    <OverflowTip>
                      {dataItem.category_type
                        ? dataItem.category_type.name
                        : dataItem.category_type}
                    </OverflowTip>
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6}>
                  <Typography noWrap component="div" className={classes.typographyStyle2}>
                    <OverflowTip>
                      {dataItem.category_description
                        ? dataItem.category_description.name
                          ? dataItem.category_description.name
                          : dataItem.category_description
                        : dataItem.category_description
                        }
                    </OverflowTip>
                  </Typography>
                </Grid>
              </Grid>
            </div>
          </Grid>
        : ''}
      </Grid>
    </div>
  )
}

export default OrderItemsNotDraftView