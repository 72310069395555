import React, { useState, useEffect } from "react";
import {
  Typography,
  Container,
  CircularProgress,
  Grid,
  Button,
  MenuItem,
  TextField,
  makeStyles,
  LinearProgress,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from "@material-ui/core";
import Page from "src/components/Page";
import Paper from "@material-ui/core/Paper";
import { enableRipple } from "@syncfusion/ej2-base";
import directus from "src/services/directus";
import { useStyles } from "./style.js";
import InvoiceList from "./InvoiceList";
import useStateRef from "react-usestateref";
import EventEmitter from "src/utils/EventEmitter";
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";
import ArrowForwardIosRoundedIcon from "@material-ui/icons/ArrowForwardIosRounded";
import List from "@material-ui/core/List";
import ListSubheader from "@material-ui/core/ListSubheader";
import { pageOptions } from "src/_utils/pageOptions";
import FileUploadIcon from "@mui/icons-material/FileUpload";

import moment from "moment";
import { POApproverPermission, InvoiceAdminPermission } from "src/utils/Permission";
import linq from "linq";
// import Enumerable from 'linq';
import SortRoundedIcon from "@material-ui/icons/SortRounded";
import { withStyles } from "@material-ui/core/styles";
// import { getEmployee } from 'src/utils/sessions';
import MarkEmailReadOutlinedIcon from "@mui/icons-material/MarkEmailReadOutlined";
import { OverflowTip } from "src/components/OverflowTip";
import { useSnackbar } from "notistack";
import ErrorMessage from "src/components/ErrorMessage";
import { CloseSnackbarAction } from 'src/components/CloseSnackbarAction';

const useStyles2 = makeStyles(theme => ({
  root: {
    marginRight: "28px",
    marginLeft: "28px",
    position: "relative",
    overflow: "auto"
    // maxHeight: window.innerHeight - 250,
  }
}));

const ApprovedButton = withStyles(theme => ({
  root: {
    backgroundColor: "#82cb43cc",
    color: "white",
    height: "36px",
    display: "inline-grid",
    borderRadius: "10px",
    "&:hover": {
      backgroundColor: "#82CB43"
    }
  }
}))(Button);

const CancelButton = withStyles({
  root: {
    backgroundColor: "#e0e0e0de",
    borderRadius: "10px",
    height: "36px",
    "&:hover": {
      backgroundColor: "#bdbdbdde"
    }
  }
})(Button);

const CustomTooltip = withStyles({
  tooltip: {
    fontSize: "16px",
    maxWidth: 500,
    backgroundColor: "grey"
  }
})(Tooltip);

export const SubmitButton = withStyles({
  root: {
    borderRadius: "24px",
    backgroundColor: "#2AC940",
    color: "#ffffff",
    height: "30px",
    width: "120px",
    "&:hover": {
      backgroundColor: "#22a033"
    }
  }
})(Button);

export const SubmitButton2 = withStyles({
  root: {
    borderRadius: "24px",
    backgroundColor: "#2AC940",
    color: "#ffffff",
    height: "30px",
    width: "120px",
    "&:hover": {
      backgroundColor: "#22a033"
    }
  }
})(Button);

export const CancelButton2 = withStyles({
  root: {
    borderRadius: "24px",
    backgroundColor: "#e0e0e0de",
    height: "30px",
    width: "120px",
    "&:hover": {
      backgroundColor: "#bdbdbdde"
    }
  }
})(Button);

// let page = 1;
const IndexView = () => {
  /* eslint-disable no-unused-vars*/
  const classes = useStyles();
  const classess = useStyles2();
  const [initialized, setInitialized] = useState(false);
  const [invoices, setInvoices, invoicesRef] = useStateRef(null);
  // const [logs, setLogs] = useState([]);
  // const [sort, setSort, sortRef] = useStateRef('');
  const [search, setSearch, searchRef] = useStateRef("");
  // const [filter, setFilter, filterRef] = useStateRef("");
  const [totalPage, setTotalPage, totalPageRef] = useStateRef("");
  const [pageNumber, setPageNumber, pageNumberRef] = useStateRef(100);
  const [invoiceLoading, setInvoiceLoading] = React.useState(false);
  const [invoiceReload, setInvoiceReload, invoiceReloadRef] = useStateRef(false);
  const [loadingPagination, setLoadingPagination] = useState(false);
  const [page, setPage, pageRef] = useStateRef(1);

  const [filterData, setFilterData, filterDataRef] = useStateRef({
    date: 6,
    date_range: [],
    order: ["all"],
    invoice: ["all"],
    supplier: ["0"],
    customer: ["0"],
    po_category: ["0"],
    isMyOrder: POApproverPermission() ? false : true,
    isAnyPrice: true,
    price: { min: 0, max: 0 }
  });

  const [sortField, setSortField, sortFieldRef] = useStateRef({
    order_number: { type: "desc", value: false },
    date: { type: "desc", value: false },
    supplier: { type: "desc", value: false },
    customer: { type: "desc", value: false },
    invoice_number: { type: "desc", value: false },
    invoice_status: { type: "desc", value: false },
    due_date: { type: "desc", value: false },
    amount: { type: "desc", value: false },
    sent_to_accounting: { type: "desc", value: false },
    matched_po: { type: "desc", value: false }
  });

  const [sortLoading, setSortLoading, sortLoadingRef] = useStateRef(false);
  const [filterLoading, setFilterLoading, filterLoadingRef] = useStateRef(false);

  const [isMultiSelect, setIsMultiSelect, isMultiSelectRef] = useStateRef(
    InvoiceAdminPermission() ? (localStorage.isMultiSelect ? (localStorage.isMultiSelect === "true" ? true : false) : false) : false
  );
  const [openImportInvoice, setOpenImportInvoice, openImportInvoiceRef] = useStateRef(false);
  const [importInvoiceLoading, setImportInvoiceLoading, importInvoiceLoadingRef] = useStateRef(false);
  const [selectedInvoiceFile, setSelectedInvoiceFile, selectedInvoiceFileRef] = useStateRef(null);
  const [saveFilterData, setSaveFilterData, saveFilterDataRef] = useStateRef(null);
  const [saveSortData, setSaveSortData, saveSortDataRef] = useStateRef(null);

  const { enqueueSnackbar } = useSnackbar();

  var react_app_url = process.env.REACT_APP_URL;
  if (window.location.hostname.search("interax") !== -1) {
    react_app_url = process.env.REACT_APP_URL_INTERAX;
  }
  /* eslint-enable no-unused-vars*/

  enableRipple(true);

  /* eslint-disable react-hooks/exhaustive-deps*/
  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
      loadFirst();
    }
  }, []);
  /* eslint-enable react-hooks/exhaustive-deps*/

  useEffect(() => {
    const onSearch = async eventData => {
      setSearch(eventData.text);
      setFilterLoading(true);
      await loadInvoices(eventData.text, "");
      setPage(1);
      EventEmitter.emit("searching_loading", {
        value: false
      });
    };
    const listener = EventEmitter.addListener(window.location.pathname, onSearch);

    const onExportOrder = eventData => {
      exportOrder(eventData.text);
    };
    const listener2 = EventEmitter.addListener("export", onExportOrder);

    const onShowHideMultiSelect = eventData => {
      setIsMultiSelect(eventData.value);
    };

    const listener3 = EventEmitter.addListener("show_hide_multi_select", onShowHideMultiSelect);

    return () => {
      listener.remove();
      listener2.remove();
      listener3.remove();
    };
  });

  useEffect(() => {
    const onReload = value => {
      setInvoiceReload(true);
      loadInvoices("", value.loc);
    };
    const listener2 = EventEmitter.addListener("reload", onReload);

    return () => {
      listener2.remove();
    };
  });

  /* eslint-disable react-hooks/exhaustive-deps*/
  useEffect(() => {
    const onFilterData = async eventData => {
      setFilterData(eventData);
      setPage(1);
      setFilterLoading(true);
      await loadInvoices();
      EventEmitter.emit("searching_loading", {
        value: false
      });
    };
    const listener1 = EventEmitter.addListener("filter_data", onFilterData);

    return () => {
      listener1.remove();
    };
  }, []);
  /* eslint-enable react-hooks/exhaustive-deps*/

  const loadFirst = () => {
    var filter_data = localStorage.filter_data;
    if (filter_data) {
      filter_data = JSON.parse(filter_data);
      if (!filter_data.price) {
        filter_data.price = {
          min: 0,
          max: 0
        };
        localStorage.setItem("filter_data", JSON.stringify(filter_data));
      }

      if (!filter_data.po_category) {
        filter_data.po_category = ["0"];
      }

      setFilterData(filter_data);
    }

    var sort_data = localStorage.sort_data;
    if (sort_data) {
      sort_data = JSON.parse(sort_data);
      if (sort_data.invoices) {
        var sort_data_invoices = sort_data.invoices;
        if (!sort_data_invoices.order_number) {
          sort_data_invoices.order_number = { type: "desc", value: false };
        } else if (!sort_data_invoices.date) {
          sort_data_invoices.date = { type: "desc", value: false };
        } else if (!sort_data_invoices.supplier) {
          sort_data_invoices.supplier = { type: "desc", value: false };
        } else if (!sort_data_invoices.customer) {
          sort_data_invoices.customer = { type: "desc", value: false };
        } else if (!sort_data_invoices.invoice_number) {
          sort_data_invoices.invoice_number = { type: "desc", value: false };
        } else if (!sort_data_invoices.invoice_status) {
          sort_data_invoices.invoice_status = { type: "desc", value: false };
        } else if (!sort_data_invoices.due_date) {
          sort_data_invoices.due_date = { type: "desc", value: false };
        } else if (!sort_data_invoices.amount) {
          sort_data_invoices.amount = { type: "desc", value: false };
        } else if (!sort_data_invoices.sent_to_accounting) {
          sort_data_invoices.sent_to_accounting = { type: "desc", value: false };
        } else if (!sort_data_invoices.matched_po) {
          sort_data_invoices.matched_po = { type: "desc", value: false };
        }
        setSortField(sort_data.invoices);
      }
    }

    loadInvoices();
  };

  const loadInvoices = async (data, loc) => {
    setInvoiceLoading(true);
    try {
      //matched with po filter
      var matched_po_index = filterDataRef.current.matched_po;
      var matched_po = "";
      if (matched_po_index) {
        if (matched_po_index[0] !== "all" && matched_po_index.length === 1) {
          matched_po = matched_po_index[0];
        }
      }

      var include_archive = filterDataRef.current.includeArchivedInvoices;

      //------------------ sort -------------- //
      var customer_sort = sortFieldRef.current.customer;
      var date_sort = sortFieldRef.current.date;
      var order_number_sort = sortFieldRef.current.order_number;
      var invoice_number_sort = sortFieldRef.current.invoice_number;
      var invoice_status_sort = sortFieldRef.current.invoice_status;
      var supplier_sort = sortFieldRef.current.supplier;
      var due_date_sort = sortFieldRef.current.due_date;
      var amount_sort = sortFieldRef.current.amount;
      var sent_to_accounting_sort = sortFieldRef.current.sent_to_accounting;
      var matched_po_sort = sortFieldRef.current.matched_po;

      var orderByFieldCustom = "-invoices.date";

      if (order_number_sort.value) {
        if (order_number_sort.type === "asc") {
          orderByFieldCustom = "-raw_po_number";
        } else if (order_number_sort.type === "desc") {
          orderByFieldCustom = "raw_po_number";
        }
      }

      if (supplier_sort.value) {
        if (supplier_sort.type === "asc") {
          orderByFieldCustom = "suppliers";
        } else if (supplier_sort.type === "desc") {
          orderByFieldCustom = "-suppliers";
        }
      }

      if (customer_sort.value) {
        if (customer_sort.type === "asc") {
          orderByFieldCustom = "customers";
        } else if (customer_sort.type === "desc") {
          orderByFieldCustom = "-customers";
        }
      }

      if (date_sort.value) {
        if (date_sort.type === "asc") {
          orderByFieldCustom = "invoices.date";
        } else if (date_sort.type === "desc") {
          orderByFieldCustom = "-invoices.date";
        }
      }

      if (invoice_number_sort.value) {
        if (invoice_number_sort.type === "asc") {
          orderByFieldCustom = "number";
        } else if (invoice_number_sort.type === "desc") {
          orderByFieldCustom = "-number";
        }
      }

      if (invoice_status_sort.value) {
        if (invoice_status_sort.type === "asc") {
          orderByFieldCustom = "invoice_status";
        } else if (invoice_status_sort.type === "desc") {
          orderByFieldCustom = "-invoice_status";
        }
      }

      if (due_date_sort.value) {
        if (due_date_sort.type === "asc") {
          orderByFieldCustom = "raw_due_date";
        } else if (due_date_sort.type === "desc") {
          orderByFieldCustom = "-raw_due_date";
        }
      }

      if (amount_sort.value) {
        if (amount_sort.type === "asc") {
          orderByFieldCustom = "amount";
        } else if (amount_sort.type === "desc") {
          orderByFieldCustom = "-amount";
        }
      }

      if (sent_to_accounting_sort.value) {
        if (sent_to_accounting_sort.type === "asc") {
          orderByFieldCustom = "sent_to_accounting";
        } else if (sent_to_accounting_sort.type === "desc") {
          orderByFieldCustom = "-sent_to_accounting";
        }
      }

      if (matched_po_sort.value) {
        if (matched_po_sort.type === "asc") {
          orderByFieldCustom = "matched_po";
        } else if (matched_po_sort.type === "desc") {
          orderByFieldCustom = "-matched_po";
        }
      }
      //------------------ filter -------------- //

      var invoice_date_value = { nnull: null };

      if (searchRef.current) {
        data = searchRef.current;
      }

      //date filter
      var index = filterDataRef.current.date;
      var start_date = moment().format("YYYY-MM-DD 00:00:00");
      var end_date = moment().format("YYYY-MM-DD 23:59:59");
      if (index === 2) {
        start_date = filterDataRef.current.date_range[0];
        end_date = filterDataRef.current.date_range[1];
      } else if (index === 3) {
        start_date = moment()
          .startOf("month")
          .format("YYYY-MM-DD 00:00:00");
        end_date = moment()
          .endOf("month")
          .format("YYYY-MM-DD 23:59:59");
      } else if (index === 4) {
        start_date = moment()
          .subtract(1, "months")
          .startOf("month")
          .format("YYYY-MM-DD 00:00:00");
        end_date = moment()
          .subtract(1, "months")
          .endOf("month")
          .format("YYYY-MM-DD 23:59:59");
      } else if (index === 5) {
        start_date = moment()
          .subtract(29, "days")
          .format("YYYY-MM-DD 00:00:00");
        end_date = moment().format("YYYY-MM-DD 23:59:59");
      } else if (index === 6) {
        start_date = moment()
          .subtract(2, "months")
          .startOf("month")
          .format("YYYY-MM-DD 00:00:00");
        end_date = moment()
          .endOf("month")
          .format("YYYY-MM-DD 23:59:59");
      }

      if (index !== 1) {
        invoice_date_value = { between: [start_date, end_date] };
      }

      //order status filter
      var order_index = filterDataRef.current.order;
      var order_status = { nnull: null };
      if (order_index[0] !== "all") {
        order_status = { in: order_index };
      }

      var show_invoices_status = true;
      //invoice status filter
      var invoice_index = filterDataRef.current.invoice;
      var invoice_status = { nnull: null };
      if (invoice_index[0] !== "all") {
        // var new_invoice_index = [];
        // if (POApproverPermission() && !InvoiceAdminPermission()) {
        // invoice_index.map((value) => {
        //   if (value === "matched" || value === "approved") {
        //     new_invoice_index.push(value);
        //   }
        // })

        // if (new_invoice_index.length > 0) {
        //   invoice_status = { "in": new_invoice_index };
        // } else {
        //   show_invoices_status = false;
        // }

        // } else {
        invoice_status = { in: invoice_index };
        // }
      }

      //supplier filter
      var supplier_index = filterDataRef.current.supplier;
      var supplier = { nnull: null };
      if (supplier_index[0] !== "0") {
        supplier = { in: supplier_index };
      }

      //customer filter
      var customer_index = filterDataRef.current.customer;
      var customer = { nnull: null };

      var show_invoices = false;

      // if (POApproverPermission() && !InvoiceAdminPermission() && show_invoices_status) {
      //   var employee_id = await getEmployee();
      //   var po_customer_employees = await directus.getItems('customers_approvers_level1', {
      //     fields: 'customer.id',
      //     filter: {
      //       "employee": { "in": employee_id.id },
      //       "customer.id": { "nnull": null },
      //     }
      //   });

      //   var po_customer_employees2 = await directus.getItems('customers_approvers_level2', {
      //     fields: 'customer.id',
      //     filter: {
      //       "employee": { "in": employee_id.id },
      //       "customer.id": { "nnull": null },
      //     }
      //   });

      //   if (po_customer_employees.data.length > 0 || po_customer_employees2.data.length > 0) {
      //     var po_customer_id = [];
      //     var po_customer_id2 = [];
      //     if (po_customer_employees.data.length > 0) {
      //       po_customer_id = Enumerable.from(po_customer_employees.data)
      //         .select(data => data.customer.id)
      //         .toArray();
      //     }

      //     if (po_customer_employees2.data.length > 0) {
      //       po_customer_id2 = Enumerable.from(po_customer_employees2.data)
      //         .where(w => !Enumerable.from(po_customer_id).contains(w.customer.id))
      //         .select(data => data.customer.id)
      //         .toArray();

      //       if (po_customer_id.length > 0) {
      //         po_customer_id = [...po_customer_id, ...po_customer_id2]
      //       }
      //     }

      //     if (po_customer_id.length > 0) {
      //       if (customer_index[0] !== "0") {
      //         var new_customer_index = [];
      //         customer_index.map((value) => {
      //           if (po_customer_id.includes(value)) {
      //             new_customer_index.push(value);
      //           }
      //         })

      //         customer = { "in": new_customer_index };
      //         show_invoices = true;
      //       } else {
      //         customer = { "in": po_customer_id };
      //         show_invoices = true;
      //       }
      //     }
      //     // setCustomerEmployee(po_customer_id);
      //     // customer = { "in": po_customer_id }
      //   }
      // } else {
      //   show_invoices = true;
      //   if (customer_index[0] !== "0") {
      //     customer = { "in": customer_index };
      //   }
      // }

      show_invoices = true;
      if (customer_index[0] !== "0") {
        customer = { in: customer_index };
      }
      var Invoices = null;

      var filter_data = {};

      filter_data = { "invoices.status": "published" };

      if (index !== 1) {
        filter_data["invoices.date"] = invoice_date_value;
      }
      if (invoice_index[0] !== "all") {
        filter_data["invoices.invoice_status"] = invoice_status;
      } else {
        // if (POApproverPermission() && !InvoiceAdminPermission()) {
        //   filter_data["invoices.invoice_status"] = invoice_status;
        // }
      }
      if (order_index[0] !== "all") {
        filter_data["orders.status"] = order_status;
      }
      if (supplier_index[0] !== "0" && supplier_index.length > 0) {
        filter_data["orders.supplier"] = supplier;
      }
      if (customer_index[0] !== "0" && customer_index.length > 0) {
        filter_data["orders.farm_code"] = customer;
      } else {
        // if (POApproverPermission() && !InvoiceAdminPermission()) {
        //   filter_data["orders.farm_code"] = customer;
        // }
      }

      if (matched_po === "matched") {
        filter_data["matched_with_po"] = "matched";
      } else if (matched_po === "unmatched") {
        filter_data["matched_with_po"] = "unmatched";
      }

      if (!filterDataRef.current.isAnyPrice && filterDataRef.current.price) {
        filter_data["total_price"] = [filterDataRef.current.price.min, filterDataRef.current.price.max];
      }

      //po category filter
      var po_category_index = filterDataRef.current.po_category;
      var po_category = { nnull: null };

      if (po_category_index) {
        if (po_category_index[0] !== "0" && po_category_index.length > 0) {
          po_category = { in: po_category_index };
          filter_data["orders.category_type"] = po_category;
        }
      }

      if (filterDataRef.current.po_category_description) {
        filter_data["category_type_description"] = filterDataRef.current.po_category_description;
      }

      if (show_invoices) {
        const params_fields = [
          "supplier.id",
          "supplier.code",
          "supplier.name",
          "supplier.status",
          "farm_code.id",
          "farm_code.code",
          "farm_code.name",
          "farm_code.status",
          "orders.orders_id.order_items.order_item.id",
          "orders.orders_id.order_items.order_item.status",
          "orders.orders_id.order_items.order_item.quantity",
          "orders.orders_id.order_items.order_item.unit_price",
          "orders.orders_id.order_items.*",
          "orders.orders_id.id",
          "orders.orders_id.order_number",
          "orders.orders_id.order_date",
          "orders.orders_id.status",
          "orders.orders_id.supplier.id",
          "orders.orders_id.supplier.status",
          "orders.orders_id.supplier.code",
          "orders.orders_id.supplier.name",
          "orders.orders_id.farm_code.id",
          "orders.orders_id.farm_code.status",
          "orders.orders_id.farm_code.code",
          "orders.orders_id.farm_code.name",
          "id",
          "status",
          "owner",
          "created_on",
          "invoice_status",
          "date",
          "number",
          "notes",
          "raw_po_number",
          "raw_supplier",
          "raw_customer",
          "date_matched",
          "total_amount",
          "raw_due_date",
          "dispute_notes",
          "sent_to_accounting",
          "previous_invoice_status",
          "approved_by",
          "approved_on",
          "approved_2_by",
          "approved_2_on",
          "matched_by",
          "matched_on",
          "modified_on"
        ];

        var request_header = {
          fields: params_fields,
          meta: "filter_count",
          limit: pageNumberRef.current,
          page: pageRef.current,
          sort: orderByFieldCustom,
          include_archive: include_archive
        }

        if (data) {
          request_header['q'] = data;
        } 

        var params = Object.entries(request_header).map(e => e.join('=')).join('&');

        Invoices = await directus.api.post(`/custom/invoices?${params}`, {
          filter: filter_data
        });

        let SortInvoices;
        // let sort_type = '';
        // let sort_by_data = '';
        // let changeUpperCase = false;

        // if (date_sort.value) {
        //   sort_by_data = 'date';
        //   sort_type = date_sort.type;
        // } else if (due_date_sort.value) {
        //   sort_by_data = 'raw_due_date';
        //   sort_type = due_date_sort.type;
        // } else if (order_number_sort.value) {
        //   sort_by_data = 'raw_po_number';
        //   sort_type = order_number_sort.type;
        //   changeUpperCase = true;
        // } else if (invoice_number_sort.value) {
        //   sort_by_data = 'number';
        //   sort_type = invoice_number_sort.type;
        //   changeUpperCase = true;
        // } else if (invoice_status_sort.value) {
        //   sort_by_data = 'invoice_status';
        //   sort_type = invoice_status_sort.type;
        // } else if (supplier_sort.value) {
        //   sort_by_data = 'supplier_name';
        //   sort_type = supplier_sort.type;
        //   changeUpperCase = true;
        // } else if (customer_sort.value) {
        //   sort_by_data = 'customer_name';
        //   sort_type = customer_sort.type;
        //   changeUpperCase = true;
        // } else if (amount_sort.value) {
        //   sort_by_data = 'total_order_amount';
        //   sort_type = amount_sort.type;
        // } else if (sent_to_accounting_sort.value) {
        //   sort_by_data = 'sent_to_accounting';
        //   sort_type = sent_to_accounting_sort.type;
        // } else if (matched_po_sort.value) {
        //   sort_by_data = 'matched_po';
        //   sort_type = matched_po_sort.type;
        // } else {
        //   sort_by_data = 'date';
        //   sort_type = 'desc';
        // }

        setTotalPage(Invoices.meta.filter_count);
        setInvoices(Invoices.data);

        SortInvoices = Invoices.data;

        //eslint-disable-next-line array-callback-return
        SortInvoices.map((value, index) => {
          var supplier_name = "";
          var customer_name = "";
          var totalOrder = 0;
          var matched_po = "";
          if (value.orders) {
            if (value.orders.length > 0) {
              var order_data = value.orders[0].orders_id;

              if (order_data) {
                if (order_data.supplier) {
                  supplier_name = order_data.supplier.name;
                }

                if (order_data.farm_code) {
                  customer_name = order_data.farm_code.name;
                }
              }

              value.orders.map((order_value, order_index) => {
                if (order_value.orders_id) {
                  if (order_value.orders_id.status !== "deleted") {
                    matched_po += order_value.orders_id.order_number;
                    if (order_index < value.orders.length - 1) {
                      matched_po += ";";
                    }
                    var total_order = 0;
                    if (order_value.orders_id.order_items.length > 0) {
                      order_value.orders_id.order_items.map(order_items_value => {
                        if (order_items_value.order_item) {
                          total_order = total_order + order_items_value.order_item.unit_price * order_items_value.order_item.quantity;
                        }
                      });
                    }
                    value.orders[order_index].total_order = total_order;
                    // totalOrder += total_order;
                  }
                }
              });
            }
          }

          if (!supplier_name && value.supplier) {
            supplier_name = value.supplier.name;
          }
          if (!customer_name && value.farm_code) {
            customer_name = value.farm_code.name;
          }

          if (!supplier_name && value.raw_supplier) {
            supplier_name = value.raw_supplier;
          }
          if (!customer_name && value.raw_customer) {
            customer_name = value.raw_customer;
          }

          SortInvoices[index].supplier_name = supplier_name;
          SortInvoices[index].customer_name = customer_name;
          // SortInvoices[index].total_order_amount = totalOrder > 0 ? totalOrder : SortInvoices[index].total_amount;
          SortInvoices[index].total_order_amount = SortInvoices[index].total_amount;
          SortInvoices[index].matched_po = matched_po;
        });

        SortInvoices = linq
          .from(SortInvoices)
          .orderBy(o => o.key)
          .toArray();

        // if (sort_by_data) {
        //   if (sort_type === "desc") {
        //     SortInvoices.sort(function (a, b) {
        //       let nameA = a[sort_by_data] ? a[sort_by_data] : '';
        //       let nameB = b[sort_by_data] ? b[sort_by_data] : '';
        //       if (changeUpperCase) {
        //         nameA = nameA ? nameA.toUpperCase() : nameA; // ignore upper and lowercase
        //         nameB = nameB ? nameB.toUpperCase() : nameB; // ignore upper and lowercase
        //       }

        //       if (nameA > nameB) { return -1; }
        //       if (nameA < nameB) { return 1; }
        //       return 0; // names must be equal
        //     });
        //   } else {
        //     SortInvoices.sort(function (a, b) {
        //       let nameA = a[sort_by_data] ? a[sort_by_data] : '';
        //       let nameB = b[sort_by_data] ? b[sort_by_data] : '';
        //       if (changeUpperCase) {
        //         nameA = nameA ? nameA.toUpperCase() : nameA; // ignore upper and lowercase
        //         nameB = nameB ? nameB.toUpperCase() : nameB; // ignore upper and lowercase
        //       }

        //       if (nameA < nameB) { return -1; }
        //       if (nameA > nameB) { return 1; }
        //       return 0; // names must be equal
        //     });
        //   }
        // }

        if (SortInvoices) {
          setInvoices(SortInvoices);
        }
      } else {
        setInvoices([]);
      }

      setSaveFilterData(filter_data);
      setSaveSortData(orderByFieldCustom);
      setInvoiceLoading(false);
      setInvoiceReload(false);
      setSortLoading(false);
      setFilterLoading(false);
      setLoadingPagination(false);
    } catch (e) {
      setInvoiceLoading(false);
      setInvoiceReload(false);
      setSortLoading(false);
      setFilterLoading(false);
      setLoadingPagination(false);
      enqueueSnackbar(ErrorMessage(e), {
        anchorOrigin: { vertical: "top", horizontal: "right" },
        variant: "error",
        autoHideDuration: 3200,
        action: CloseSnackbarAction
      });
    }
  };

  const pageChange = value => {
    setLoadingPagination(true);
    setPage(pageRef.current + value);
    loadInvoices();
  };

  const handleChange = event => {
    setPageNumber(event.target.value);
    setPage(1);
    loadInvoices();
  };

  const exportOrder = async export_data => {
    let dataURL = "";

    var start_date = null;
    var end_date = null;

    var index = filterDataRef.current.date;
    if (index === 2) {
      start_date = filterDataRef.current.date_range[0];
      end_date = filterDataRef.current.date_range[1];
    } else if (index === 3) {
      start_date = moment()
        .startOf("month")
        .format("YYYY-MM-DD 00:00:00");
      end_date = moment()
        .endOf("month")
        .format("YYYY-MM-DD 23:59:59");
    } else if (index === 4) {
      start_date = moment()
        .subtract(1, "months")
        .startOf("month")
        .format("YYYY-MM-DD 00:00:00");
      end_date = moment()
        .subtract(1, "months")
        .endOf("month")
        .format("YYYY-MM-DD 23:59:59");
    } else if (index === 5) {
      start_date = moment()
        .subtract(29, "days")
        .format("YYYY-MM-DD 00:00:00");
      end_date = moment().format("YYYY-MM-DD 23:59:59");
    } else if (index === 6) {
      start_date = moment()
        .subtract(2, "months")
        .startOf("month")
        .format("YYYY-MM-DD 00:00:00");
      end_date = moment()
        .endOf("month")
        .format("YYYY-MM-DD 23:59:59");
    }

    //order status filter
    var order_index = filterDataRef.current.order;
    var status = null;
    if (order_index[0] !== "all") {
      status = order_index;
    }

    //invoice status filter
    var invoice_index = filterDataRef.current.invoice;
    var invoice_status = null;
    if (invoice_index[0] !== "all") {
      invoice_status = invoice_index;
    }

    //customer filter
    var customer_index = filterDataRef.current.customer;
    var customer = null;

    // if (!AdminPermission()) {
    //   customer = customerEmployeeRef.current;
    // }
    if (customer_index[0] !== "0") {
      customer = customer_index;
    }

    //supplier filter
    var supplier_index = filterDataRef.current.supplier;
    var supplier = null;
    if (supplier_index[0] !== "0") {
      supplier = supplier_index;
    }

    //isMyOrder filter
    // var isMyOrder = filterDataRef.current.isMyOrder;
    var employee = null;
    // var employee_id = await getEmployee();

    // if (isMyOrder) {
    //   employee = employee_id.id;
    // }

    //matched po filter
    var matched_po_index = filterDataRef.current.matched_po;
    var matched_po = null;
    if (matched_po_index) {
      if (matched_po_index[0] !== "all" && matched_po_index.length === 1) {
        matched_po = matched_po_index[0];
      }
    }

    //po category filter
    var po_category_index = filterDataRef.current.po_category;
    var po_category = null;
    if (po_category_index) {
      if (po_category_index[0] !== "0") {
        po_category = po_category_index;
      }
    }

    //------------------ sort -------------- //
    var customer_sort = sortFieldRef.current.customer;
    var date_sort = sortFieldRef.current.date;
    var order_number_sort = sortFieldRef.current.order_number;
    var invoice_number_sort = sortFieldRef.current.invoice_number;
    var invoice_status_sort = sortFieldRef.current.invoice_status;
    var supplier_sort = sortFieldRef.current.supplier;
    var due_date_sort = sortFieldRef.current.due_date;
    var amount_sort = sortFieldRef.current.amount;
    var sent_to_accounting_sort = sortFieldRef.current.sent_to_accounting;
    var matched_po_sort = sortFieldRef.current.matched_po;

    let sort_type = "";
    let sort_by_data = "";

    if (date_sort.value) {
      sort_by_data = "date";
      sort_type = date_sort.type;
    } else if (due_date_sort.value) {
      sort_by_data = "raw_due_date";
      sort_type = due_date_sort.type;
    } else if (order_number_sort.value) {
      sort_by_data = "raw_po_number";
      sort_type = order_number_sort.type;
    } else if (invoice_number_sort.value) {
      sort_by_data = "number";
      sort_type = invoice_number_sort.type;
    } else if (invoice_status_sort.value) {
      sort_by_data = "invoice_status";
      sort_type = invoice_status_sort.type;
    } else if (supplier_sort.value) {
      sort_by_data = "supplier_name";
      sort_type = supplier_sort.type;
    } else if (customer_sort.value) {
      sort_by_data = "customer_name";
      sort_type = customer_sort.type;
    } else if (amount_sort.value) {
      sort_by_data = "total_order_amount";
      sort_type = amount_sort.type;
    } else if (sent_to_accounting_sort.value) {
      sort_by_data = "sent_to_accounting";
      sort_type = sent_to_accounting_sort.type;
    } else if (matched_po_sort.value) {
      sort_by_data = "matched_po";
      sort_type = matched_po_sort.type;
    } else {
      sort_by_data = "date";
      sort_type = "desc";
    }

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json", Authorization: "Bearer " + directus.api.auth.config.token },
      body: JSON.stringify({
        start_date: start_date,
        end_date: end_date,
        export_id: export_data.id,
        selected_customer: customer,
        selected_supplier: supplier,
        selected_po_category: po_category,
        search_by_text: searchRef.current,
        status: status,
        invoice_status: invoice_status,
        employee: employee,
        matched_po: matched_po,
        custom_sort: { sort_by_data: sort_by_data, sort_type: sort_type },
        isAnyPrice: filterDataRef.current.isAnyPrice,
        total_price: [filterDataRef.current.price.min, filterDataRef.current.price.max]
      })
    };

    dataURL = `${react_app_url}${JSON.parse(window.localStorage.getItem("company"))}/custom/export_invoice/` + export_data.link;

    await fetch(dataURL, requestOptions)
      .then(resp => resp.blob())
      .then(async blob => {
        var file = await blob.text();
        // setExportLoading(false);
        if (file.includes("error")) {
          alert("Connection error. Please try again.");
        } else if (file.includes("no_template")) {
          alert("No Template");
        } else {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.style.display = "none";
          a.href = url;
          // the filename you want
          var file_name = "Invoice-Export";
          if (start_date && end_date) {
            file_name += `-${moment(start_date).format("YYYY-MM-DD")}-${moment(end_date).format("YYYY-MM-DD")}`;
          } else {
            file_name += "-All";
          }
          file_name += ".xlsx";
          a.download = file_name;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);

          // setExportLoading(false);
        }
      });

    EventEmitter.emit("export_loading", { value: false });
  };

  const sortData = val => {
    setSortLoading(true);
    var sort_field_data = {
      order_number: { type: "desc", value: false },
      date: { type: "desc", value: false },
      supplier: { type: "desc", value: false },
      customer: { type: "desc", value: false },
      invoice_number: { type: "desc", value: false },
      invoice_status: { type: "desc", value: false },
      due_date: { type: "desc", value: false },
      amount: { type: "desc", value: false },
      sent_to_accounting: { type: "desc", value: false },
      matched_po: { type: "desc", value: false }
    };

    var sort_data = sortFieldRef.current[val];
    if (sort_data) {
      var value = sort_data.value;
      if (sort_data.value === false) {
        value = true;
      }

      if (value === true) {
        var sort_type = "";
        if (sort_data.type === "desc") {
          sort_type = "asc";
        } else {
          sort_type = "desc";
        }

        sort_field_data[val].value = true;
        sort_field_data[val].type = sort_type;

        var sort_data = localStorage.sort_data;
        if (sort_data) {
          sort_data = JSON.parse(sort_data);
          sort_data.invoices = sort_field_data;
        } else {
          sort_data = { invoices: sort_field_data };
        }
        localStorage.setItem("sort_data", JSON.stringify(sort_data));

        setSortField(sort_field_data);
      }
    }

    loadInvoices();
  };

  const openApprovedDialog = () => {
    EventEmitter.emit("approved_invoice", { value: true });
  };

  const unselectInvoice = () => {
    EventEmitter.emit("unselect_invoice", { value: true });
  };

  const handleSubmission = async (e, order) => {
    // setImportInvoiceLoading(true);
    e.preventDefault();

    const form = document.querySelector("#my-form");
    const data = new FormData(form);
    setSelectedInvoiceFile(data);

    // let updateData = await directus.uploadFiles(data, onUploadProgress);

    // // await directus.createItem('order_files', {
    // //   status: "published",
    // //   order_id: order.id,
    // //   directus_file_id: updateData.data.id
    // // });

    // // loadOrders();
    // setImportInvoiceLoading(false);

    // var message = 'File uploaded successfully';
    // enqueueSnackbar(message, { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'success', autoHideDuration: 3200 });
  };

  function onUploadProgress(progressEvent) {
    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
    console.log(percentCompleted + "% Done");
  }

  const onImportInvoice = () => {
    setOpenImportInvoice(true);
  };

  const closeImportInvoiceDialog = () => {
    setOpenImportInvoice(false);
    setSelectedInvoiceFile(null);
  };

  const importInvoice = async () => {
    setImportInvoiceLoading(true);
    try {
      if (selectedInvoiceFileRef.current) {
        let updateData = await directus.api.post("/custom/emails/upload", selectedInvoiceFileRef.current, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        });

        if (!updateData.error_message) {
          loadInvoices();
          closeImportInvoiceDialog();
        } else {
          enqueueSnackbar(updateData.error_message, {
            anchorOrigin: { vertical: "top", horizontal: "right" },
            variant: "error",
            autoHideDuration: 3200,
            action: CloseSnackbarAction
          });
          setImportInvoiceLoading(false);
        }
      }
    } catch (e) {
      enqueueSnackbar(ErrorMessage(e), {
        anchorOrigin: { vertical: "top", horizontal: "right" },
        variant: "error",
        autoHideDuration: 3200,
        action: CloseSnackbarAction
      });
      setImportInvoiceLoading(false);
    }
    setImportInvoiceLoading(false);
  };

  return (
    <Page className={classes.root} title="Invoices" style={{ paddingTop: "0px" }}>
      <Container maxWidth={false} style={{ padding: "0px" }}>
        <List className={classess.root} subheader={<li />} style={{ overflow: "hidden" }}>
          <div style={{ display: "flex" }}>
            <div style={{ display: "flex", marginBottom: "10px" }}>
              {POApproverPermission() ? (
                <CancelButton
                  style={{ width: "160px", textTransform: "capitalize", marginRight: "10px" }}
                  variant="contained"
                  component="span"
                  onClick={() => onImportInvoice()}
                >
                  <FileUploadIcon />
                  <Typography noWrap style={{ fontWeight: "500", lineHeight: "24px", marginLeft: "5px" }}>
                    Import Invoice
                  </Typography>
                </CancelButton>
              ) : (
                ""
              )}

              {POApproverPermission() || isMultiSelectRef.current ? (
                <div style={{ display: "flex", color: "#263238" }}>
                  {isMultiSelectRef.current ? (
                    <>
                      <ApprovedButton
                        style={{ width: "170px", textTransform: "capitalize", marginRight: "10px" }}
                        variant="contained"
                        component="span"
                        disabled={invoiceReloadRef.current || !invoicesRef.current}
                        onClick={() => openApprovedDialog()}
                      >
                        <Typography noWrap style={{ fontWeight: "500", lineHeight: "24px" }}>
                          {" "}
                          {"Approve Selected"}
                        </Typography>
                      </ApprovedButton>
                      <CancelButton
                        style={{ width: "120px", textTransform: "capitalize", marginRight: "10px" }}
                        variant="contained"
                        component="span"
                        disabled={invoiceReloadRef.current || !invoicesRef.current}
                        onClick={() => unselectInvoice()}
                      >
                        <Typography noWrap style={{ fontWeight: "500", lineHeight: "24px" }}>
                          Unselect All
                        </Typography>
                      </CancelButton>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}
            </div>
            {invoicesRef.current ? (
              invoicesRef.current.length > 0 ? (
                <Grid item xs={12} style={{ textAlign: "right", fontFamily: "SF Pro", marginBottom: "10px" }}>
                  <span style={{ paddingRight: "20px" }}>Rows per page:</span>
                  <span style={{ paddingRight: "20px" }}>
                    <TextField id="standard-select-currency" select value={pageNumber} onChange={handleChange}>
                      {pageOptions.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </span>

                  <span style={{ paddingRight: "20px" }}>
                    {pageNumberRef.current * (pageRef.current - 1) + 1} -{" "}
                    {pageNumberRef.current * pageRef.current > totalPage ? totalPage : pageNumberRef.current * pageRef.current} of {totalPage}
                  </span>

                  <Button
                    variant="outlined"
                    size="small"
                    disabled={pageRef.current === 1 || loadingPagination}
                    className={classes.buttonLeft}
                    onClick={() => pageChange(-1)}
                  >
                    <ArrowBackIosRoundedIcon />
                  </Button>

                  <Button
                    variant="outlined"
                    size="small"
                    className={classes.buttonRight}
                    disabled={pageRef.current >= Math.ceil(totalPage / pageNumberRef.current) || loadingPagination}
                    onClick={() => pageChange(1)}
                  >
                    <ArrowForwardIosRoundedIcon />
                  </Button>
                </Grid>
              ) : (
                ""
              )
            ) : (
              " "
            )}
          </div>

          <ListSubheader style={{ paddingLeft: "0px", paddingRight: "0px" }}>
            <div style={{ display: "flex" }}>
              {isMultiSelectRef.current ?
                <div style={{ marginTop: "4px", minWidth: "45px", maxWidth: "45px" }}></div> : ""}
              <Paper elevation={0} className={classes.tablehead} style={{ width: "100%" }}>
                <Grid container spacing={0} justifyContent="space-around"
                      style={{ paddingLeft: "22px", paddingRight: "6px" }}>
                  <Grid style={{ width: "2%" }}></Grid>
                  <Grid style={{ width: "8%" }}>
                    <div className={classes.headerSort} onClick={() => sortData("date")}>
                      <Typography noWrap component="div">
                        <b>
                          <OverflowTip>Date</OverflowTip>
                        </b>
                      </Typography>
                      <div style={{ position: "relative" }}>
                        {sortFieldRef.current["date"].value === true ? (
                          sortFieldRef.current["date"].type === "asc" ? (
                            <SortRoundedIcon className={classes.ascSort} />
                          ) : (
                            <SortRoundedIcon className={classes.descSort} />
                          )
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </Grid>
                  <Grid style={{ width: "11%" }}>
                    <div className={classes.headerSort} onClick={() => sortData("supplier")}>
                      <Typography noWrap component="div">
                        <b>
                          <OverflowTip>Supplier</OverflowTip>
                        </b>
                      </Typography>
                      <div style={{ position: "relative" }}>
                        {sortFieldRef.current["supplier"].value === true ? (
                          sortFieldRef.current["supplier"].type === "asc" ? (
                            <SortRoundedIcon className={classes.ascSort} />
                          ) : (
                            <SortRoundedIcon className={classes.descSort} />
                          )
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </Grid>
                  <Grid style={{ width: "11%" }}>
                    <div className={classes.headerSort} onClick={() => sortData("customer")}>
                      <Typography noWrap component="div">
                        <b>
                          <OverflowTip>Customer</OverflowTip>
                        </b>
                      </Typography>
                      <div style={{ position: "relative" }}>
                        {sortFieldRef.current["customer"].value === true ? (
                          sortFieldRef.current["customer"].type === "asc" ? (
                            <SortRoundedIcon className={classes.ascSort} />
                          ) : (
                            <SortRoundedIcon className={classes.descSort} />
                          )
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </Grid>
                  <Grid style={{ width: "10%" }}>
                    <div className={classes.headerSort} onClick={() => sortData("order_number")}>
                      <Typography noWrap component="div">
                        <b>
                          <OverflowTip>Order Number</OverflowTip>
                        </b>
                      </Typography>
                      <div style={{ position: "relative" }}>
                        {sortFieldRef.current["order_number"].value === true ? (
                          sortFieldRef.current["order_number"].type === "asc" ? (
                            <SortRoundedIcon className={classes.ascSort} />
                          ) : (
                            <SortRoundedIcon className={classes.descSort} />
                          )
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </Grid>
                  <Grid style={{ width: "10%" }}>
                    <div className={classes.headerSort} onClick={() => sortData("invoice_number")}>
                      <Typography noWrap component="div">
                        <b>
                          <OverflowTip>Invoice Number</OverflowTip>
                        </b>
                      </Typography>
                      <div style={{ position: "relative" }}>
                        {sortFieldRef.current["invoice_number"].value === true ? (
                          sortFieldRef.current["invoice_number"].type === "asc" ? (
                            <SortRoundedIcon className={classes.ascSort} />
                          ) : (
                            <SortRoundedIcon className={classes.descSort} />
                          )
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </Grid>
                  <Grid style={{ width: "9%" }}>
                    <div className={classes.headerSort} onClick={() => sortData("due_date")}>
                      <Typography noWrap component="div">
                        <b>
                          <OverflowTip>Due Date</OverflowTip>
                        </b>
                      </Typography>
                      <div style={{ position: "relative" }}>
                        {sortFieldRef.current["due_date"].value === true ? (
                          sortFieldRef.current["due_date"].type === "asc" ? (
                            <SortRoundedIcon className={classes.ascSort} />
                          ) : (
                            <SortRoundedIcon className={classes.descSort} />
                          )
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </Grid>
                  <Grid style={{ width: "12%" }}>
                    <div className={classes.headerSort} onClick={() => sortData("matched_po")}>
                      <Typography noWrap component="div">
                        <b>
                          <OverflowTip>Matched PO</OverflowTip>
                        </b>
                      </Typography>
                      <div style={{ position: "relative" }}>
                        {sortFieldRef.current["matched_po"].value === true ? (
                          sortFieldRef.current["matched_po"].type === "asc" ? (
                            <SortRoundedIcon className={classes.ascSort} />
                          ) : (
                            <SortRoundedIcon className={classes.descSort} />
                          )
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </Grid>
                  <Grid style={{ width: "12%" }}>
                    <div style={{ display: "flex" }}>
                      <div style={{ width: "75%" }}>
                        <div className={classes.headerSort} onClick={() => sortData("invoice_status")}>
                          <Typography noWrap component="div">
                            <b>
                              <OverflowTip>Invoice Status</OverflowTip>
                            </b>
                          </Typography>
                          <div style={{ position: "relative" }}>
                            {sortFieldRef.current["invoice_status"].value === true ? (
                              sortFieldRef.current["invoice_status"].type === "asc" ? (
                                <SortRoundedIcon className={classes.ascSort} />
                              ) : (
                                <SortRoundedIcon className={classes.descSort} />
                              )
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                      <div style={{ width: "25%", height: "20px" }}>
                        <div className={classes.headerSort} onClick={() => sortData("sent_to_accounting")}>
                          <CustomTooltip arrow title={<Typography variant="h6">Sent to Accounting</Typography>}>
                            <MarkEmailReadOutlinedIcon
                              style={{ fontSize: "20px", verticalAlign: "top", marginTop: "2px" }} />
                          </CustomTooltip>
                          <div style={{ position: "relative", marginTop: "1px" }}>
                            {sortFieldRef.current["sent_to_accounting"].value === true ? (
                              sortFieldRef.current["sent_to_accounting"].type === "asc" ? (
                                <SortRoundedIcon className={classes.ascSort} style={{ marginLeft: "0px" }} />
                              ) : (
                                <SortRoundedIcon className={classes.descSort} style={{ marginLeft: "0px" }} />
                              )
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Grid>
                  <Grid style={{ width: "14%" }}>
                    <div className={classes.headerSort} onClick={() => sortData("amount")}
                         style={{ justifyContent: "center" }}>
                      <Typography align="center" noWrap component="div">
                        <b>
                          <OverflowTip>Amount</OverflowTip>
                        </b>
                      </Typography>
                      <div style={{ position: "relative" }}>
                        {sortFieldRef.current["amount"].value === true ? (
                          sortFieldRef.current["amount"].type === "asc" ? (
                            <SortRoundedIcon className={classes.ascSort} />
                          ) : (
                            <SortRoundedIcon className={classes.descSort} />
                          )
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </Grid>
                </Grid>

                {sortLoadingRef.current ? (
                  <Grid item xs={12}>
                    <div className={classes.linearProgress}
                         style={{ marginTop: "7px", marginLeft: "7px", marginRight: "7px" }}>
                      <LinearProgress style={{ height: "2px" }} />
                    </div>
                  </Grid>
                ) : (
                  ""
                )}
              </Paper>
            </div>
          </ListSubheader>

          {invoiceReloadRef.current ? (
            <CircularProgress className={classes.circular} />
          ) : invoicesRef.current ? (
            invoicesRef.current.length > 0 ? (
              <div className={classes.scrollsetting} style={{ position: "relative" }}>
                <div>
                  <InvoiceList
                    loadInvoices={loadInvoices}
                    invoiceData={invoicesRef.current}
                    page={pageRef.current}
                    pageNumber={pageNumberRef.current}
                    saveFilterData={saveFilterDataRef.current}
                    saveSortData={saveSortDataRef.current}
                    totalPage={totalPage}
                  />
                </div>
                {sortLoadingRef.current || loadingPagination || filterLoadingRef.current ? (
                  <div
                    style={{
                      position: "absolute",
                      zIndex: "999",
                      top: 0,
                      height: "calc(100% - 10px)",
                      width: "100%",
                      background: "#6e6e6e05",
                      borderRadius: "10px"
                    }}
                  ></div>
                ) : (
                  ""
                )}
              </div>
            ) : !invoiceLoading ? (
              <Grid container direction="column" justifyContent="center" alignItems="center"
                    style={{ opacity: "0.2", paddingTop: "6pc" }}>
                <Grid item xs={12} sm={12} lg={12}>
                  <img alt="No Data Found" height="230" width="230" src="/static/images/folder.png?v=0.0.1" />
                  <Typography variant="h3" style={{ textAlign: "end" }}>
                    No Data Found
                  </Typography>
                </Grid>
              </Grid>
            ) : (
              ""
            )
          ) : (
            <CircularProgress className={classes.circular} />
          )}
          {/* </li> */}
        </List>

        {invoicesRef.current ? (
          invoicesRef.current.length > 0 ? (
            <Grid item xs={12} style={{ textAlign: "right", fontFamily: "SF Pro", marginRight: "28px" }}>
              <span style={{ paddingRight: "20px" }}>Rows per page:</span>
              <span style={{ paddingRight: "20px" }}>
                <TextField id="standard-select-currency" select value={pageNumber} onChange={handleChange}>
                  {pageOptions.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </span>

              <span style={{ paddingRight: "20px" }}>
                {pageNumberRef.current * (pageRef.current - 1) + 1} -{" "}
                {pageNumberRef.current * pageRef.current > totalPage ? totalPage : pageNumberRef.current * pageRef.current} of {totalPage}
              </span>

              <Button
                variant="outlined"
                size="small"
                disabled={pageRef.current === 1 || loadingPagination}
                className={classes.buttonLeft}
                onClick={() => pageChange(-1)}
              >
                <ArrowBackIosRoundedIcon />
              </Button>

              <Button
                variant="outlined"
                size="small"
                className={classes.buttonRight}
                disabled={pageRef.current >= Math.ceil(totalPage / pageNumberRef.current) || loadingPagination}
                onClick={() => pageChange(1)}
              >
                <ArrowForwardIosRoundedIcon />
              </Button>
            </Grid>
          ) : (
            ""
          )
        ) : (
          " "
        )}

        <Dialog open={openImportInvoiceRef.current}>
          <DialogTitle disableTypography={true} id="form-dialog-title">
            <Typography component="div" variant="h6">
              <h2>Import Invoice</h2>
            </Typography>
          </DialogTitle>
          <DialogContent>
            <form id="my-form" className={classes.form} style={{ width: "400px", padding: "10px 0" }}>
              <input
                type="file"
                name="file"
                accept=".pdf, .doc, .docx, .xls, .xlsx, .png, .jpg, .jpeg, .img"
                onChange={e => handleSubmission(e)}
                id="contained-button-file"
                className={classes.input}
              />
            </form>
          </DialogContent>

          <DialogActions style={{ height: "60px", marginRight: "5px" }}>
            {importInvoiceLoadingRef.current ? (
              <CircularProgress className={classes.circular} />
            ) : (
              <div>
                <CancelButton2 id="cancel_btn" size="small" variant="contained" onClick={closeImportInvoiceDialog}>
                  Cancel
                </CancelButton2>
                <SubmitButton2 style={{ marginLeft: "5px" }} id="save_btn" size="small" variant="contained"
                               onClick={() => importInvoice()}>
                  Import
                </SubmitButton2>
              </div>
            )}
          </DialogActions>
        </Dialog>
      </Container>
    </Page>
  );
};

export default IndexView;
