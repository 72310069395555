import React, { useEffect, useState } from 'react';
import directus from "../../services/directus";
import useStateRef from "react-usestateref";
import Enumerable from "linq";
import moment from "moment";
import GooglePlacesAutocomplete, { geocodeByAddress } from 'react-google-places-autocomplete';
import { generateDeliveryOption } from './ReusableFunction';
import LinearProgress from '@material-ui/core/LinearProgress';
import EventEmitter from "src/utils/EventEmitter";
import { v4 as uuidv4 } from "uuid";
import NumberFormat from 'react-number-format';
import { getCache, setCache } from 'src/utils/KeyValueCache';
import { useSnackbar } from 'notistack';
import { ThemeProvider } from '@material-ui/core/styles';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { getEmployee } from 'src/utils/sessions';
import { OverflowTip } from "src/components/OverflowTip";
import { theme,
  useStyles,
  SubmitButton,
  CancelButton,
  AddItemButton,
  CustomButton,
  CustomButton2,
  StyledMenu,
  NumResultsHeader,
  NumResultsHeaderDescription,
  NumResultsHeaderItemDescription,
  filterOption
} from './style.js';
import { AdminPermission, InvoiceAdminPermission, POApproverPermission, POCreatorPermission } from 'src/utils/Permission';
import { IconButton, Divider, Box, InputAdornment, Autocomplete } from "@mui/material";
import StarBorderRoundedIcon from '@material-ui/icons/StarBorderRounded';
import StarRoundedIcon from '@material-ui/icons/StarRounded';
import { createFilterOptions } from '@mui/material/Autocomplete';
import ErrorMessage from 'src/components/ErrorMessage';
import { CloseSnackbarAction } from 'src/components/CloseSnackbarAction';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import {
  Grid,
  Typography,
  MenuItem,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  CircularProgress,
  Chip
} from "@material-ui/core";

const filterOptions = createFilterOptions({
  matchFrom: 'start',
  stringify: (option) => option.name.toLowerCase(),
});

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        });
      }}
      thousandSeparator
      isNumericString
      prefix="$"
    />
  );
}

const AddOrder = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [initialized, setInitialized] = useState(false);
  const [showTotalDetails, setShowTotalDetails] = useState(false);
  const [showPOAccount, setShowPOAccount] = useState(false);
  const [showPOInternalCategory, setShowPOInternalCategory] = useState(false);
  const [isClickFavourite, setIsClickFavourite, isClickFavouriteRef] = useStateRef(false);
  const [deliveryAddress, setDeliveryAddress] = useState(null);
  const [deliveryAddressOptionId, setDeliveryAddressOptionId, deliveryAddressOptionIdRef] = useStateRef(0);
  const [deliveryAddressOptionSelected, setDeliveryAddressOptionSelected] = useState([]);
  const [deliveryAddressOptions, setDeliveryAddressOptions] = useState([{ id: -1, address_name: 'Custom', lable: 'Custom' }]);
  const [totalOrderItem, setTotalOrderItem, totalOrderItemRef] = useStateRef(0);
  const [subTotal, setSubTotal, subTotalRef] = useStateRef(0);
  const [totalTax, setTotalTax, totalTaxRef] = useStateRef(0);
  const [selectedDeliveryDate, handleDeliveryDateChange] = useState(new Date());
  const [deliveryInstructions, setDeliveryInstructions] = useState(null);
  const [email, setEmail] = useState('');
  const [notes, setNotes] = useState(null);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [list, setList, listRef] = useStateRef('');
  const [dataCustomEmail, setDataCustomEmail, dataCustomEmailRef] = useStateRef({ send_to_email: null, cc_email: null });
  const [customEmailLoading, setCustomEmailLoading] = useState(false);
  const [isCustomEmail, setIsCustomEmail, isCustomEmailRef] = useStateRef(false);
  const [uploadFiles, setUploadFiles, uploadFilesRef] = useStateRef([]);
  const [anchorElOrdersType, setAnchorElOrdersType] = React.useState(null);
  const openActions = Boolean(anchorElOrdersType);
  
  const [selectedSupplier, setSelectedSupplier, selectedSupplierRef] = useStateRef(null);
  const [supplierOptions, setSupplierOptions, supplierOptionsRef] = useStateRef([]);
  const [supplierOptions2, setSupplierOptions2, supplierOptionsRef2] = useStateRef([]);
  const [supplierLoading, setSupplierLoading] = useState(false);
  
  const [selectedCustomer, setSelectedCustomer, selectedCustomerRef] = useStateRef(null);
  const [customerOptions, setCustomerOptions, customerOptionsRef] = useStateRef([]);
  const [customerLoading, setCustomerLoading] = useState(false);
  
  const [collection, setCollection, collectionRef] = useStateRef([]);
  const [collectionName, setCollectionName, collectionNameRef] = useStateRef([]);
  const [collectionData, setCollectionData, collectionDataRef] = useStateRef([]);
  const [categoryTypeLoading, setCategoryTypeLoading] = useState(false);
  const [updateCategory, setUpdateCategory] = useState('category');
  const [openUpdateCategoryDialog, setOpenUpdateCategoryDialog] = useState(false);
  const [selectedCategoryType, setSelectedCategoryType] = useState(null);
  const [categoryType, setCategoryType, categoryTypeRef] = useStateRef([]);

  const [categoryDescription, setCategoryDescription, categoryDescriptionRef] = useStateRef(null);
  const [isUpdateCategoryDescription, setIsUpdateCategoryDescription, isUpdateCategoryDescriptionRef] = useStateRef(false);
  
  const [items, setItems, itemsRef] = useStateRef([{id: uuidv4(), statusValue: 1}]);
  const [itemCheck, setItemCheck] = useState(true);
  
  const [inputAddressValue, setInputAddressValue, inputAddressValueRef] = useStateRef('');
  const [selectedDeliveryAddress, setSelectedDeliveryAddress, selectedDeliveryAddressRef] = useStateRef({
    AddressLine1: '',
    AddressLine2: '',
    AddressName: '',
    AddressCity: '',
    AddressState: '',
    AddressPostcode: '',
  });

  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
      loadSupplier();
      loadCustomer();
      loadCategoryType();
      addItemsCheck();
      getConfigurationSettings();
    }
  }, []);

  const loadSupplier = async () => {
    setSupplierLoading(true);
    try {
      var supplierCache = getCache('suppliers');
      var Suppliers = { data: [] };

      if (!supplierCache) {
        var Suppliers = await directus.getItems('suppliers', {
          fields: '*',
          sort: 'name',
          limit: -1,
          filter: {
            status: 'published'
          }
        });
        
        setCache('suppliers', Suppliers.data);
      } else {
        Suppliers = { data: supplierCache };
      }

      var suppliers_data = Suppliers.data;
      var fav_suppliers = [];

      if (localStorage.directus_employee) {
        var directus_employee = JSON.parse(localStorage.directus_employee);
        if (directus_employee.favourites_suppliers_po) {
          fav_suppliers = directus_employee.favourites_suppliers_po;
        }
      }

      suppliers_data.map((value, index) => {
        if (fav_suppliers.indexOf(value.id) >= 0) {
          suppliers_data[index].isFavourite = true;
        } else {
          suppliers_data[index].isFavourite = false;
        }
      });

      suppliers_data = Enumerable.from(suppliers_data)
        .orderBy(item => item.name)
        .orderByDescending(item => item.isFavourite)
        .toArray();

      setSupplierOptions(suppliers_data);
      setSupplierOptions2(suppliers_data);
    } catch (e) {
      enqueueSnackbar(ErrorMessage(e), {
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        variant: 'error',
        autoHideDuration: 3200,
        action: CloseSnackbarAction
      });
    }
    setSupplierLoading(false);
  };

  const loadCustomer = async () => {
    setCustomerLoading(true);
    try {
      var customer_filter = { status: 'published' };
      var Customers = null;

      var fields = `id, code, email, name, selectable, access_level, accounting_system, legal_name, 
      suppliers.id, suppliers.status, suppliers.supplier.*, delivery_address_line_1, delivery_address_line_2, delivery_city, delivery_state, delivery_post_code, additional_address.*`;

      var customerCache = getCache('customers');
      if (!customerCache) {
        Customers = await directus.getItems('customers', {
          fields: fields,
          sort: 'name',
          filter: customer_filter
        });

        setCache('customers', Customers.data);
      } else {
        Customers = { data: customerCache };
      }

      Customers = Customers.data;
      var customers_data = Customers;
      var fav_customers = [];

      if (localStorage.directus_employee) {
        var directus_employee = JSON.parse(localStorage.directus_employee);
        if (directus_employee.favourites_customers_po) {
          fav_customers = directus_employee.favourites_customers_po;
        }
      }

      customers_data.map((value, index) => {
        if (fav_customers.indexOf(value.id) >= 0) {
          customers_data[index].isFavourite = true;
        } else {
          customers_data[index].isFavourite = false;
        }
      });

      customers_data = Enumerable.from(customers_data)
        .orderBy(item => item.name)
        .orderByDescending(item => item.isFavourite)
        .toArray();

      setCustomerOptions(customers_data);
    } catch (e) {
      enqueueSnackbar(ErrorMessage(e), {
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        variant: 'error',
        autoHideDuration: 3200,
        action: CloseSnackbarAction
      });
    }
    setCustomerLoading(false);
  };

  const loadCategoryType = async () => {
    setCategoryTypeLoading(true);
    var categories_data = getCache("order_category_type");
    if (!categories_data) {
      var Category = await directus.getItems("order_category_type", {
        fields: "*",
        sort: "name"
      });

      setCache("order_category_type", Category.data);
      categories_data = Category.data;
    }

    var fav_categories = [];

    if (localStorage.directus_employee) {
      var directus_employee = JSON.parse(localStorage.directus_employee);
      if (directus_employee.favourites_categories_po) {
        fav_categories = directus_employee.favourites_categories_po;
      }
    }

    categories_data.map((value, index) => {
      if (fav_categories.indexOf(value.id) >= 0) {
        categories_data[index].isFavourite = true;
      } else {
        categories_data[index].isFavourite = false;
      }
    });

    categories_data = Enumerable.from(categories_data)
      .orderBy(item => item.name)
      .orderByDescending(item => item.isFavourite)
      .toArray();

    setCategoryType(categories_data);
    loadCollections(categories_data);
  };

  const getConfigurationSettings = async () => {
    var result = await directus.getItems('configuration');
    if (result.data.length > 0) {
      if (result.data[0]) {
        if (result.data[0].show_purchase_orders_total_details === true) {
          setShowTotalDetails(true);
        }

        if (result.data[0].show_po_account === true) {
          setShowPOAccount(true);
        }

        if (result.data[0].show_po_internal_category === true) {
          setShowPOInternalCategory(true);
        }
      }
    }
  }

  const loadCollections = async data => {
    if (data.length > 0) {
      var count = 0;
      var new_collection = [];
      const promises = await data.map(async value => {
        if (value.collection) {
          var custom_filter = value.custom_filter;
          if (!collectionNameRef.current.includes(value.collection)) {
            setCollectionName([...collectionNameRef.current.concat(value.collection)]);

            if (custom_filter) {
              if (!custom_filter["status"]) {
                custom_filter["status"] = { nin: "deleted" };
              }
            } else {
              custom_filter = { status: { nin: "deleted" } };
            }

            var params = {
              fields: "*",
              filter: custom_filter,
              sort: "name",
              meta: "filter_count",
              limit: -1
            };

            var cache_key = `${value.collection}_${JSON.stringify(params).hashCode()}`;
            var cache_data = getCache(cache_key);
            if (!cache_data) {
              var result = await directus.getItems(value.collection, params);
              setCollectionData([...collectionDataRef.current.concat({
                data: result.data,
                filter_count: result.meta.filter_count
              })]);
              setCache(cache_key, result.data);
            } else {
              setCollectionData([...collectionDataRef.current.concat({
                data: cache_data,
                filter_count: cache_data.length
              })]);
            }

            new_collection.push({ settings: value, collection_index: count });
            count++;
          } else {
            var collection_index = collectionNameRef.current.findIndex(obj => obj == value.collection);
            new_collection.push({
              settings: value,
              collection_index: collection_index !== -1 ? collection_index : null
            });
          }
        } else {
          new_collection.push({ settings: value, collection_index: null });
        }
      });
      await Promise.all(promises);
      setCollection(new_collection);
    }

    setCategoryTypeLoading(false);
  };

  const formatNumber = num => {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  };

  const handleChange = e => {
    const { name, value } = e.target;

    if (name === 'email') {
      setEmail(value);
    } else if (name === 'notes') {
      setNotes(value);
    } else if (name === 'delivery_instructions') {
      setDeliveryInstructions(value);
    } else if (name === 'delivery_address') {
      setDeliveryAddress(value);
    }
  };

  const clickSupplierFavorite = (optionId, status) => {
    setIsClickFavourite(true);
    let supplierOptionsData = supplierOptions.map((data, index) => {
      if (data.id == optionId) {
        data.isFavourite = !status;
      }
      return data;
    })
    
    const updateSupplierOption = Enumerable.from(supplierOptionsData)
      .orderBy(item => item.name)
      .orderByDescending(item => item.isFavourite)
      .toArray();
    
    setSupplierOptions(updateSupplierOption);
  };
  
  const resetSupplier = async e => {
    try {
      let suppliersOptionData = supplierOptionsRef2.current;
      suppliersOptionData = Enumerable.from(suppliersOptionData)
        .orderBy(item => item.name)
        .orderByDescending(item => item.isFavourite)
        .toArray();
      setSupplierOptions(suppliersOptionData);

      if (isClickFavouriteRef.current) {
        var fav_suppliers_data = Enumerable.from(supplierOptionsRef.current)
          .where(w => w.isFavourite === true)
          .select(data => data.id)
          .toArray();

        var fav_suppliers = [];
        var directus_employee = null;
        if (localStorage.directus_employee) {
          directus_employee = JSON.parse(localStorage.directus_employee);
          if (directus_employee.favourites_suppliers_po) {
            fav_suppliers = directus_employee.favourites_suppliers_po;
          }
        }

        var isUpdateFavourite = false;
        if (fav_suppliers.length === 0 && fav_suppliers_data.length === 0) {
          isUpdateFavourite = true;
        } else if (fav_suppliers.length !== fav_suppliers_data.length) {
          isUpdateFavourite = true;
        } else {
          isUpdateFavourite = true;
          fav_suppliers_data.map(value => {
            if (fav_suppliers.indexOf(value) >= 0) {
              isUpdateFavourite = false;
            }
          });

          if (!isUpdateFavourite) {
            isUpdateFavourite = true;
            fav_suppliers.map(value => {
              if (fav_suppliers_data.indexOf(value) >= 0) {
                isUpdateFavourite = false;
              }
            });
          }
        }

        if (isUpdateFavourite) {
          directus_employee.favourites_suppliers_po = fav_suppliers_data;
          localStorage.directus_employee = JSON.stringify(directus_employee);
          await directus.updateItem('employees', directus_employee.id, { favourites_suppliers_po: fav_suppliers_data });
        }
      }
    } catch (e) {
      enqueueSnackbar(ErrorMessage(e), {
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        variant: 'error',
        autoHideDuration: 3200,
        action: CloseSnackbarAction
      });
    }
    setIsClickFavourite(false);
  };

  const openSupplierPopup = () => {
    if (supplierOptionsRef.current && selectedCustomerRef.current) {
      if (supplierOptionsRef.current.length > 0 && selectedCustomerRef.current.suppliers) {
        if (selectedCustomerRef.current.suppliers.length > 0) {
          var selected_supplier = Enumerable.from(selectedCustomerRef.current.suppliers)
            .select(x => x.supplier.id).toArray();

          var new_selected_supplier = Enumerable.from(supplierOptionsRef.current)
            .where(x => selected_supplier.includes(x.id))
            .toArray();

          setSupplierOptions(new_selected_supplier);
        }
      }
    }
  }

  function onUploadProgress(progressEvent) {
    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
    console.log(percentCompleted + '% Done');
  }

  const editSubmit = async (id, type, send_supplier = false, custom_send = false) => {
    var directusUser = JSON.parse(window.localStorage.getItem('directus_employee'));
    const date = moment().format('YYYY-MM-DD HH:mm:ss');
    setLoadingSubmit(true);

    var orderResult = null;

    var customParams = { send_supplier: send_supplier, total_order: totalOrderItemRef.current };

    if (custom_send) {
      customParams["custom_send"] = custom_send;
    }

    orderResult = await directus.api.post(
      `/items/orders`,
      {
        employee: directusUser.id,
        status: type,
        order_date: date,
        delivery_instructions: deliveryInstructions,
        delivery_address_name: selectedDeliveryAddressRef.current ? selectedDeliveryAddressRef.current.AddressName : '',
        delivery_address_line_1: selectedDeliveryAddressRef.current ? selectedDeliveryAddressRef.current.AddressLine1 : '',
        delivery_address_line_2: selectedDeliveryAddressRef.current ? selectedDeliveryAddressRef.current.AddressLine2 : '',
        delivery_address_city: selectedDeliveryAddressRef.current ? selectedDeliveryAddressRef.current.AddressCity : '',
        delivery_address_state: selectedDeliveryAddressRef.current ? selectedDeliveryAddressRef.current.AddressState : '',
        delivery_address_postcode: selectedDeliveryAddressRef.current ? selectedDeliveryAddressRef.current.AddressPostcode : '',
        delivery_address_option: deliveryAddressOptionIdRef.current,
        supplier: selectedSupplierRef.current ? selectedSupplierRef.current.id : null,
        farm_code: showPOAccount ? selectedCustomerRef.current ? selectedCustomerRef.current.id : null : null,
        category_type: showPOInternalCategory ? selectedCategoryType ? selectedCategoryType.id : null : null,
        category_description: showPOInternalCategory ? categoryDescription ? (categoryDescription.id ? categoryDescription.id : categoryDescription) : categoryDescription : null,
        due_date: selectedDeliveryDate ? moment(new Date(selectedDeliveryDate)).format('YYYY-MM-DD') : null,
        notes: notes,
        send_to_email: dataCustomEmailRef.current.send_to_email,
        cc_email: dataCustomEmailRef.current.cc_email,
      },
      customParams
    );

    //--------------------------------------------------------------------------------------------------------
    let addItems = [];
    let editItems = [];
    let delItems = [];
    let delItems2 = [];

    //eslint-disable-next-line array-callback-return
    itemsRef.current.map((data, index) => {
      if (data.statusValue === 3) {
        delItems = [...delItems, data.orders_items_id];
        delItems2 = [...delItems2, data.id];
      }
      if (data.statusValue === 2) {
        editItems = [
          ...editItems,
          {
            id: data.id,
            description: data.description,
            quantity: data.quantity,
            unit_price: data.unit_price,
            cc_email: data.cc_email,
            note: data.note,
            category_type: data.category_type ? data.category_type.id : null,
            category_description: data.category_description
              ? data.category_description.id
                ? data.category_description.id
                : data.category_description
              : data.category_description,
            sort: index,
            supplier_code: data.supplier_code
          }
        ];
      }
      if (data.statusValue === 1 && data.description) {
        addItems = [
          ...addItems,
          {
            id: uuidv4(),
            order: { id: orderResult.data.id },
            order_item: {
              id: data.id,
              description: data.description,
              quantity: data.quantity,
              unit_price: data.unit_price,
              category_type: data.category_type ? data.category_type.id : null,
              category_description: data.category_description
                ? data.category_description.id
                  ? data.category_description.id
                  : data.category_description
                : data.category_description,
              sort: index,
              supplier_code: data.supplier_code
            }
          }
        ];
      }
    });

    if (delItems.length > 0) {
      await directus.deleteItems('orders_items', delItems);
      await directus.deleteItems('order_items', delItems2);
    }

    if (editItems.length > 0) {
      await directus.updateItems('order_items', editItems);
    }

    if (addItems.length > 0) {
      var addItemsBody = addItems;

      for (let index = 0; index < addItemsBody.length; index++) {
        var addItem = addItemsBody[index];
        delete addItem.id;
        delete addItem.order_item.id;

        var orderItemResult = await directus.createItems('order_items', addItem.order_item);
        addItem.order_item = { id: orderItemResult.data.id };

        addItemsBody[index] = addItem;
      }

      await directus.createItems('orders_items', addItemsBody);
    }

    if (uploadFilesRef.current.length > 0) {
      const promises = uploadFilesRef.current.map(async value => {
        if (value.formData) {
          let updateData = await directus.uploadFiles(value.formData, onUploadProgress);

          await directus.createItem('order_files', {
            status: 'published',
            order_id: orderResult.data.id,
            directus_file_id: updateData.data.id
          });
        }
      });

      await Promise.all(promises);
    }

    setLoadingSubmit(false);

    EventEmitter.emit('setIsEdit', { value: false });
    var message = type === 'draft' ? 'Order has been saved as a draft' : 'Order has been sent';

    if (orderResult.data) {
      if (orderResult.data.status && orderResult.data.status == 'awaiting_approval') {
        message = 'Order has been updated to Awaiting Approval';
      }
    }
    
    enqueueSnackbar(message, { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'success', autoHideDuration: 3200, action: CloseSnackbarAction });

    if (custom_send) {
      setCustomEmailLoading(false);
      closeCustomEmail();
    }
    handleCloseActions();
    EventEmitter.emit('load_all_orders', { value: true });
  };

  const closeCustomEmail = () => {
    setIsCustomEmail(false);
    setDataCustomEmail({ send_to_email: null, cc_email: null });
  };

  const checkEnableClick = (option) => {
    var enable_click = false;
    if (option.selectable) {
      enable_click = true;
    }

    if (option.access_level) {
      if (option.access_level === 'po_creator') {
        if (!POCreatorPermission()) {
          enable_click = false;
        }
      } else if (option.access_level === 'po_approver') {
        if (!POApproverPermission()) {
          enable_click = false;
        }
      } else if (option.access_level === 'invoice_admin') {
        if (!InvoiceAdminPermission()) {
          enable_click = false;
        }
      } else if (option.access_level === 'invoice_admin') {
        if (!AdminPermission()) {
          enable_click = false;
        }
      }
    }

    if (AdminPermission()) {
      enable_click = true;
    }

    return enable_click;
  }

  const resetCustomer = async e => {
    try {
      if (isClickFavouriteRef.current) {
        var fav_customers_data = Enumerable.from(customerOptionsRef.current)
          .where(w => w.isFavourite === true)
          .select(data => data.id)
          .toArray();

        var fav_customers = [];
        var directus_employee = null;
        if (localStorage.directus_employee) {
          directus_employee = JSON.parse(localStorage.directus_employee);
          if (directus_employee.favourites_customers_po) {
            fav_customers = directus_employee.favourites_customers_po;
          }
        }

        var isUpdateFavourite = false;
        if (fav_customers.length === 0 && fav_customers_data.length === 0) {
          isUpdateFavourite = true;
        } else if (fav_customers.length !== fav_customers_data.length) {
          isUpdateFavourite = true;
        } else {
          isUpdateFavourite = true;
          fav_customers_data.map(value => {
            if (fav_customers.indexOf(value) >= 0) {
              isUpdateFavourite = false;
            }
          });

          if (!isUpdateFavourite) {
            isUpdateFavourite = true;
            fav_customers.map(value => {
              if (fav_customers_data.indexOf(value) >= 0) {
                isUpdateFavourite = false;
              }
            });
          }
        }

        if (isUpdateFavourite) {
          directus_employee.favourites_customers_po = fav_customers_data;
          localStorage.directus_employee = JSON.stringify(directus_employee);
          await directus.updateItem('employees', directus_employee.id, { favourites_customers_po: fav_customers_data });
        }
      }
    } catch (e) {
      enqueueSnackbar(ErrorMessage(e), {
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        variant: 'error',
        autoHideDuration: 3200,
        action: CloseSnackbarAction
      });
    }
    setIsClickFavourite(false);
  };

  const clickCustomerFavorite = (option) => {
    var enable_click = false;
    if (option.selectable) {
      enable_click = true;
    }

    if (option.access_level) {
      if (option.access_level === 'po_creator') {
        if (!POCreatorPermission()) {
          enable_click = false;
        }
      } else if (option.access_level === 'po_approver') {
        if (!POApproverPermission()) {
          enable_click = false;
        }
      } else if (option.access_level === 'invoice_admin') {
        if (!InvoiceAdminPermission()) {
          enable_click = false;
        }
      } else if (option.access_level === 'invoice_admin') {
        if (!AdminPermission()) {
          enable_click = false;
        }
      }
    }

    if (AdminPermission()) {
      enable_click = true;
    }

    if (enable_click) {
      setIsClickFavourite(true);
      let customerOptionsData = customerOptionsRef.current.map((data, index) => {
        if (data.id == option.id) {
          data.isFavourite = !option.isFavourite;
        }
        return data;
      })
      
  
      const updateCustomerOption = Enumerable.from(customerOptionsData)
        .orderBy(item => item.name)
        .orderByDescending(item => item.isFavourite)
        .toArray();
      
      setCustomerOptions(updateCustomerOption);
    }
  };

  const changeDeliveryAddressOption = newValue => {
    let newDeliveryAddressOption = generateDeliveryOption(newValue);

    const selectedAddressFromOption = newDeliveryAddressOption.filter(address => address.id === deliveryAddressOptionIdRef.current);

    if (selectedAddressFromOption.length > 0) {
      setDeliveryAddress(selectedAddressFromOption[0]);
      formattingDeliveryAddress(selectedAddressFromOption[0]);
    } else {
      setDeliveryAddress('');
    }

    setDeliveryAddressOptions(newDeliveryAddressOption);
  }

  const formattingDeliveryAddress = (address) => {
    let address_name = address.address_name;
    let address_line_1 = '';
    let address_line_2 = '';
    let address_city = '';
    let address_state = '';
    let address_postcode = '';

    if (address && address.id !== -1) {
      let delivery_address_line_1 = address.address_line_1 ?? '';
      let delivery_address_line_2 =  address.address_line_2 ?? '';
      let delivery_city = address.city;
      let delivery_state = address.state;
      let delivery_post_code = address.post_code;
  
      if (delivery_address_line_1) {
        address_line_1 += delivery_address_line_1;
      }
  
      if (delivery_address_line_2) {
        address_line_2 += delivery_address_line_2;
      }

      if (delivery_city) {
        address_city += delivery_city
      }
      
      if (delivery_state) {
        address_state += delivery_state
      }
      
      if (delivery_post_code) {
        address_postcode += delivery_post_code
      }
    }
    
    setSelectedDeliveryAddress({
      AddressName: address_name,
      AddressLine1: address_line_1,
      AddressLine2: address_line_2,
      AddressCity: address_city,
      AddressState: address_state,
      AddressPostcode: address_postcode,
    })
  }

  const updateAllCategory = () => {
    var new_items = [];

    if (itemsRef.current) {
      if (updateCategory === 'category') {
        itemsRef.current.map(value => {
          value.category_type = selectedCategoryType;
          value.category_description = '';
          if (value.statusValue != 3 && value.statusValue != 1) {
            value.statusValue = 2;
          }
          new_items.push(value);
        });
      } else {
        itemsRef.current.map(value => {
          value.category_description = categoryDescriptionRef.current;
          if (value.statusValue != 3 && value.statusValue != 1) {
            value.statusValue = 2;
          }
          new_items.push(value);
        });
      }
    }

    setItems(new_items);
    countTotal();
    addItemsCheck();
    handleCloseUpdateCategoryDialog();
  };

  const handleCloseUpdateCategoryDialog = () => {
    setOpenUpdateCategoryDialog(false);
  };

  const handleOpenUpdateCategoryDialog = () => {
    setOpenUpdateCategoryDialog(true);
  };

  const changeCategory = newValue => {
    var showWarning = true;
    if (selectedCategoryType && newValue) {
      if (selectedCategoryType.id === newValue.id) {
        showWarning = false;
      }
    }

    setCategoryDescription(null);
    setSelectedCategoryType(newValue);

    if (itemsRef.current) {
      if (itemsRef.current.length > 0) {
        if (showWarning) {
          setUpdateCategory('category');
          handleOpenUpdateCategoryDialog();
        }
      }
    }
  };

  const clickCategoryFavorite = (optionId, status) => {
    setIsClickFavourite(true);
    let categoryOptionsData = categoryTypeRef.current.map((data, index) => {
      if (data.id == optionId) {
        data.isFavourite = !status;
      }
      return data;
    })
    
    const updateCategoryOption = Enumerable.from(categoryOptionsData)
      .orderBy(item => item.name)
      .orderByDescending(item => item.isFavourite)
      .toArray();
    
    setCategoryType(updateCategoryOption);
  };

  const resetCategory = async e => {
    try {
      if (isClickFavouriteRef.current) {
        var fav_category_data = Enumerable.from(categoryTypeRef.current)
          .where(w => w.isFavourite === true)
          .select(data => data.id)
          .toArray();
        
        var fav_categories = [];
        var directus_employee = null;
        if (localStorage.directus_employee) {
          directus_employee = JSON.parse(localStorage.directus_employee);
          if (directus_employee.favourites_categories_po) {
            fav_categories = directus_employee.favourites_categories_po;
          }
        }
        
        var isUpdateFavourite = false;
        if (fav_categories.length === 0 && fav_category_data.length === 0) {
          isUpdateFavourite = true;
        } else if (fav_categories.length !== fav_category_data.length) {
          isUpdateFavourite = true;
        } else {
          isUpdateFavourite = true;
          fav_category_data.map(value => {
            if (fav_categories.indexOf(value) >= 0) {
              isUpdateFavourite = false;
            }
          });

          if (!isUpdateFavourite) {
            isUpdateFavourite = true;
            fav_categories.map(value => {
              if (fav_category_data.indexOf(value) >= 0) {
                isUpdateFavourite = false;
              }
            });
          }
        }

        if (isUpdateFavourite) {
          directus_employee.favourites_categories_po = fav_category_data;
          localStorage.directus_employee = JSON.stringify(directus_employee);
          await directus.updateItem('employees', directus_employee.id, { favourites_categories_po: fav_category_data });
        }
      }
    } catch (e) {
      enqueueSnackbar(ErrorMessage(e), {
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        variant: 'error',
        autoHideDuration: 3200,
        action: CloseSnackbarAction
      });
    }
    setIsClickFavourite(false);
  };

  const changeCategoryDescription = (newValue, isText) => {
    if (!isText) {
      var showWarning = true;
      if (categoryDescriptionRef.current && newValue) {
        if (categoryDescriptionRef.current.id === newValue.id) {
          showWarning = false;
        }
      }

      setCategoryDescription(newValue);
      if (itemsRef.current) {
        if (itemsRef.current.length > 0) {
          if (showWarning) {
            setUpdateCategory('description');
            handleOpenUpdateCategoryDialog();
          }
        }
      }
    } else {
      if (newValue !== categoryDescriptionRef.current) {
        setCategoryDescription(newValue);
        setIsUpdateCategoryDescription(true);
      } else {
        setCategoryDescription(newValue);
      }
    }
  };

  const checkOrderDescription = () => {
    if (itemsRef.current) {
      if (itemsRef.current.length > 0) {
        if (isUpdateCategoryDescriptionRef.current) {
          setUpdateCategory('description');
          handleOpenUpdateCategoryDialog();
        }
      }
    }
    setIsUpdateCategoryDescription(false);
  };

  const getDescription = selected_category_type => {
    var desc = [];
    if (collection.length > 0 && selected_category_type) {
      collection.map((category_value, index) => {
        if (category_value.settings.name === selected_category_type.name) {
          if (category_value.settings.show_category && !category_value.settings.show_as_textbox) {
            var label_text =
              category_value.settings.name === 'Activity'
              ? 'Select Activity'
              : category_value.settings.name === 'R&M'
              ? 'Select Equipment'
              : 'Select';
                  
            var requiredCategory = false;
            if (!categoryDescription) {
              if (selected_category_type) {
                if (selected_category_type.required) {
                  requiredCategory = true;
                }
              }
            } else if (!categoryDescription.id) {
              requiredCategory = true;
            }

            var collection_data =
              category_value.collection_index !== null
                ? collectionData[category_value.collection_index]
                  ? collectionData[category_value.collection_index].data
                  : []
                : [];
            desc.push(
              <Grid key={index} container spacing={0} style={{ paddingTop: '5px', paddingRight: '5px' }}>
                <Grid item xs={4} sm={4} md={4} lg={4} style={{ display: 'flex', alignItems: 'center' }}>
                  <Typography className={classes.typographyStyle2} style={{ color: 'black', paddingLeft: '11px' }}>Details</Typography>
                </Grid>
                <Grid item xs={8} sm={8} md={8} lg={8}>
                  <Autocomplete
                    key={index}
                    id="order_category_description"
                    options={collection_data}
                    getOptionLabel={option => option.name}
                    value={categoryDescription ? categoryDescription.id ? categoryDescription : null : null}
                    className={`${requiredCategory ? classes.requiredAutoComplete : ''} ${classes.autocompleteEditable}`}
                    filterOptions={filterOptions}
                    disableClearable
                    popupIcon={<img src='/static/images/icons/updown.svg' />}
                    sx={{
                      '& .MuiAutocomplete-input': { 
                        textAlign: 'right',
                        color: 'rgba(60, 60, 67, 0.6)',
                      },
                    }}
                    isOptionEqualToValue={(option, value) => option.id == value.id }
                    onChange={(event, newValue) => {
                      changeCategoryDescription(newValue);
                    }}
                    PaperComponent={({ children }) => (
                      <div>
                        {NumResultsHeaderDescription({
                          children: children,
                          className: 'MuiAutocomplete-paper',
                          type: 'category_type',
                          collection_data
                        })}
                      </div>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder={categoryDescription ? (categoryDescription.id ? categoryDescription.name : label_text) : label_text}
                      />
                    )}
                    renderOption={(props, option) => {
                      let isSelected = false;
                      if (categoryDescription) {
                        isSelected = option.id === categoryDescription.id;
                      }
    
                      return (
                        <MenuItem
                          {...props}
                          selected={isSelected}
                          className={isSelected ? classes.selectedMenuItem : classes.menuItem}
                        >
                          <Box display="flex" justifyContent="space-between" width="100%"> 
                            <span style={{ overflowX: 'hidden', textOverflow: 'ellipsis', paddingRight: '5px' }}>{option.name}</span>
                          </Box>
                        </MenuItem>
                      )
                    }}
                  />
                </Grid>
              </Grid>
            );

          } else if (category_value.settings.show_as_textbox) {
            var requiredCategory2 = false;
            if (!categoryDescription) {
              if (selected_category_type) {
                if (selected_category_type.required) {
                  requiredCategory2 = true;
                }
              }
            } else {
              if (categoryDescription.id) {
                requiredCategory2 = true;
              }
            }
            
            desc.push(
              <Grid container key={index} spacing={0} style={{ paddingTop: '5px', paddingRight: '5px' }}>
                <Grid item xs={4} sm={4} md={4} lg={4} style={{ display: 'flex', alignItems: 'center' }}>
                  <Typography className={classes.typographyStyle2} style={{ color: 'black', paddingLeft: '11px' }}>Details</Typography>
                </Grid>
                <Grid item xs={8} sm={8} md={8} lg={8}>
                  <TextField
                    key={index}
                    variant="outlined"
                    id="order_category_description"
                    className={`${requiredCategory2 ? classes.textFieldRequired : ''} ${classes.textfield2Editable}`}
                    placeholder={'Write Description'}
                    value={categoryDescription ? (categoryDescription.id ? '' : categoryDescription) : ''}
                    onChange={e => changeCategoryDescription(e.target.value, true)}
                    onBlur={e => checkOrderDescription()}
                    />
                </Grid>
              </Grid>
            );
          } else {

            desc.push(
              <TextField
                key={index}
                variant="outlined"
                id="order_category_description"
                className={classes.textfield2EditableDisabled}
                style={{ padding: '5px 5px 0px 0px' }}
                disabled
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Typography className={classes.typographyStyle2} style={{ color: 'black' }}>Details</Typography>
                    </InputAdornment>
                  ),
                }}
              />
            );
          }
        }
      });
    } else {
      desc.push(
        <TextField
          key='order_category_description_disabled'
          variant="outlined"
          id="order_category_description"
          className={classes.textfield2EditableDisabled}
          style={{ padding: '5px 5px 0px 0px'}}
          disabled
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Typography className={classes.typographyStyle2} style={{ color: 'black' }}>Details</Typography>
              </InputAdornment>
            ),
          }}
        />
      );
    }

    return <div>{desc}</div>;
  };

  const getItemDescription = (dataItem, itemIndex) => {
    var desc = [];
    if (dataItem.category_type) {
      if (collection.length > 0) {
        collection.map((category_value, index) => {
          if (category_value.settings.name === dataItem.category_type.name) {
            if (category_value.settings.show_category && !category_value.settings.show_as_textbox) {
              var label_text =
                category_value.settings.name === 'Activity'
                ? 'Select Activity'
                : category_value.settings.name === 'R&M'
                ? 'Select Equipment'
                : 'Select';
                    
              var requiredCategory = false;
              if (!dataItem.category_description) {
                if (dataItem) {
                  if (dataItem.category_type) {
                    requiredCategory = dataItem.category_type.required;
                  }
                }
              }  

              var collection_data =
                category_value.collection_index !== null
                  ? collectionData[category_value.collection_index]
                    ? collectionData[category_value.collection_index].data
                    : []
                  : [];

              desc.push(
                <Autocomplete
                  key={index}
                  id="order_item_category_description"
                  options={collection_data}
                  getOptionLabel={option => (option ? (option.name ? option.name : '') : '')}
                  value={dataItem.category_description ? (dataItem.category_description.id ? dataItem.category_description.name : null) : null}
                  className={`${requiredCategory ? classes.requiredAutoComplete : ''} ${classes.autocompleteEditable}`}
                  filterOptions={filterOptions}
                  disableClearable
                  popupIcon={<img src='/static/images/icons/updown.svg' />}
                  isOptionEqualToValue={(option, value) => option.id == value.id }
                  onChange={(event, newValue) => {
                    changeItems(null, dataItem, { name: 'category_description', value: newValue });
                  }}
                  PaperComponent={({ children }) => (
                    <div>
                      {NumResultsHeaderItemDescription({
                        children: children,
                        className: 'MuiAutocomplete-paper',
                        type: 'category_type',
                        collection_data
                      })}
                    </div>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant='outlined'
                      placeholder={
                        dataItem.category_description
                          ? dataItem.category_description.id
                            ? dataItem.category_description.name
                            : label_text
                          : label_text
                      }
                    />
                  )}
                  renderOption={(props, option) => {
                    let isSelected = false;
                    if (dataItem.category_description) {
                      isSelected = option.id === dataItem.category_description.id;
                    }

                    return (
                      <MenuItem
                        {...props}
                        selected={isSelected}
                        className={isSelected ? classes.selectedMenuItem : classes.menuItem}
                      >
                        <Box display="flex" justifyContent="space-between" width="100%"> 
                          <span style={{ overflowX: 'hidden', textOverflow: 'ellipsis', paddingRight: '5px' }}>{option.name}</span>
                        </Box>
                      </MenuItem>
                    )
                  }}
                />
              );
            } else if (category_value.settings.show_as_textbox) {
              var requiredCategory2 = false;
              if (!dataItem.category_description) {
                if (dataItem.category_type) {
                  if (dataItem.category_type.required) {
                    requiredCategory2 = true;
                  }
                }
              } else {
                if (dataItem.category_description.id) {
                  requiredCategory2 = true;
                }
              }

              desc.push(
                <TextField
                  key={index}
                  id="order_item_category_description"
                  variant='outlined'
                  className={`${classes.textfield3ItemDetails} ${requiredCategory2 ? classes.textfield3Required : classes.textfield3}`}
                  placeholder='Write Description'
                  value={dataItem.category_description ? (dataItem.category_description.id ? '' : dataItem.category_description) : ''}
                  onChange={e => changeItems(null, dataItem, { name: 'category_description', value: e.target.value })}
                  onBlur={e => checkOrderDescription()}
                />
              );
            } else {
              desc.push(
                <TextField
                  key={index}
                  variant="outlined"
                  id="item_category_description"
                  className={classes.textfield2}
                  disabled
                />
              );
            }
          }
        });
      }
    } else {
      desc.push(
        <TextField
          variant="outlined"
          id="item_category_description"
          className={classes.textfield2}
          disabled
        />
      );
    }

    return <div>{desc}</div>;
  };

  const countTotal = () => {
    if (itemsRef.current) {
      let subtotal = 0;
      
      //eslint-disable-next-line array-callback-return
      itemsRef.current.map(data => {
        if (data.statusValue !== 3) {
          if (data.unit_price && data.quantity) {
            subtotal = subtotal + parseFloat(data.unit_price * data.quantity);
          }
        }
      });
      const tax = 10 * subtotal / 100;
      const total = subtotal;
  
      setSubTotal(subtotal.toFixed(2));
      setTotalTax(tax.toFixed(2));
      setTotalOrderItem(total.toFixed(2));
    }
  };

  const addItemsCheck = () => {
    let check_order_item = true;
    
    check_order_item = !itemsRef.current.some(data => {
      if (
        data.quantity === undefined ||
        data.quantity === '' ||
        data.quantity === 0 ||
        data.quantity === '0' ||
        data.quantity < 0 ||
        data.description === undefined ||
        data.description === '' || showPOInternalCategory &&
        (data.category_type === undefined ||
        data.category_type === '' ||
        data.category_type === null)
      ) {
        return true;
      } else {
        if (showPOInternalCategory) {
          if (
            (data.category_type.show_category || data.category_type.show_as_textbox) &&
            data.category_type.required &&
            (data.category_description === undefined || data.category_description === '' || data.category_description === null)
          ) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      }
    });
    
    setItemCheck(check_order_item);
  };

  const changeItems = (e, oldData, newData) => {
    var name;
    var value;
    if (e) {
      name = e.target.name;
      value = e.target.value;
    } else {
      name = newData.name;
      value = newData.value;
    }
    
    if (name === 'category_type') {
      setItems(
        items.map(dataList =>
          dataList.id === oldData.id
            ? dataList.statusValue === 1
              ? { ...oldData, statusValue: 1, category_description: null, [name]: value }
              : { ...oldData, statusValue: 2, category_description: null, [name]: value }
            : dataList
        )
      );
    } else {
      setItems(
        items.map(dataList =>
          dataList.id === oldData.id
            ? dataList.statusValue === 1
              ? { ...oldData, statusValue: 1, [name]: value }
              : { ...oldData, statusValue: 2, [name]: value }
            : dataList
        )
      );
    }

    countTotal();
    addItemsCheck();
  };

  const deleteItems = itemData => {
    setItems(itemsRef.current.filter(data => data.id !== itemData.id));

    if (itemsRef.current.length === 0) {
      setItems([{id: uuidv4(), category_type: selectedCategoryType, category_description: categoryDescriptionRef.current, statusValue: 1}]);
    }

    addItemsCheck();
    countTotal();
  };

  const addItems = () => {
    let data = [];
    data.id = uuidv4();
    data.statusValue = 1;
    if (itemsRef.current.length > 0) {
      if (selectedCategoryType) {
        data.category_type = selectedCategoryType;
        data.category_description = categoryDescriptionRef.current;
      }
      setItems([...itemsRef.current, data]);
    } else {
      let data = [];
      data.id = uuidv4();
      data.statusValue = 1;
      if (selectedCategoryType) {
        data.category_type = selectedCategoryType;
        data.category_description = categoryDescriptionRef.current;
      } else {
        data.category_type = null;
        data.category_description = null;
      }
      setItems([data]);
    }

    addItemsCheck();
  };

  const handleChangeInput = (e, type) => {
    const { name, value } = e.target;
    var check_value = null;
    if (value) {
      if (value === ' ') {
        check_value = '';
      } else {
        check_value = value.split(/[ ,]+/).join(';');
        check_value = check_value.split(/[;;,]+/).join(';');
      }
    }
    setDataCustomEmail({ ...dataCustomEmailRef.current, [name]: check_value });
  };

  const sendEmail = () => {
    setCustomEmailLoading(true);
    var cc_email = dataCustomEmailRef.current.cc_email;
    var send_to_email = dataCustomEmailRef.current.send_to_email;
    var cc_email_array = cc_email.split(';');
    var send_to_email_array = send_to_email.split(';');

    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    var invalid_email = [];
    var invalid_email_count = 0;
    var invalid_email_text = '';
    /* eslint-disable array-callback-return*/
    cc_email_array.map(value => {
      if (value) {
        if (!value.match(mailformat)) {
          invalid_email.push(value);
          invalid_email_count++;
        }
      }
    });
    /* eslint-disable array-callback-return*/
    send_to_email_array.map(value => {
      if (value) {
        if (!value.match(mailformat)) {
          invalid_email.push(value);
          invalid_email_count++;
        }
      }
    });
    /* eslint-disable array-callback-return*/
    invalid_email.map((value, index) => {
      invalid_email_text += value;
      if (index < invalid_email.length - 1) {
        invalid_email_text += ', ';
      }
    });

    if (invalid_email_count > 0) {
      var error_message = 'Invalid email address' + (invalid_email_count > 1 ? 'es' : '');
      error_message += '\n' + invalid_email_text + '';
      enqueueSnackbar(error_message, {
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        variant: 'error',
        style: { width: '300px', whiteSpace: 'pre-line' },
        autoHideDuration: 4200,
        action: CloseSnackbarAction
      });
      setCustomEmailLoading(false);
    } else {
      editSubmit(null, 'sent', false, true);
    }
  };

  const openCustomEmail = async () => {
    setIsCustomEmail(true);

    var send_to_email = '';
    var cc_email = '';

    if (selectedSupplierRef.current) {
      if (selectedSupplierRef.current.email) {
        send_to_email = selectedSupplierRef.current.email;
      }
    }

    var employee_data = await getEmployee();
    if (employee_data.email) {
      cc_email = employee_data.email;
    } else if (employee_data.user.email) {
      cc_email = employee_data.user.email;
    }

    setDataCustomEmail({ send_to_email: send_to_email, cc_email: cc_email });
  };

  const handleCloseActions = () => {
    setAnchorElOrdersType(null);
  };

  const handleOpenActions = event => {
    setAnchorElOrdersType(event.currentTarget);
  };

  const handleFileDelete = index => {
    var current_files = uploadFilesRef.current;
    var new_upload_files = [];

    current_files.map((value, file_index) => {
      if (file_index != index) {
        new_upload_files.push(value);
      }
    });

    setUploadFiles(new_upload_files);
  };

  const handleSubmission = async (e, order) => {
    try {
      handleCloseActions(false);
      e.preventDefault();

      
      var file_data = document.getElementById('fileInput');
      const form = document.querySelector('#my-form');
      const data = new FormData(form);
      
      
      var current_files = uploadFilesRef.current;
      var new_upload_files = [];
      new_upload_files = [
        ...current_files.concat({
          name: file_data.files.item(0).name,
          formData: data
        })
      ];
      
      setUploadFiles(new_upload_files);
    } catch (e) {
      enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200, action: CloseSnackbarAction });
    }
  };

  const onChangeAddress = async (data) => {
    setDeliveryAddressOptionSelected(data);
    if (data) {
      var address = data.label;
      var subpremise = '';
      var street_number = '';
      var route = '';
      var check_address = '';
      var Zip = '';
      var State = '';
      var City = '';

      setInputAddressValue(address);

      const results = await geocodeByAddress(address);

      if (results[0]) {

        /* eslint-disable array-callback-return */
        results[0].address_components.map((value) => {
          if (value.types) {
            value.types.map((types_value) => {
              if (types_value === "subpremise") {
                subpremise = "U" + value.long_name + '/';
                check_address = "U" + value.long_name + '/';
              }

              if (types_value === "street_number") {
                street_number = value.long_name + ' ';
                check_address = check_address + ' ' + value.long_name;
              }

              if (types_value === "route") {
                route = value.long_name + '';
                check_address = check_address + ' ' + value.long_name;
              }

              if (types_value === "postal_code") {
                Zip = value.long_name;
              }

              if (types_value === "administrative_area_level_1") {
                State = value.short_name;
              }

              if (types_value === "locality") {
                City = value.long_name;
              }

            })
          }
        })
        /* eslint-enable array-callback-return */
      }

      if (check_address.length <= 30) {
        setSelectedDeliveryAddress({
          ...selectedDeliveryAddressRef.current,
          AddressLine1: subpremise + '' + street_number + '' + route,
          AddressLine2: ''
        })
      } else {
        setSelectedDeliveryAddress({
          ...selectedDeliveryAddressRef.current,
          AddressLine1: subpremise,
          AddressLine2: ', ' + street_number + ' ' + route
        })
      }

      setSelectedDeliveryAddress({
        ...selectedDeliveryAddressRef.current,
        AddressName: 'Custom',
        AddressCity: City,
        AddressState: State,
        AddressPostcode: Zip,
      })
    } else {
      setInputAddressValue('');
    }
  }

  let disabledCheck = selectedSupplier === null || itemCheck === false;

  if (showPOAccount) {
    disabledCheck = selectedCustomer === null || disabledCheck;
  }

  if (showPOInternalCategory) {
    disabledCheck = selectedCategoryType === null || disabledCheck;
    if (selectedCategoryType && (selectedCategoryType.show_category || selectedCategoryType.show_as_textbox) && selectedCategoryType.required) {
      disabledCheck = categoryDescription === null || categoryDescription === '' || disabledCheck;
    }
  }

  if (showPOAccount && showPOInternalCategory) {
    disabledCheck = selectedCustomer === null || disabledCheck;
  }

  let delivery_address_check = deliveryAddressOptionIdRef.current === -1 ? inputAddressValueRef.current : selectedDeliveryAddressRef.current.AddressLine1;

  return (
    <>
      <Grid container spacing={0} justifyContent="space-around">
        <Grid item xs={6} sm={6} md={6} lg={6}>
          <Typography noWrap component="div" style={{ paddingRight: '20px', fontWeight: '590', fontSize: '20px' }}>
              <OverflowTip>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', columnGap: '5px' }}>
                  Purchase Order
                  <InsertDriveFileIcon
                    style={{
                      color: 'white',
                      backgroundColor: '#516573',
                      padding: '3px',
                      borderRadius: '50%',
                      fontSize: '20px',
                      marginRight: '5px'
                    }}
                  />
                </div>
              </OverflowTip>
          </Typography>
        </Grid>
        <Grid item xs={6} sm={6} md={6} lg={6} className={classes.flexEnd}>  
          <OverflowTip>
            <div style={{ display: 'flex', columnGap: '10px' }}>
              <IconButton aria-label="delete" size="small" style={{  height: '40px', background: 'white', alignItems: 'center', justifyContent: 'center', borderRadius: '12px', paddingRight: '20px', paddingLeft: '20px' }}>
                <img src='/static/images/icons/draft.svg' />
                <Typography
                  noWrap
                  component="div"
                  className={classes.typographyStyle2}
                  style={{ paddingLeft: '10px', color: '#374355', lineHeight: '22px', textTransform: 'capitalize' }}
                >
                  <OverflowTip>Draft</OverflowTip>
                </Typography>
              </IconButton>
            </div>
          </OverflowTip>
        </Grid>
      </Grid>
        <Grid container spacing={0} justifyContent="space-around" style={{ marginTop: '20px' }}>
          <Grid item xs={7} sm={7} md={7} lg={7} style={{ paddingRight: '16px'}}>
            <>
              {/* -------------------- SUPPLIER -------------------- */}
              <div className='supplier'>
                <Typography
                  noWrap
                  component="div"
                  className={classes.typographyStyle}
                  style={{ paddingLeft: '16px' }}
                >
                  <OverflowTip>Supplier</OverflowTip>
                </Typography>
                <div style={{ backgroundColor: 'white', height: '44px', borderRadius: '10px', padding: '5px'}}>
                {supplierLoading ?
                  <div style={{ margin: '16px 6px 0px 11px' }}>
                    <LinearProgress />
                  </div>
                :
                  <Grid container spacing={0}>
                    <Grid item xs={4} sm={4} md={4} lg={4} style={{ display: 'flex', alignItems: 'center' }}>
                      <Typography className={classes.typographyStyle2} style={{ color: 'black', paddingLeft: '11px' }}>Supplier</Typography>
                    </Grid>
                    <Grid item xs={8} sm={8} md={8} lg={8}>    
                      <Autocomplete
                        id="supplier-options"
                        popupIcon={<img src='/static/images/icons/updown.svg' />}
                        options={supplierOptions}
                        className={classes.autocompleteEditable}
                        filterOptions={filterOptions}
                        disableClearable
                        getOptionLabel={(option) => option.name ?? ''}
                        isOptionEqualToValue={(option, value) => option.id == value.id }
                        value={selectedSupplier ?? ''}
                        onClose={() => resetSupplier()}
                        onOpen={() => openSupplierPopup()}
                        sx={{
                          '& .MuiAutocomplete-input': { 
                            textAlign: 'right',
                            color: 'rgba(60, 60, 67, 0.6)',
                          },
                        }}
                        onChange={(event, newValue) => {
                          setSelectedSupplier(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            placeholder='Select Supplier'
                          />
                        )}
                        renderOption={(props, option) => {
                          let isSelected = false;
                          if (selectedSupplier) {
                            isSelected = option.id === selectedSupplier.id;
                          }

                          return (
                            <MenuItem
                              {...props}
                              selected={isSelected}
                              className={isSelected ? classes.selectedMenuItem : classes.menuItem}
                            >
                              <Box display="flex" justifyContent="space-between" width="100%"> 
                                <span style={{ overflowX: 'hidden', textOverflow: 'ellipsis', paddingRight: '5px' }}>{option.name}</span>
                                <div onClick={e => clickSupplierFavorite(option.id, option.isFavourite)}>
                                  {option.isFavourite ? (
                                    <StarRoundedIcon fontSize="small" />
                                  ) : (
                                    <StarBorderRoundedIcon fontSize="small" />
                                  )}
                                </div>
                              </Box>
                            </MenuItem>
                          )
                        }}
                      />
                    </Grid>
                  </Grid>
                }
                </div>
              </div>


              {/* -------------------- ACCOUNT -------------------- */}
              {showPOAccount ?
                <>
                  <Typography
                    noWrap
                    component="div"
                    className={classes.typographyStyle}
                    style={{ marginTop: '17px', paddingLeft: '16px' }}
                  >
                    <OverflowTip>Account</OverflowTip>
                  </Typography>
                  <div style={{ backgroundColor: 'white', height: '88px', borderRadius: '10px', padding: '5px 0px 5px 5px'}}>
                    {customerLoading ?
                      <div style={{ margin: '18px 11px' }}>
                        <LinearProgress />
                      </div>
                    :
                      <Grid container spacing={0} style={{ paddingBottom: '5px', paddingRight: '5px' }}>
                        <Grid item xs={4} sm={4} md={4} lg={4} style={{ display: 'flex', alignItems: 'center' }}>
                          <Typography className={classes.typographyStyle2} style={{ color: 'black', paddingLeft: '11px' }}>Customer</Typography>
                        </Grid>
                        <Grid item xs={8} sm={8} md={8} lg={8}>    
                          <Autocomplete
                            id="customer-options"
                            options={customerOptionsRef.current}
                            className={classes.autocompleteEditable}
                            filterOptions={filterOptions}
                            disableClearable
                            popupIcon={<img src='/static/images/icons/updown.svg' />}
                            getOptionLabel={(option) => option.name ?? ''}
                            isOptionEqualToValue={(option, value) => option.id == value.id }
                            value={selectedCustomer ?? ''}
                            onClose={() => resetCustomer()}
                            sx={{
                              '& .MuiAutocomplete-input': { 
                                textAlign: 'right',
                                color: 'rgba(60, 60, 67, 0.6)',
                              },
                            }}
                            onChange={(event, newValue) => {
                              const enable_click = checkEnableClick(newValue);

                              if (enable_click) {
                                var isSelected = true;
                                if (selectedSupplierRef.current && newValue.suppliers && newValue.suppliers.length > 0) {
                                  var selected_supplier = Enumerable.from(newValue.suppliers)
                                    .where(x => x.supplier && x.supplier.id == selectedSupplierRef.current.id)
                                    .toArray();
                          
                                  if (selected_supplier.length == 0) {
                                    isSelected = false;
                                    window.alert("Customer is not available for the supplier");
                                  }
                                }
                          
                                if (isSelected) {
                                  setSelectedCustomer(newValue);
                                  setDeliveryAddressOptionId(0);
                                  setSelectedDeliveryAddress({
                                    AddressLine1: '',
                                    AddressLine2: '',
                                    AddressName: '',
                                    AddressCity: '',
                                    AddressState: '',
                                    AddressPostcode: '',
                                  });
                                  changeDeliveryAddressOption(newValue);
                                }
                              } else {
                                setSelectedCustomer(newValue);
                                setDeliveryAddressOptionId(0);
                                setSelectedDeliveryAddress({
                                  AddressLine1: '',
                                  AddressLine2: '',
                                  AddressName: '',
                                  AddressCity: '',
                                  AddressState: '',
                                  AddressPostcode: '',
                                });
                                changeDeliveryAddressOption(newValue);
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                placeholder='Select Customer'
                                multiline
                              />
                            )}
                            renderOption={(props, option) => {
                              const enable_click = checkEnableClick(option)

                              let isSelected = false;
                              if (selectedCustomer) {
                                isSelected = option.id === selectedCustomer.id;
                              }

                              return (
                                <MenuItem
                                  {...props}
                                  selected={isSelected}
                                  className={isSelected ? classes.selectedMenuItem : classes.menuItem}
                                  disabled={!enable_click}
                                >
                                  <Box display="flex" justifyContent="space-between" width="100%"> 
                                    <span style={{ overflowX: 'hidden', textOverflow: 'ellipsis', paddingRight: '5px' }}>{option.name}</span>
                                    <div onClick={e => clickCustomerFavorite(option)}>
                                      {option.isFavourite ? (
                                        <StarRoundedIcon fontSize="small" />
                                      ) : (
                                        <StarBorderRoundedIcon fontSize="small" />
                                      )}
                                    </div>
                                  </Box>
                                </MenuItem>
                              )
                            }}
                          />
                        </Grid>
                      </Grid>
                    }
                    <Divider style={{ marginLeft: '11px'}} />
                    <TextField
                      disabled
                      variant="outlined"
                      style={{ paddingLeft: '11px', paddingRight: '8px'}}
                      className={classes.textfield}
                      value={ selectedCustomer ? selectedCustomer.legal_name ?? '' : ''}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Typography className={classes.typographyStyle2} style={{ color: 'black' }}>Invoice to</Typography>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                </>
              : ''}
            </>
          </Grid>
          <Grid item xs={5} sm={5} md={5} lg={5}>
            <>
              {/* -------------------- DATE -------------------- */}
              <div className='supplier'>
                <Typography
                  noWrap
                  component="div"
                  className={classes.typographyStyle}
                  style={{ paddingLeft: '16px' }}
                >
                  <OverflowTip>Date</OverflowTip>
                </Typography>
                <div style={{ backgroundColor: 'white', height: '44px', borderRadius: '10px', display: 'flex', alignItems: 'center' }}>
                  <Typography
                    noWrap
                    component="div"
                    className={classes.typographyStyle2}
                    style={{ paddingLeft: '16px' }}
                  >
                    <OverflowTip>{moment(Date.now()).format('DD MMMM YYYY')}</OverflowTip>
                  </Typography>
                </div>
              </div>

              {/* -------------------- INTERNAL -------------------- */}
              {showPOInternalCategory ?
                <>
                  <Typography
                    noWrap
                    component="div"
                    className={classes.typographyStyle}
                    style={{ marginTop: '17px', color: '#67AC2D', paddingLeft: '16px' }}
                  >
                    <OverflowTip>Internal</OverflowTip>
                  </Typography>
                  <div style={{ backgroundColor: 'rgba(130, 203, 67, 0.15)', height: '88px', borderRadius: '10px', padding: '5px 0px 5px 5px' }}>
                    {categoryTypeLoading ?
                      <div style={{ margin: '18px 11px' }}>
                        <LinearProgress />
                      </div>
                    :
                      <Grid container spacing={0} style={{ paddingBottom: '5px' , paddingRight: '5px'}}>
                        <Grid item xs={4} sm={4} md={4} lg={4} style={{ display: 'flex', alignItems: 'center' }}>
                          <Typography className={classes.typographyStyle2} style={{ color: 'black', paddingLeft: '11px' }}>Category</Typography>
                        </Grid>
                        <Grid item xs={8} sm={8} md={8} lg={8}>    
                          <Autocomplete
                            id="category-type-options"
                            options={categoryType}
                            className={classes.autocompleteEditable}
                            filterOptions={filterOptions}
                            disableClearable
                            PaperComponent={({ children }) => (
                              <div>
                                {NumResultsHeader({
                                  children: children,
                                  className: 'MuiAutocomplete-paper',
                                  type: 'category_type',
                                })}
                              </div>
                            )}
                            popupIcon={<img src='/static/images/icons/updown.svg' />}
                            getOptionLabel={(option) => option.name ?? ''}
                            isOptionEqualToValue={(option, value) => option.id == value.id }
                            value={selectedCategoryType ?? ''}
                            onClose={() => resetCategory()}
                            sx={{
                              '& .MuiAutocomplete-input': { 
                                textAlign: 'right', 
                                color: 'rgba(60, 60, 67, 0.6)',
                              },
                            }}
                            onChange={(event, newValue) => {
                              changeCategory(newValue);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                placeholder='Select Category'
                              />
                            )}
                            renderOption={(props, option) => {
                              let isSelected = false;
                              if (selectedCategoryType) {
                                isSelected = option.id === selectedCategoryType.id;
                              }
                              
                              return (
                              <MenuItem
                                {...props}
                                selected={isSelected}
                                className={isSelected ? classes.selectedMenuItem : classes.menuItem}
                              >
                                <Box display="flex" justifyContent="space-between" width="100%"> 
                                  <span style={{ overflowX: 'hidden', textOverflow: 'ellipsis', paddingRight: '5px' }}>{option.name}</span>
                                  <div onClick={e => clickCategoryFavorite(option.id, option.isFavourite)}>
                                    {option.isFavourite ? (
                                      <StarRoundedIcon fontSize="small" />
                                    ) : (
                                      <StarBorderRoundedIcon fontSize="small" />
                                    )}
                                  </div>
                                </Box>
                              </MenuItem>
                            )}}
                          />
                        </Grid>
                      </Grid>
                    }
                    <Divider style={{ marginLeft: '11px'}} />
                    {categoryTypeLoading ?
                      <div style={{ margin: '18px 11px' }}>
                        <LinearProgress />
                      </div>
                    :
                      getDescription(selectedCategoryType)
                    }
                  </div>
                </>
              : ''}
            </>
          </Grid>
        </Grid>

        {/* -------------------- TABLE HEADER -------------------- */}
        <Grid
          container
          spacing={0}
          justifyContent="space-around"
          style={{
            marginTop: '20px',
            maxWidth: showPOInternalCategory ? '100%' : 'calc(100% - 29px)'
          }}
        >
          <Grid
            item
            xs={showPOInternalCategory ? 7 : 12}
            sm={showPOInternalCategory ? 7 : 12}
            md={showPOInternalCategory ? 7 : 12}
            lg={showPOInternalCategory ? 7 : 12}
          >
            <div style={{ paddingRight: showPOInternalCategory ? '16px' : '0px' }}>
              <Grid container spacing={0} justifyContent="space-around" style={{ padding: '0px 5px' }}>
                <Grid item xs={1} sm={1} md={1} lg={1} className={classes.gridPaddingRight}>
                  <Typography noWrap component="div" className={classes.typographyStyle} style={{ paddingLeft: '5px'}}>
                    <OverflowTip>Qty</OverflowTip>
                  </Typography>
                </Grid>
                <Grid item xs={2} sm={2} md={2} lg={2} className={classes.gridPaddingRight}>
                  <Typography noWrap component="div" className={classes.typographyStyle} style={{ paddingLeft: '5px'}}>
                    <OverflowTip>Code</OverflowTip>
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} className={classes.gridPaddingRight}>
                  <Typography noWrap component="div" className={classes.typographyStyle} style={{ paddingLeft: '5px'}}>
                    <OverflowTip>Description</OverflowTip>
                  </Typography>
                </Grid>
                <Grid item xs={3} sm={3} md={3} lg={3} className={classes.gridPaddingRight}>
                  <Typography noWrap component="div" className={classes.typographyStyle} style={{ paddingLeft: '5px'}}>
                    <OverflowTip>Unit Price</OverflowTip>
                  </Typography>
                </Grid>
                {/* <Grid item xs={2} sm={2} md={2} lg={2}>
                  <Typography noWrap component="div" className={`${classes.typographyStyle} ${classes.flexEnd}`} style={{ paddingRight: '10px', paddingLeft: '5px' }}>
                    <OverflowTip>Invoiced</OverflowTip>
                  </Typography>
                </Grid> */}
              </Grid>
            </div>
          </Grid>
          {showPOInternalCategory ?
            <Grid item xs={5} sm={5} md={5} lg={5}>
              <Grid container spacing={0} justifyContent="space-around" style={{ paddingLeft: '16px' }}>
                <Grid item className={classes.gridPaddingRight} style={{ width: 'calc(100% - 29px)'}}>
                  <Grid container spacing={0}>
                    <Grid item xs={6} sm={6} md={6} lg={6} className={classes.gridPaddingRight}>
                      <Typography noWrap component="div" className={classes.typographyStyle} style={{ color: '#67AC2D' }}>
                        <OverflowTip>Item Category</OverflowTip>
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6}>
                      <Typography noWrap component="div" className={classes.typographyStyle} style={{ color: '#67AC2D' }}>
                        <OverflowTip>Item Details</OverflowTip>
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item style={{ width: '29px'}}>
                </Grid>
              </Grid>
            </Grid>
          : ''}
        </Grid>

        {/* -------------------- TABLE BODY -------------------- */}
        {itemsRef.current ?
          itemsRef.current?
            itemsRef.current.map((dataItem, index) => {
              const isLastItem = index === itemsRef.current.length - 1;

              if (dataItem.statusValue !== 3) {
                return (
                  <Grid key={index} container spacing={0} justifyContent="space-around" style={{ marginBottom: isLastItem ? '0px' : '5px' }}>
                    <Grid
                      item
                      style={{ maxWidth: showPOInternalCategory ? '100%' : 'calc(100% - 29px)' }}
                      xs={showPOInternalCategory ? 7 : 12}
                      sm={showPOInternalCategory ? 7 : 12}
                      md={showPOInternalCategory ? 7 : 12}
                      lg={showPOInternalCategory ? 7 : 12}
                    >
                      <div style={{ paddingRight: showPOInternalCategory ? '16px' : '0px' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: 'white', padding: '0px 5px', alignItems: 'center', borderRadius: '10px' }}>
                          <Grid container spacing={0} justifyContent="space-around" style={{ height: '44px', display: 'flex', alignItems: 'center' }} >
                            <Grid item xs={1} sm={1} md={1} lg={1} className={classes.gridPaddingRight}>
                              <TextField
                                id={`order_item_quantity_${index}`}
                                variant='outlined'
                                className={
                                  dataItem.quantity && dataItem.quantity !== '0' && dataItem.quantity !== '' && dataItem.quantity > 0
                                    ? classes.textfield3
                                    : classes.textfield3Required
                                }
                                fullWidth
                                name="quantity"
                                type='number'
                                value={dataItem.quantity ? dataItem.quantity : ''}
                                onChange={event => changeItems(event, dataItem)}
                              />
                            </Grid>
                            <Grid item xs={2} sm={2} md={2} lg={2} className={classes.gridPaddingRight}>
                              <TextField
                                id={`order_item_description_${index}`}
                                variant='outlined'
                                className={classes.textfield3}
                                fullWidth
                                name="supplier_code"
                                value={dataItem.supplier_code ? dataItem.supplier_code : ''}
                                onChange={event => changeItems(event, dataItem)}
                              />
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={6} className={classes.gridPaddingRight}>
                              <TextField
                                id={`order_item_description_${index}`}
                                variant='outlined'
                                className={dataItem.description ? classes.textfield3 : classes.textfield3Required}
                                fullWidth
                                name="description"
                                value={dataItem.description ? dataItem.description : ''}
                                onChange={event => changeItems(event, dataItem)}
                              />
                            </Grid>
                            <Grid item xs={3} sm={3} md={3} lg={3} className={classes.gridPaddingRight}>
                              <TextField
                                id={`order_item_unit_price_${index}`}
                                variant='outlined'
                                className={classes.textfield3}
                                fullWidth
                                name="unit_price"
                                value={dataItem.unit_price ? parseFloat(dataItem.unit_price).toFixed(2) : ''}
                                onChange={event => changeItems(event, dataItem)}
                                InputProps={{
                                  inputComponent: NumberFormatCustom
                                }}
                              />
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    </Grid>
                    {showPOInternalCategory ?
                      <Grid item xs={5} sm={5} md={5} lg={5}>
                        <Grid container spacing={0} justifyContent="space-around">
                          <Grid item className={classes.gridPaddingRight} style={{ width: 'calc(100% - 29px)'}}>
                            <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: 'rgba(130, 203, 67, 0.15)', paddingLeft: '5px', alignItems: 'center', borderRadius: '10px' }}>
                              <Grid container spacing={0} justifyContent="space-around" style={{ height: '44px', display: 'flex', alignItems: 'center' }} >
                                <Grid item xs={6} sm={6} md={6} lg={6} className={classes.gridPaddingRight}>
                                  {categoryTypeLoading ?
                                    <div style={{ margin: '18px 11px' }}>
                                      <LinearProgress />
                                    </div>
                                  :
                                    <Autocomplete
                                      id="item-category-type-options"
                                      options={categoryTypeRef.current}
                                      className={classes.autocompleteEditable}
                                      filterOptions={filterOptions}
                                      classes={{
                                        paper: classes.paperstyle
                                      }}
                                      disableClearable
                                      popupIcon={<img src='/static/images/icons/updown.svg' />}
                                      getOptionLabel={(option) => option.name ?? ''}
                                      isOptionEqualToValue={(option, value) => option.id == value.id }
                                      value={dataItem.category_type ?? ''}
                                      onClose={() => resetCategory()}
                                      onChange={(event, newValue) => {
                                        changeItems(null, dataItem, { name: 'category_type', value: newValue });
                                      }}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          variant='outlined'
                                          placeholder='Select Category'
                                        />
                                      )}
                                      renderOption={(props, option) => {
                                        let isSelected = false;
                                        if (dataItem.category_type) {
                                          isSelected = option.id === dataItem.category_type.id;
                                        }

                                        return (
                                          <MenuItem
                                            {...props}
                                            selected={isSelected}
                                            className={isSelected ? classes.selectedMenuItem : classes.menuItem}
                                          >
                                            <Box display="flex" justifyContent="space-between" width="100%"> 
                                              <span style={{ overflowX: 'hidden', textOverflow: 'ellipsis', paddingRight: '5px' }}>{option.name}</span>
                                              <div onClick={e => clickCategoryFavorite(option.id, option.isFavourite)}>
                                                {option.isFavourite ? (
                                                  <StarRoundedIcon fontSize="small" />
                                                ) : (
                                                  <StarBorderRoundedIcon fontSize="small" />
                                                )}
                                              </div>
                                            </Box>
                                          </MenuItem>
                                        )
                                      }}
                                    />
                                  }
                                </Grid>
                                <Grid item xs={6} sm={6} md={6} lg={6} className={classes.gridPaddingRight}>
                                  {categoryTypeLoading ?
                                    <div style={{ margin: '18px 11px 18px 5px' }}>
                                      <LinearProgress />
                                    </div>
                                  : dataItem.category_type ? (
                                    getItemDescription(dataItem, index)
                                  ) : null}
                                </Grid>
                              </Grid>
                            </div>
                          </Grid>
                          <Grid item style={{ width: '29px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'end' }}>
                            <IconButton onClick={() => deleteItems(dataItem)} aria-label="delete" size="small" style={{ width: '22px', height: '22px'}} >
                              <img src='/static/images/icons/remove.svg' />
                            </IconButton>
                          </Grid>
                        </Grid>
                      </Grid>
                    :
                      <Grid item style={{ width: '29px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'end' }}>
                        <IconButton onClick={() => deleteItems(dataItem)} aria-label="delete" size="small" style={{ width: '22px', height: '22px'}} >
                          <img src='/static/images/icons/remove.svg' />
                        </IconButton>
                      </Grid>
                    }
                  </Grid>
                )
              }
            })
          : ''
        : ''}
        {/* -------------------- ADD ITEM -------------------- */}
        <div style={{ display: 'flex', paddingLeft: '6px', marginTop: '15px' }}>
          <AddItemButton
            variant="outlined"
            onClick={addItems}
            startIcon={<img src='/static/images/icons/add-item.svg' />}
          >
            Add Item
          </AddItemButton>
        </div>

        {/* -------------------- DELIVERY -------------------- */}
        <Grid container spacing={0} justifyContent='space-around' style={{ marginTop: '15px' }}>
          <Grid item xs={7} sm={7} md={7} lg={7}>
            <Typography
              noWrap
              component="div"
              className={classes.typographyStyle}
              style={{ marginTop: '17px', paddingLeft: '16px' }}
            >
              <OverflowTip>Delivery</OverflowTip>
            </Typography>
            <div style={{ backgroundColor: 'white', borderRadius: '10px', marginRight: '16px', padding: '5px 0px 5px 5px' }}>
              <Grid container spacing={0} style={{ paddingBottom: '5px', paddingRight: '5px' }}>
                <Grid item xs={4} sm={4} md={4} lg={4} style={{ display: 'flex', alignItems: 'center' }}>
                  <Typography className={classes.typographyStyle2} style={{ color: 'black', paddingLeft: '11px' }}>Delivery by</Typography>
                </Grid>
                <Grid item xs={8} sm={8} md={8} lg={8}>
                  <ThemeProvider theme={theme}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        inputVariant="outlined"
                        id="date_picker"
                        inputProps={{
                          readOnly: true,
                        }}
                        format="dd MMMM yyyy"
                        placeholder="dd/MM/yyyy"
                        value={moment(selectedDeliveryDate).format()}
                        onChange={(date) => handleDeliveryDateChange(date)}
                        keyboardIcon={<img src="/static/images/icons/calendar.svg" />}
                      />
                    </MuiPickersUtilsProvider>
                  </ThemeProvider>
                </Grid>
              </Grid>
              <div style={{ backgroundColor: 'white' }}>
                <Divider style={{ marginLeft: '11px' }} />
              </div>
              <Grid container spacing={0} style={{ padding: '5px 5px 5px 0px' }}>
                <Grid item xs={4} sm={4} md={4} lg={4} style={{ display: 'flex', alignItems: 'center' }}>
                  <Typography className={classes.typographyStyle2} style={{ color: 'black', paddingLeft: '11px' }}>Delivery instructions</Typography>
                </Grid>
                <Grid item xs={8} sm={8} md={8} lg={8}>    
                  <TextField
                    variant="outlined"
                    className={classes.textfield2Editable}
                    value={deliveryInstructions ?? ''}
                    placeholder='Add delivery notes'
                    name="delivery_instructions"
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
              <div style={{ backgroundColor: 'white' }}>
                <Divider style={{ marginLeft: '11px' }} />
              </div>
              <Grid container spacing={0} style={{ padding: '5px 5px 0px 0px' }}>
                <Grid item xs={4} sm={4} md={4} lg={4} style={{ display: 'flex', alignItems: 'center' }}>
                  <Typography className={classes.typographyStyle2} style={{ color: 'black', paddingLeft: '11px' }}>Delivery address</Typography>
                </Grid>
                <Grid item xs={8} sm={8} md={8} lg={8}>
                  <Autocomplete
                    id="delivery-address-options"
                    options={deliveryAddressOptions}
                    className={classes.autocompleteEditable2}
                    disableClearable
                    popupIcon={<img src='/static/images/icons/updown.svg' />}
                    getOptionLabel={(option) => option.lable || ''}
                    isOptionEqualToValue={(option, value) => option.id == value.id }
                    value={deliveryAddress ?? ''}
                    sx={{
                      height: '49px !important',
                      '& .MuiAutocomplete-input': { 
                        textAlign: 'right',
                        color: 'rgba(60, 60, 67, 0.6)',
                      },
                      '& .MuiOutlinedInput-root': {
                        height: '49px !important',
                      }
                    }}
                    onChange={(event, newValue) => {
                      setDeliveryAddress(newValue);
                      formattingDeliveryAddress(newValue);
                      setDeliveryAddressOptionId(newValue.id);
                      setDeliveryAddressOptionSelected('');
                      setInputAddressValue('');
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        multiline
                      />
                    )}
                    renderOption={(props, option) => {
                      let isSelected = false;
                      if (deliveryAddress) {
                        isSelected = option.id === deliveryAddress.id;
                      }

                      return (
                        <MenuItem
                          {...props}
                          selected={isSelected}
                          className={isSelected ? classes.selectedMenuItem : classes.menuItem}
                        >
                          <Box display="flex" justifyContent="space-between" width="100%"> 
                            <span style={{ wordWrap: 'break-word', overflowWrap: 'break-word', textOverflow: 'ellipsis', whiteSpace: 'normal', paddingRight: '5px' }}>
                              {option.lable}
                            </span>
                          </Box>
                        </MenuItem>
                      )
                    }}
                  />
                </Grid>
                {deliveryAddressOptionIdRef.current === -1 ?
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <GooglePlacesAutocomplete
                      apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                      autocompletionRequest={{
                        componentRestrictions: { country: "au" },
                        types: ['address'],
                        fields: ["address_components", "geometry", "icon", "name"]
                      }}
                      selectProps={{
                        inputValue: inputAddressValueRef.current,
                        className: `select-address-autocomplete address`,
                        placeholder: 'Search address...',
                        blurInputOnSelect: true,
                        onInputChange: (value, { action }) => {
                          if (action === "input-change") {
                            setInputAddressValue(value)
                          }
                        },
                        onChange: (data) => {
                          onChangeAddress(data);
                        },
                        styles: {
                          option: (provided, { isFocused, isSelected }) => ({
                            ...provided,
                            background: isSelected ? "#d5f2be !important" : isFocused ? "#d5f2be !important" : "white"
                          })
                        },
                        value: deliveryAddressOptionSelected,
                        controlShouldRenderValue: true,
                        isClearable: true,
                        filterOption: filterOption,
                      }}
                    />
                  </Grid>
                : ''}
              </Grid>
            </div>

            {/* Notes */}
            <Typography
              noWrap
              component="div"
              className={classes.typographyStyle}
              style={{ marginTop: '17px', paddingLeft: '16px' }}
            >
              <OverflowTip>Notes</OverflowTip>
            </Typography>
            <div style={{ backgroundColor: 'white', borderRadius: '10px', marginRight: '16px', padding: '5px' }}>
              <Grid container spacing={0}>
                <Grid item xs={12} sm={12} md={12} lg={12}>   
                  <TextField
                    variant="outlined"
                    className={`${classes.textfield2Editable} ${classes.leftAlignTextfield}`}
                    value={notes ?? ''}
                    placeholder='Insert note...'
                    name="notes"
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid item xs={5} sm={5} md={5} lg={5} >
            <Typography
              noWrap
              component="div"
              className={classes.typographyStyle}
              style={{ marginTop: '17px', paddingLeft: '16px' }}
            >
              <OverflowTip>Totals</OverflowTip>
            </Typography>
            <div style={{ backgroundColor: 'white', borderRadius: '10px' }}>
              {showTotalDetails ?
                <>
                  <Grid container spacing={0} style={{ paddingLeft: '16px', paddingRight: '13px', height: '44px' }}>
                    <Grid item xs={4} sm={4} md={4} lg={4} className={classes.gridItem}>
                      <Typography className={classes.typographyStyle2} style={{ color: 'black' }}>Subtotal</Typography>
                    </Grid>
                    <Grid item xs={8} sm={8} md={8} lg={8} className={classes.gridItem}>
                      <Typography className={classes.typographyStyle2} style={{ color: '#3C3C4399', marginLeft: 'auto' }}>
                        <OverflowTip>
                          {isNaN(subTotalRef.current) ? 0 : '$' + formatNumber((Math.round(subTotalRef.current * 100) / 100).toFixed(2))}
                        </OverflowTip>
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={0} style={{ paddingLeft: '16px', paddingRight: '13px', height: '44px' }}>
                    <Grid item xs={4} sm={4} md={4} lg={4} className={classes.gridItem}>
                      <Typography className={classes.typographyStyle2} style={{ color: 'black' }}>Tax (10% GST)</Typography>
                    </Grid>
                    <Grid item xs={8} sm={8} md={8} lg={8} className={classes.gridItem}>
                      <Typography className={classes.typographyStyle2} style={{ color: '#3C3C4399', marginLeft: 'auto' }}>
                        <OverflowTip>
                          {isNaN(subTotalRef.current) ? 0 : '$' + formatNumber((Math.round(subTotalRef.current * 100) / 100).toFixed(2))}
                        </OverflowTip>
                      </Typography>
                    </Grid>
                  </Grid>
                  <Divider style={{ marginLeft: '16px' }} />
                </>
              : ''}
              <Grid container spacing={0} justifyContent="space-around" className={showTotalDetails ? classes.gridContainer2 : classes.gridContainer} style={{ paddingRight: '5px'}}>
                <Grid item xs={6} sm={6} md={6} lg={6} className={classes.gridItem}>
                  <Typography
                    noWrap
                    component="div"
                    className={classes.typographyStyle4}
                    style={{ lineHeight: '22px' }}
                    >
                    <OverflowTip>Total</OverflowTip>
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} className={classes.gridItem} style={{ justifyContent: 'flex-end' }}>
                  <Typography
                    noWrap
                    component="div"
                    className={classes.typographyStyle4}
                    style={{ backgroundColor: 'rgba(118, 118, 128, 0.12)', padding: '6px 11px', borderRadius: '6px' }}
                  >
                    <OverflowTip>
                      {isNaN(totalOrderItemRef.current) ? 0 : '$' + formatNumber((Math.round(totalOrderItemRef.current * 100) / 100).toFixed(2))}
                    </OverflowTip>
                  </Typography>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>

        {/* -------------------- BUTTON -------------------- */}
        <div style={{ width: '100%', display: 'flex', marginTop: '30px'}}>
          <div style={{ width: '50%', flexWrap: 'wrap', display: 'flex', gap: '10px', overflowX: 'auto' }}>
            {uploadFilesRef.current.length > 0
              ? uploadFilesRef.current.map((file, index) => {
                return (
                  <Grid key={index} span="true" style={{ display: 'flex' }}>
                    <Chip
                      icon={<InsertDriveFileIcon />}
                      label={file.name}
                      onDelete={() => handleFileDelete(index)}
                      variant="outlined"
                      style={{ marginRight: '5px' }}
                    />
                  </Grid>
                );
              })
            : ''}
          </div>
          <div style={{ width: '50%', maxWidth: '50%', display: 'flex', justifyContent: 'flex-end', gap: '10px', flexWrap: 'wrap' }}>
            {loadingSubmit ? (
              <span display="inline-flex" style={{ marginTop: '5px' }}>
                <CircularProgress size={20} />
              </span>
            ) : (
              <>
                <CustomButton
                  variant="contained"
                  onClick={handleOpenActions}
                >
                  Actions
                </CustomButton>

                <StyledMenu
                  id="customized-menu"
                  anchorEl={anchorElOrdersType}
                  keepMounted
                  open={Boolean(openActions)}
                  onClose={handleCloseActions}
                  className={classes.listStyle}
                >
                  <List>
                    <MenuItem className={classes.menuitem2} onClick={() => {}} >
                      <form id="my-form" className={classes.form}>
                        <input type="file" name="file" id="fileInput" onChange={e => handleSubmission(e, list)} className={classes.input} />
                        <label htmlFor="fileInput" className={classes.fileInputLabel}>Attach File</label>
                      </form>
                    </MenuItem>
                    {selectedSupplier ? (
                      selectedSupplier.id ? (
                        selectedSupplier.email ? (
                          <MenuItem
                            className={classes.menuitem}
                            disabled={disabledCheck || !delivery_address_check}
                            onClick={() => editSubmit(list.id, 'sent', true)}
                          >
                            Submit and Send
                          </MenuItem>
                        ) : null
                      ) : list.supplier ? (
                        list.supplier.email ? (
                          <MenuItem
                            className={classes.menuitem}
                            disabled={disabledCheck || !delivery_address_check}
                            onClick={() => editSubmit(list.id, 'sent', true)}
                          >
                            Submit and Send
                          </MenuItem>
                        ) : null
                      ) : null
                    ) : null}

                    {selectedSupplier ? (
                      selectedSupplier.id ? (
                        selectedSupplier.email ? (
                          <MenuItem
                            className={classes.menuitem}
                            onClick={() => openCustomEmail()}
                            disabled={disabledCheck || !delivery_address_check}
                          >
                            Submit and Custom Send
                          </MenuItem>
                        ) : null
                      ) : list.supplier ? (
                        list.supplier.email ? (
                          <MenuItem
                            className={classes.menuitem}
                            onClick={() => openCustomEmail()}
                            disabled={disabledCheck || !delivery_address_check}
                          >
                            Submit and Custom Send
                          </MenuItem>
                        ) : null
                      ) : null
                    ) : null}
                  </List>
                </StyledMenu>

                <CustomButton
                  variant="contained"
                  onClick={() => editSubmit(list.id, 'draft')}
                >
                  Save Draft
                </CustomButton>
                <CustomButton2
                  variant="contained"
                  onClick={() => editSubmit(list.id, 'sent')}
                  disabled={disabledCheck || !delivery_address_check}
                >
                  Submit
                </CustomButton2>
              </>
            )}
          </div>
        </div>

        <Dialog open={isCustomEmail} onClose={closeCustomEmail}>
        <DialogTitle disableTypography={true} id="form-dialog-title">
          <Typography component="div" variant="h6">
            <h2>Submit and Custom Send</h2>
          </Typography>
        </DialogTitle>
        <DialogContent style={{ width: '450px' }}>
          <TextField
            id="email_text"
            label="Email"
            variant="outlined"
            fullWidth={true}
            name="send_to_email"
            value={dataCustomEmail.send_to_email}
            placeholder={'user1@gmail.com;user2@gmail.com'}
            onChange={handleChangeInput}
          />
          <br />
          <br />
          <TextField
            id="cc_email_text"
            label="CC Email"
            variant="outlined"
            fullWidth={true}
            name="cc_email"
            value={dataCustomEmail.cc_email}
            placeholder={'user1@gmail.com;user2@gmail.com'}
            onChange={handleChangeInput}
          />
        </DialogContent>

        <DialogActions style={{ height: '60px', marginRight: '5px' }}>
          {customEmailLoading ? (
            <CircularProgress className={classes.circular} />
          ) : (
            <div>
              <CancelButton id="cancel_btn" size="small" variant="contained" onClick={closeCustomEmail}>
                Cancel
              </CancelButton>
              <SubmitButton
                id="save_btn"
                size="small"
                variant="contained"
                onClick={() => sendEmail()}
                disabled={!dataCustomEmail.send_to_email}
              >
                Save
              </SubmitButton>
            </div>
          )}
        </DialogActions>
      </Dialog>

        <Dialog
          isopen={`${openUpdateCategoryDialog}`}
          open={openUpdateCategoryDialog}
          fullWidth={true}
          onClose={handleCloseUpdateCategoryDialog}
        >
          <DialogTitle disableTypography={true} id="form-dialog-title">
            <Typography component="div" variant="h6">
              <h2>Confirmation</h2>
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Typography variant="h6">
              Do you want to apply this {updateCategory === 'category' ? 'category' : 'category description'} to all existing lines?
            </Typography>
          </DialogContent>
          <DialogActions style={{ height: '60px' }}>
            <div>
              <CancelButton id="cancel_btn" size="small" variant="contained" onClick={handleCloseUpdateCategoryDialog} style={{ marginRight: '10px' }}>
                No
              </CancelButton>
              <SubmitButton id="save_btn" size="small" variant="contained" onClick={() => updateAllCategory()}>
                Yes
              </SubmitButton>
            </div>
          </DialogActions>
        </Dialog>
    </>
  )
}

export default AddOrder