import React, { useState, useEffect } from 'react';
import useStateRef from 'react-usestateref';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import { getEmployee } from 'src/utils/sessions';
import EventEmitter from "src/utils/EventEmitter";
import ErrorMessage from 'src/components/ErrorMessage';
import directus from "../../services/directus";
import Chip from '@material-ui/core/Chip';
import { useNavigate } from 'react-router-dom';
import ClearIcon from '@mui/icons-material/Clear';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import { useStyles, SubmitButton, CancelButton, CustomButton, CustomButton2, StyledMenu } from './style.js';
import { POApproverPermission, AdminPermission } from 'src/utils/Permission';
import { CloseSnackbarAction } from 'src/components/CloseSnackbarAction';
import {
  Typography,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
  Button,
  withStyles,
  List,
  MenuItem,
  Grid,
  IconButton
} from "@material-ui/core";

const CancelledButton = withStyles({
  root: {
    borderRadius: '40px',
    backgroundColor: '#E87777',
    color: '#ffffff',
    height: '30px',
    width: '150px',
    '&:hover': {
      backgroundColor: '#f08984',
    },
  },
})(Button);

export const CompletedButton = withStyles({
  root: {
    borderRadius: '40px',
    backgroundColor: '#67AC2D',
    color: '#ffffff',
    height: '30px',
    width: '150px',
    '&:hover': {
      backgroundColor: '#77c337',
    },
  },
})(Button);

const SentButton = withStyles({
  root: {
    borderRadius: '40px',
    backgroundColor: '#f9ebcc',
    height: '30px',
    width: '150px',
    '&:hover': {
      backgroundColor: '#fde9b9',
    },
  },
})(Button);

const DraftButton = withStyles({
  root: {
    borderRadius: '40px',
    backgroundColor: '#8aa9ca',
    color: '#ffffff',
    height: '30px',
    width: '150px',
    '&:hover': {
      backgroundColor: '#6898cb',
    },
  },
})(Button);

const ButtonsView = ({
  selectedOrder,
  loadOrderSelected,
  disabledCheck,
  selectedCustomer,
  editSubmit,
  setDataCustomEmail,
  dataCustomEmail,
  customEmailLoading,
  setCustomEmailLoading,
  setIsCustomEmail,
  isCustomEmail,
  closeCustomEmail,
  editSave,
  loadingSubmit,
  delivery_address_check
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const navigate = useNavigate();
  const [initialized, setInitialized] = useState(false);
  const [loadingFile, setLoadingFile] = useState(false);
  const [loadingDuplicate, setLoadingDuplicate] = useState(false);
  const [loadingUpdateStatus, setLoadingUpdateStatus] = useState(false);
  const [loadingStatusCompleted, setLoadingStatusCompleted] = useState(false);
  const [open, setOpen] = useState(false);
  const [loadingResendCustomer, setLoadingResendCustomer] = useState(false);
  const [loadingExport, setLoadingExport] = useState(false);
  const [anchorElOrdersType, setAnchorElOrdersType] = React.useState(null);
  const openActions = Boolean(anchorElOrdersType);
  const [openDeleteOrderDialog, setOpenDeleteOrderDialog] = React.useState(false);
  const [loadingDeleteDialog, setLoadingDeleteDialog] = useState(false);
  const [employeeID, setEmployeeID, employeeIDRef] = useStateRef(null);
  const [loadingDeleteFile, setLoadingDeleteFile] = useState(false);

  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
      loadEmployeeData();
    }
  });

  function onUploadProgress(progressEvent) {
    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
    console.log(percentCompleted + '% Done');
  }

  const loadEmployeeData = async () => {
    var employee_data = await getEmployee();

    if (employee_data) {
      if (employee_data.user) {
        if (employee_data.user.id) {
          setEmployeeID(employee_data.user.id);
        }
      }
    }
  };

  const handleSubmission = async (e, sales_order) => {
    handleCloseActions();
    try {
      setLoadingFile(true);
      e.preventDefault();

      const form = document.querySelector('#my-form');
      const data = new FormData(form);
      
      let updateData = await directus.uploadFiles(data, onUploadProgress);

      await directus.createItem('sales_order_files', {
        status: 'published',
        sales_order_id: sales_order.id,
        directus_file_id: updateData.data.id
      });

      loadOrderSelected();
      setLoadingFile(false);
      var message = 'File uploaded successfully';
      enqueueSnackbar(message, {
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        variant: 'success',
        autoHideDuration: 3200,
        action: CloseSnackbarAction
      });
    } catch (e) {
      setLoadingFile(false);
      enqueueSnackbar(ErrorMessage(e), {
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        variant: 'error',
        autoHideDuration: 3200,
        action: CloseSnackbarAction
      });
    }
  };

  const openCustomEmail = () => {
    handleCloseActions();
    setIsCustomEmail(true);

    var send_to_email = '';
    var cc_email = '';

    if (selectedOrder.farm_code) {
      if (selectedOrder.farm_code.email) {
        send_to_email = selectedOrder.farm_code.email;
      }
    }
    if (selectedOrder.employee.email) {
      cc_email = selectedOrder.employee.email;
    } else if (selectedOrder.employee.user.email) {
      cc_email = selectedOrder.employee.user.email;
    }

    setDataCustomEmail({ send_to_email: send_to_email, cc_email: cc_email });
  };

  const handleChangeInput = (e, type) => {
    const { name, value } = e.target;
    var check_value = null;
    if (value) {
      if (value === ' ') {
        check_value = '';
      } else {
        check_value = value.split(/[ ,]+/).join(';');
        check_value = check_value.split(/[;;,]+/).join(';');
      }
    }
    setDataCustomEmail({ ...dataCustomEmail, [name]: check_value });
  };

  const sendEmail = () => {
    setCustomEmailLoading(true);
    var cc_email = dataCustomEmail.cc_email;
    var send_to_email = dataCustomEmail.send_to_email;
    var cc_email_array = cc_email.split(';');
    var send_to_email_array = send_to_email.split(';');

    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    var invalid_email = [];
    var invalid_email_count = 0;
    var invalid_email_text = '';
    /* eslint-disable array-callback-return*/
    cc_email_array.map(value => {
      if (value) {
        if (!value.match(mailformat)) {
          invalid_email.push(value);
          invalid_email_count++;
        }
      }
    });
    /* eslint-disable array-callback-return*/
    send_to_email_array.map(value => {
      if (value) {
        if (!value.match(mailformat)) {
          invalid_email.push(value);
          invalid_email_count++;
        }
      }
    });
    /* eslint-disable array-callback-return*/
    invalid_email.map((value, index) => {
      invalid_email_text += value;
      if (index < invalid_email.length - 1) {
        invalid_email_text += ', ';
      }
    });

    if (invalid_email_count > 0) {
      var error_message = 'Invalid email address' + (invalid_email_count > 1 ? 'es' : '');
      error_message += '\n' + invalid_email_text + '';
      enqueueSnackbar(error_message, {
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        variant: 'error',
        style: { width: '300px', whiteSpace: 'pre-line' },
        autoHideDuration: 4200,
        action: CloseSnackbarAction
      });
      setCustomEmailLoading(false);
    } else {
      editSubmit(selectedOrder.id, 'sent', false, true);
    }
  };

  const handleClickOpenDialog = () => {
    handleCloseActions();
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const updateStatus = async (id, status) => {
    setLoadingUpdateStatus(true);
    handleCloseActions();
    setLoadingStatusCompleted(true);
    handleCloseDialog();

    await directus.updateItem('sales_orders', id, {
      status: status
    });

    EventEmitter.emit('load_all_orders', { loadStatus: false });

    loadOrderSelected();
    setLoadingStatusCompleted(false);

    var message = 'Status has changed to ' + status;
    enqueueSnackbar(message, {
      anchorOrigin: { vertical: 'top', horizontal: 'right' },
      variant: 'success',
      autoHideDuration: 3200,
      action: CloseSnackbarAction
    });
    setLoadingUpdateStatus(false);
  };

  const goToFirstPage = () => {
    localStorage.setItem("limit_and_page", JSON.stringify({page: 1, limit: 100}));
    EventEmitter.emit("load_all_orders");
  }

  const duplicateData = async value => {
    handleCloseActions();
    setLoadingDuplicate(true);
    var directusUser = JSON.parse(window.localStorage.getItem('directus_employee'));
    const date = moment().format('YYYY-MM-DD HH:mm:ss');
    var order_body = {
      employee: directusUser.id,
      status: 'draft',
      order_date: date,
      notes: value.notes,
      cc_email: value.cc_email,
      farm_code: value.farm_code.id,
    };

    const orderResult = await directus.createItems('sales_orders', order_body);

    var sales_order_items = value.sales_order_items;
    var order_list = [];
    if (sales_order_items) {
      sales_order_items.map(data => {
        order_list = [
          ...order_list,
          {
            sales_order: { id: orderResult.data.id },
            sales_order_item: {
              description: data.sales_order_item.description,
              quantity: data.sales_order_item.quantity,
              internal_code: data.sales_order_item.internal_code,
              unit_price: data.sales_order_item.unit_price,
              sort: data.sales_order_item.sort,
              item_discount: data.sales_order_item.item_discount,
              sales_item: data.sales_order_item.sales_item ? data.sales_order_item.sales_item.id : null,
            }
          }
        ];
      });

      for (let index = 0; index < order_list.length; index++) {
        let order_item_list = order_list[index];
        let sales_order_item = order_item_list.sales_order_item;
        delete sales_order_item.id;
        var orderItemResult = await directus.createItems('sales_order_items', sales_order_item);
        if (orderItemResult && orderItemResult.data) {
          order_item_list.sales_order_item = { id: orderItemResult.data.id };
          order_list[index] = order_item_list;
        }
      }

      await directus.createItems('sales_orders_items', order_list);
    }

    goToFirstPage();

    var message = 'Order has been duplicated';
    enqueueSnackbar(message, {
      anchorOrigin: { vertical: 'top', horizontal: 'right' },
      variant: 'success',
      autoHideDuration: 3200,
      action: CloseSnackbarAction
    });
    setLoadingDuplicate(false);
  };
  
  const resendToCustomer = async id => {
    handleCloseActions();
    setLoadingResendCustomer(true);
    try {
      let result = await directus.api.get(`/custom/sales_orders/send_sales_order_email/${id}`, { resend_customer: true });
      if (result.data) {
        enqueueSnackbar('Order has been resent to customer', {
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
          variant: 'success',
          autoHideDuration: 3200,
          action: CloseSnackbarAction
        });
      }
    } catch (e) {
      enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200, action: CloseSnackbarAction });
    }
    setLoadingResendCustomer(false);
  };

  const exportSalesOrder = async data => {
    handleCloseActions();
    setLoadingExport(true);
    try {
      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + directus.api.auth.config.token }
      };

      var react_app_url = process.env.REACT_APP_URL;
      if (window.location.hostname.search('interax') !== -1) {
        react_app_url = process.env.REACT_APP_URL_INTERAX;
      }
      await fetch(`${react_app_url}${JSON.parse(window.localStorage.getItem('company'))}/custom/export/sales_order/` + data.id, requestOptions)
        .then(response => response.blob())
        .then(async blob => {
          var file = await blob.text();
          const url = window.URL.createObjectURL(blob);

          if (file.includes('error')) {
            var message = JSON.parse(file).error.message;
            alert(JSON.parse(message).error.message);
          } else {
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            // the filename you want
            // a.download = 'PO-Export' + '-' + moment(data.order_date).format('YYYY-MM-DD') + '.pdf';
            a.download = `S${data.order_number}-${moment(data.order_date).format('YYYY-MM-DD')}.pdf`;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
          }
        });
    } catch (e) {
      enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200, action: CloseSnackbarAction });
    }
    setLoadingExport(false);
  };

  const handleCloseActions = () => {
    setAnchorElOrdersType(null);
  };

  const handleOpenActions = event => {
    setAnchorElOrdersType(event.currentTarget);
  };

  const handleCloseDeleteOrderDialog = () => {
    setOpenDeleteOrderDialog(false);
  };

  const handleOpenDeleteOrderDialog = () => {
    handleCloseActions();
    setOpenDeleteOrderDialog(true);
  };

  const deleteOrder = async () => {
    setLoadingDeleteDialog(true);
    try {
      var orders = await directus.getItems('sales_orders', {
        fields: 'id,sales_order_items.*,files.*',
        filter: {
          id: selectedOrder.id
        }
      });

      let delItems = [];
      let delItems2 = [];
      let delFiles = [];

      if (orders.data[0]) {
        var deleted_order = orders.data[0];
        if (deleted_order.sales_order_items) {
          if (deleted_order.sales_order_items.length > 0) {
            //eslint-disable-next-line array-callback-return
            deleted_order.sales_order_items.map(value => {
              delItems.push(value.id);
              delItems2.push(value.sales_order_item);
            });
          }
        }

        if (deleted_order.files) {
          if (deleted_order.files.length > 0) {
            deleted_order.files.map(value => {
              delFiles.push(value.id);
            });
          }
        }
      }

      if (delItems.length > 0) {
        await directus.deleteItems('sales_orders_items', delItems);
        await directus.deleteItems('sales_order_items', delItems2);
      }

      if (delFiles.length > 0) {
        await directus.deleteItems('sales_order_files', delFiles);
      }

      await directus.deleteItem('sales_orders', selectedOrder.id);
      enqueueSnackbar('Data deleted successfully', {
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        variant: 'success',
        autoHideDuration: 3200,
        action: CloseSnackbarAction
      });
    } catch (e) {
      enqueueSnackbar(ErrorMessage(e), {
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        variant: 'error',
        autoHideDuration: 3200,
        action: CloseSnackbarAction
      });
    }
    setLoadingDeleteDialog(false);
    handleCloseDeleteOrderDialog();

    EventEmitter.emit('load_all_orders', { value: true });
  };

  const handleSaveCompletedOrder = (orderID) => {
    editSave(orderID);
    handleCloseActions();
  }

  const handleClick = id => {
    window.open(id);
  };

  const handleDelete = async id => {
    setLoadingDeleteFile(true);
    let delItems = [];
    delItems = [...delItems, id];
    
    if (delItems.length > 0) {
      await directus.deleteItems('sales_order_files', delItems);
    }
    
    await loadOrderSelected(false);
    setLoadingDeleteFile(false);
    var message = 'File has been deleted';
    enqueueSnackbar(message, { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'success', autoHideDuration: 3200, action: CloseSnackbarAction });
  };
  
  const isButtonDisabled = disabledCheck || loadingDuplicate || loadingSubmit || loadingFile || loadingStatusCompleted;

  return (
    <div>
      <div style={{ width: '100%', display: 'flex', marginTop: '30px'}}>
        <div style={{ width: '50%', flexWrap: 'wrap', display: 'flex', gap: '10px', overflowX: 'auto' }}>
          {loadingDeleteFile ?
            <span display="inline-flex" style={{ marginTop: '5px' }}>
              <CircularProgress size={20} style={{  color: '#67AC2D' }}/>
            </span>
          : selectedOrder.files && selectedOrder.files.length > 0
            ? selectedOrder.files.map((file, index) => {
              if (file.directus_file_id) {
                return (
                  <Grid key={index} span="true" style={{ display: 'flex' }}>
                    <Chip
                      icon={<InsertDriveFileIcon />}
                      label={file.directus_file_id.filename_download}
                      onClick={id => handleClick(file.directus_file_id.data.full_url)}
                      onDelete={() => handleDelete(file.id)}
                      variant="outlined"
                      style={{ marginRight: '5px' }}
                    />
                  </Grid>
                );
              } else {
                return null;
              }
            })
          : ''}
        </div>
        <div style={{ width: '50%', maxWidth: '50%', display: 'flex', justifyContent: 'flex-end', gap: '10px', flexWrap: 'wrap' }}>
          {loadingSubmit || loadingExport || loadingResendCustomer || loadingDuplicate || loadingUpdateStatus ? (
            <span display="inline-flex" style={{ marginTop: '5px' }}>
              <CircularProgress size={20} style={{  color: '#67AC2D' }}/>
            </span>
          ) : (
            <>
              <CustomButton
                variant="contained"
                onClick={handleOpenActions}
              >
                Actions
              </CustomButton>
              
              <StyledMenu
                id="customized-menu"
                anchorEl={anchorElOrdersType}
                keepMounted
                open={Boolean(openActions)}
                onClose={handleCloseActions}
                className={classes.listStyle}
              >
                <List>
                  {selectedOrder.status !== 'cancelled' ?
                    <MenuItem className={classes.menuitem2} onClick={() => {}} >
                      <form id="my-form" className={classes.form}>
                        <input type="file" name="file" onChange={e => handleSubmission(e, selectedOrder)} id="contained-button-file" className={classes.input} />
                        <label htmlFor="contained-button-file" className={classes.fileInputLabel}>Attach File</label>
                      </form>
                    </MenuItem>
                  : ''}
                  {selectedOrder.status === 'completed' ?
                    <MenuItem
                      className={classes.menuitem}
                      onClick={() => handleSaveCompletedOrder(selectedOrder.id)}
                      disabled={isButtonDisabled}
                    >
                      Save
                    </MenuItem>
                  : ''}
                  {selectedOrder.status === 'draft' ?
                    <>
                      {selectedCustomer ? (
                        selectedCustomer.id ? (
                          selectedCustomer.email ? (
                            <MenuItem
                              disabled={isButtonDisabled || !delivery_address_check}
                              className={classes.menuitem}
                              onClick={() => editSubmit(selectedOrder.id, 'sent', true)}
                            >
                              Submit and Send
                            </MenuItem>
                          ) : null
                        ) : selectedOrder.farm_code ? (
                          selectedOrder.farm_code.email ? (
                            <MenuItem
                              disabled={isButtonDisabled || !delivery_address_check}
                              className={classes.menuitem}
                              onClick={() => editSubmit(selectedOrder.id, 'sent', true)}
                            >
                              Submit and Send
                            </MenuItem>
                          ) : null
                        ) : null
                      ) : null}

                      {selectedCustomer ? (
                        selectedCustomer.id ? (
                          selectedCustomer.email ? (
                            <MenuItem
                              disabled={isButtonDisabled || !delivery_address_check}
                              className={classes.menuitem}
                              onClick={() => openCustomEmail()}
                            >
                              Submit and Custom Send
                            </MenuItem>
                          ) : null
                        ) : selectedOrder.farm_code ? (
                          selectedOrder.farm_code.email ? (
                            <MenuItem
                              disabled={isButtonDisabled || !delivery_address_check}
                              className={classes.menuitem}
                              onClick={() => openCustomEmail()}
                            >
                              Submit and Custom Send
                            </MenuItem>
                          ) : null
                        ) : null
                      ) : null}
                    </>
                  : ''}
                  {selectedOrder.status === 'sent' || selectedOrder.status === 'completed' ?
                    <MenuItem
                      className={classes.menuitem}
                      onClick={e => exportSalesOrder(selectedOrder)}
                      disabled={isButtonDisabled}
                    >
                      Export Sales Order
                    </MenuItem>
                    : ''}
                  {selectedOrder.status !== 'completed' ?
                    <MenuItem
                      className={classes.menuitem}
                      onClick={handleClickOpenDialog}
                      disabled={isButtonDisabled}
                    >
                      Update Status
                    </MenuItem>
                  : ''}
                  <MenuItem
                    className={classes.menuitem}
                    onClick={e => duplicateData(selectedOrder)}
                    disabled={isButtonDisabled}
                  >
                    Duplicate
                  </MenuItem>
                  {selectedOrder.status === 'draft' ?
                    POApproverPermission() ?
                      <MenuItem
                        className={classes.menuitem}
                        onClick={() => handleOpenDeleteOrderDialog(selectedOrder.id)}
                      >
                        Delete Order
                      </MenuItem>
                    : selectedOrder.owner ?
                      selectedOrder.owner.id === employeeIDRef.current ?
                        <MenuItem
                          className={classes.menuitem}
                          onClick={() => handleOpenDeleteOrderDialog(selectedOrder.id)}
                        >
                          Delete Order
                        </MenuItem>
                      : ''
                    : ''
                  : ''}
                </List>
              </StyledMenu>

              {selectedOrder.status === 'sent' || selectedOrder.status === 'completed' ?
                <>
                  <CustomButton2
                    variant="contained"
                    onClick={() => resendToCustomer(selectedOrder.id)}
                    disabled={isButtonDisabled}
                    startIcon={<img src='/static/images/icons/resend.svg' />}
                  >
                    Resend to customer
                  </CustomButton2>
                </>
              : ''}

              {selectedOrder.status === 'draft' ? (
                <>
                  <CustomButton
                    variant="contained"
                    onClick={() => editSubmit(selectedOrder.id, 'draft')}
                  >
                    Save Draft
                  </CustomButton>

                  <CustomButton2
                    variant="contained"
                    onClick={() => editSubmit(selectedOrder.id, 'sent')}
                    disabled={isButtonDisabled || !delivery_address_check}
                  >
                    Submit
                  </CustomButton2>
                </>
              ) : ''}
            </>
          )}
        </div>
      </div>

      <Dialog isopen={`${openDeleteOrderDialog}`} open={openDeleteOrderDialog} fullWidth={true} onClose={handleCloseDeleteOrderDialog}>
        <DialogTitle disableTypography={true} id="form-dialog-title">
          <Typography component="div" variant="h6">
            <h2>Delete Order</h2>
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography variant="h6">Are you sure?</Typography>
        </DialogContent>
        <DialogActions style={{ height: '60px' }}>
          {loadingDeleteDialog ? (
            <CircularProgress className={classes.circular} style={{  color: '#67AC2D' }}/>
          ) : (
            <div>
              <CancelButton id="cancel_btn" size="small" variant="contained" onClick={handleCloseDeleteOrderDialog}>
                Cancel
              </CancelButton>
              <SubmitButton style={{ marginLeft: '10px' }} id="save_btn" size="small" variant="contained" onClick={() => deleteOrder()}>
                Delete
              </SubmitButton>
            </div>
          )}
        </DialogActions>
      </Dialog>

      <Dialog open={isCustomEmail} onClose={closeCustomEmail}>
        <DialogTitle disableTypography={true} id="form-dialog-title">
          <Typography component="div" variant="h6">
            <h2>Submit and Custom Send</h2>
          </Typography>
        </DialogTitle>
        <DialogContent style={{ width: '450px' }}>
          <TextField
            id="email_text"
            label="Email"
            variant="outlined"
            fullWidth={true}
            name="send_to_email"
            value={dataCustomEmail.send_to_email}
            placeholder={'user1@gmail.com;user2@gmail.com'}
            onChange={handleChangeInput}
          />
          <br />
          <br />
          <TextField
            id="cc_email_text"
            label="CC Email"
            variant="outlined"
            fullWidth={true}
            name="cc_email"
            value={dataCustomEmail.cc_email}
            placeholder={'user1@gmail.com;user2@gmail.com'}
            onChange={handleChangeInput}
          />
        </DialogContent>

        <DialogActions style={{ height: '60px', marginRight: '5px' }}>
          {customEmailLoading ? (
            <CircularProgress className={classes.circular} style={{  color: '#67AC2D' }}/>
          ) : (
            <div>
              <CancelButton id="cancel_btn" size="small" variant="contained" onClick={closeCustomEmail}>
                Cancel
              </CancelButton>
              <SubmitButton
                id="save_btn"
                size="small"
                variant="contained"
                onClick={() => sendEmail()}
                disabled={!dataCustomEmail.send_to_email}
              >
                Save
              </SubmitButton>
            </div>
          )}
        </DialogActions>
      </Dialog>

      <Dialog open={open} className={classes.DialogStatusStyle}>
        <IconButton aria-label="close" className={classes.dialogCloseBtn} onClick={handleCloseDialog}>
          <ClearIcon />
        </IconButton>
        <DialogTitle>Update {selectedOrder ? selectedOrder.order_number : ''} Status</DialogTitle>
        <DialogActions>
          {AdminPermission() && selectedOrder.status !== 'draft' ?
            <DraftButton variant="contained" onClick={() => updateStatus(selectedOrder.id, 'draft')}>
              <Typography variant="h6">Draft</Typography>
            </DraftButton>
          : ''}

          {selectedOrder.status !== 'sent' ?
            <SentButton disabled={isButtonDisabled || !delivery_address_check} variant="contained" onClick={() => updateStatus(selectedOrder.id, 'sent')}>
              <Typography variant="h6">Sent</Typography>
            </SentButton>
          : ''}

          {POApproverPermission() ? (
            <CompletedButton disabled={isButtonDisabled || !delivery_address_check} variant="contained" onClick={() => updateStatus(selectedOrder.id, 'completed')}>
              <Typography variant="h6">Completed</Typography>
            </CompletedButton>
          ) : (
            ''
          )}
          
          <CancelledButton variant="contained" onClick={() => updateStatus(selectedOrder.id, 'cancelled')}>
            <Typography variant="h6">Cancelled</Typography>
          </CancelledButton>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default ButtonsView